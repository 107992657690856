import helper from "@/app/utils/helper";
import cellMixin from "@/app/mixins/table/cell-mixin";

export default {
	name: 'grid-cell-number',
	mixins: [cellMixin],
	computed: {
		label(){
			// console.log(this.fieldData);
			if(this.fieldData === '0.00') return '–';
			return helper.getFormatNum(this.fieldData);
		},
	},
	created() {

	},
}