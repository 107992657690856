import { mapState, mapActions, mapWritableState } from 'pinia';
import { useRootStore } from '@/app/stores/root-store';
import { useGridDesignStore } from '@/app/stores/grid-design-store';
import { useMapDesignStore } from '@/app/stores/map-design-store';
import { useDesignFilterStore } from '@/app/stores/design-filter-store';
import { useFiltersStore } from '@/app/stores/filters-store';

import { ElTable, ElTableColumn } from "element-plus";
// import ElTable from '@/app/lib/element-plus-custom/table/index.mjs';
// import ElTableColumn from '@/app/lib/element-plus-custom/table/src/table-column/index.mjs';
import 'element-plus/es/components/table/style/css';




import helper from "@/app/utils/helper";
import HeadBtnSort from "@/app/components/table/header/head-btn-sort/HeadBtnSort.vue";
import HeadDesignInfo from '@/app/components/grid/grid-cell-types/head-design-info/HeadDesignInfo.vue';

import GridDesignsInfo from '@/app/components/grid/grid-designs-info/GridDesignsInfo.vue';
import SideQueue from '@/app/components/map/map-design-info/side-queue/SideQueue.vue';
import GridEmployInfo from '@/app/components/grid/grid-employ-info/GridEmployInfo.vue';
// import GridCellNumber from '@/app/components/grid/grid-cell-types/grid-cell-number/GridCellNumber.vue';
import CellNumber from '@/app/components/table/cell-types/cell-number/CellNumber.vue';
import GridCellMoney from '@/app/components/grid/grid-cell-types/grid-cell-money/GridCellMoney.vue';
import GridCellPhoto from '@/app/components/grid/grid-cell-types/grid-cell-photo/GridCellPhoto.vue';
import GridCellQueue from '@/app/components/grid/grid-cell-types/grid-cell-queue/GridCellQueue.vue';

export default {
	name: 'grid-designs-table',
	components: {
		ElTable, ElTableColumn,
		HeadBtnSort, HeadDesignInfo,
		GridDesignsInfo, SideQueue,
		GridEmployInfo, CellNumber, GridCellMoney,
		GridCellPhoto, GridCellQueue,
	},
	props:{
		tableData: {
			type: Array,
			default: [],
		}
	},
	emits: ['update:tableData', 'changeSelectionTable'],
	data() {
		return {
			isDesignInfoCompact: false,
			sortData: null,
			sizeChunk: 10,
		}
	},
	computed: {
		...mapState( useRootStore, ['isShowSecondAside'] ),
		...mapState(useGridDesignStore, ['sideQueueList', 'gridPaginationData']),
		...mapWritableState(useGridDesignStore, ['colConfigList']),
		...mapWritableState(useMapDesignStore, ['fakeDesignInfoData']),
		...mapWritableState(useDesignFilterStore, ['isFilterDataLoad']),

		chunkTableData(){
			return this.tableData.slice(0, this.sizeChunk);
		},
		cityMap(){
			const map = {};
			const list = this.getOptions('city');

			for( const item of list){
				map[item.value] = item;
			}

			return map;
		}
	},
	methods: {
		...mapActions( useMapDesignStore, ['getStructure'] ),
		...mapActions( useFiltersStore, ['getOptions']),
		...mapActions( useRootStore, ['setShowSecondAside']),

		getMoney(num){
			return helper.getMoney(num);
		},
		sortTable(order, key){
			// if(!order) return;
			// console.log(order, key);
			const tableData = helper.clone(this.tableData);
			this.sortData = { order, key };

			for(let item of this.colConfigList){
				if(item.key == key) continue;
				item.order = '';
			}

			if( key=='designInfo' ){
				if(order=='asc') tableData.sort((a,b)=>a['gid'].toLowerCase().localeCompare(b['gid'].toLowerCase()));
				else if(order=='desc') tableData.sort((a,b)=>b['gid'].toLowerCase().localeCompare(a['gid'].toLowerCase()));
				else tableData.sort((a,b)=>a['id'] - b['id']);
			} else {
				if(order=='asc') tableData.sort((a,b)=>a.curSideData[key] - b.curSideData[key]);
				else if(order=='desc') tableData.sort((a,b)=>b.curSideData[key] - a.curSideData[key]);
				else tableData.sort((a,b)=>a['id'] - b['id']);
			}

			this.$emit('update:tableData', tableData);
		},
		changeSelectionTable(data){
			// console.log(data);
			this.$emit('changeSelectionTable', data);
		},
		isMoneyType(key){
			return [ 'priceMonth','pricePeriod',
				'priceInstallationFra', 'priceInstallationLocal', 'pricePrint' ].includes(key);
		},
		scrollAddItemData(){
			const tableContainer = this.$el.querySelector('.el-scrollbar__wrap');
			$(tableContainer).off('scroll.grid_designs_table').on('scroll.grid_designs_table', ()=>{
				if (tableContainer.scrollTop + tableContainer.clientHeight >= tableContainer.scrollHeight) {
					const { per_page } = this.gridPaginationData;
					if( this.sizeChunk >= per_page ) return;

					this.sizeChunk += 10;
					console.log('конец таблицы', this.sizeChunk);
				}
			});
		},
		getRowClasses({row, rowIndex}){
			const classList = [];
			const { inTargetProgram } = row?.curSideData ?? {};
			// console.log(row);
			// classList.push('row-in-tprog');
			if(inTargetProgram) classList.push('row-in-tprog');

			return classList.join(' ');
		},
		async dbClickRow(data){
			console.log(data);
			this.isFilterDataLoad = true;
			await this.getStructure({ id: data.id, noDataMap: true });
			if(!this.isShowSecondAside) this.setShowSecondAside(true);
			this.isFilterDataLoad = false;
		},
		changeCompactHeadDesign(is){
			const findCol = this.colConfigList.find(item=>item.key=='designInfo');

			this.isDesignInfoCompact = is;
			if(!findCol){
				this.isDesignInfoCompact = false;
				return;
			}

			findCol.width = is ? 290 : 530;
		},
	},
	watch: {
		async tableData(data){
			const { per_page } = this.gridPaginationData;
			if(data){
				this.sizeChunk = 10;

				// await helper.pause(2000);
				// while (this.sizeChunk < per_page) {
				// 	console.log(this.sizeChunk, per_page);
				// 	await helper.pause(500);
				// 	this.sizeChunk +=5;
				// }
			}
		}
	},
	created() {

	},
	mounted() {
		// this.scrollAddItemData();

		$(window).on('clear-table-selection', ()=>{
			const { gridTable } = this.$refs;
			if(gridTable) gridTable.clearSelection();
		});
	},
	unmounted() {
		$(window).off('clear-table-selection');
	},

}