import helper from "@/app/utils/helper";

import BsSingleSelect from "@/app/components/controls/selects/bs-single-select/BsSingleSelect.vue";
import BtnAction from "@/app/components/controls/buttons/btn-action/BtnAction.vue";
import TpTempSaveDialog from "./tp-temp-save-dialog/TpTempSaveDialog.vue";

export default {
	name: 'tp-temp-settings',
	components: {
		BsSingleSelect, BtnAction, TpTempSaveDialog,
	},
	props: {
		options: {
			type: Array,
			default: [],
		},
		colConfigList: {
			type: Array,
			default: [],
		},
		isCommonFieldsCollapsed: {
			type: Boolean,
			default: false,
		}
	},
	emits: ['save', 'change', 'remove'],
	data() {
		return {
			template: '',
			isVisibleSaveDialog: false,
		}
	},
	methods: {
		saveTemplate(tempName){
			const configList = [];

			for(const item of this.colConfigList){
				const { key, show } = item ?? {};
				if( key && show!==undefined ){
					if(this.isCommonFieldsCollapsed && item.collapsed) configList.push({ key, show: false });
					else configList.push({ key, show });
				}
			}

			const id = helper.hash;
			this.$emit('save', { id, tempName, configList });
			this.template = id;
		},
		changeTemplate(value){
			this.$emit('change', value);
		},
		removeTemplate({ opt }){
			this.$emit('remove', opt.value);
			if(this.template == opt.value) this.template = '';
		}
	},
}