import DstrSideItem from "./dstr-side-item/DstrSideItem.vue";

export default {
	name: 'dstr-side-list',
	components: {
		DstrSideItem,
	},
	props: {
		list: {
			type: Array,
			default: [],
		}
	},
	emits: ['removeItem', 'openSideMap'],
	methods: {
		removeItem(item){
			this.$emit('removeItem', item);
		},
		openSideMap(data){
			this.$emit('openSideMap', data);
		}
	},
};