import { mapActions, mapState, mapWritableState } from 'pinia';
import { useRootStore } from '@/app/stores/root-store';
import { useAllTargetProgStore } from '@/app/stores/all-target-prog-store';
import { useDetailTargetProgStore } from '@/app/stores/detail-target-prog-store';
import { useFiltersStore } from '@/app/stores/filters-store';

import { ElBadge } from 'element-plus';


import { colConfigList } from '@/app/components/target-programs/tprog-all-table/table-config';
import helper from '@/app/utils/helper';

import LoaderSpinner from '@/app/components/loaders/loader-spinner/LoaderSpinner.vue';
import BtnAction from '@/app/components/controls/buttons/btn-action/BtnAction.vue';
import GridPagination from '@/app/components/grid/grid-pagination/GridPagination.vue';
import TargetTabFilter from '@/app/components/target-programs/target-tab-filter/TargetTabFilter.vue';
import TableComPanel from '@/app/components/table-com-panel/TableComPanel.vue';
import TprogAllTable from '@/app/components/target-programs/tprog-all-table/TprogAllTable.vue';

export default {
	name: 'target-programs-view',
	components: {
		ElBadge,
		LoaderSpinner,
		BtnAction, GridPagination, TargetTabFilter,
		TprogAllTable, TableComPanel
	},
	data() {
		return {
			colConfigList,
			paginationData: null,
			tabConfig: [
				{
					key: 'all',
					title: 'Все',
				},
				{
					key: 'my',
					title: 'Мои',
				},
				{
					key: 'active',
					title: 'Активные',
				},
				{
					key: 'placement',
					title: 'В размещении',
				},
				{
					key: 'agreement',
					title: 'На согласовании',
				},
				{
					key: 'messages',
					title: 'Есть сообщения',
				},
				{
					key: 'archival',
					title: 'Архивные',
				},
			],

			tableCommandList: [
				{
					key: 'add-in-archive',
					label: 'Добавить в архив',
				},
				{
					key: 'delete',
					label: 'Удалить',
				},
				{
					key: 'add',
					label: 'Обновить',
				},
				{
					key: 'save',
					label: 'Сохранить',
				},
				{
					key: 'com-1',
					label: 'Комманда-1',
				},
				{
					key: 'com-2',
					label: 'Комманда-2',
				},
				{
					key: 'com-3',
					label: 'Комманда-3',
				},
			],
			selectedTableRow: [],
		}
	},
	computed: {
		...mapState(useRootStore, ['isShowSecondAside'] ),
		...mapState(useAllTargetProgStore, ['allTargetFilterData', 'allTargetCategoriesCount',
			'allTargetPaginationData',
		]),

		...mapWritableState(useAllTargetProgStore, [ 'isViewLoader', 'isAsideLoader', 'allTargetTableData']),
		...mapWritableState(useDetailTargetProgStore, [ 'detailTargetGridData' ]),

		countFilters(){
			let count = 0;

			for(const [key, filterData] of Object.entries(this.allTargetFilterData)){
				const { keyBack } = filterData;
				if(key=='placePeriod'){
					const { from, to } = filterData;
					if(from || to) count++;
				}
				else if(key=='totalPrice'){
					const { select, input } = filterData;
					if(input) count++;
				}
				else if(key=='category'){  }
				else {
					const { value } = filterData;
					if(value?.length) count++;
				}
			}
			return count;
		},
		tabList(){
			const list = [];

			for(const item of this.tabConfig){
				let count = 0;

				if(this.allTargetCategoriesCount?.[item.key]) count = this.allTargetCategoriesCount[item.key];
				if( ['all'].includes(item.key) || count) list.push({
					...item,
					count,
				});
			}

			// console.log(list);
			return list;
		},
	},
	methods: {
		...mapActions(useRootStore, ['setShowSecondAside']),
		...mapActions(useAllTargetProgStore, ['getAllTargetListData', 'setFilterField' ]),
		...mapActions(useFiltersStore, ['getBatchFiltersData']),
		...mapActions(useDetailTargetProgStore, ['clearFilters']),

		toggleFilter(){
			this.setShowSecondAside(!this.isShowSecondAside);
		},
		changeTable(data){
			this.selectedTableRow = data;
		},

		async initViewData(){
			const { filters } = this.$route?.query ?? {};

			this.isAsideLoader = true;
			await this.getBatchFiltersData();
			if(filters) this.setFilterField(JSON.parse(filters));
			this.isAsideLoader = false;

			await this.getAllTargetListData();
		},

		async changePagination(data){
			const { page, sizeChunk } = data;
			const res = await this.getAllTargetListData({
				params: {
					page: page,
					per_page: sizeChunk,
				}
			});
			this.paginationData = data;

			// Очищаем сортировку столбцов
			// for(let item of this.colConfigList){
			// 	item.order = '';
			// }
		},

		async changeTabFilter(key){
			let params = {};
			this.allTargetFilterData.category.value = key;

			if(this.paginationData){
				const { page, sizeChunk } = this.paginationData;
				params = {
					// page: page,
					per_page: sizeChunk,
				};
			}

			const res = await this.getAllTargetListData({
				params: { ...params },
			});

			// Очищаем сортировку столбцов
			for(let item of this.colConfigList){
				item.order = '';
			}
		},

		async dbClickTableRow(data){
			console.log(data);
			if(data?.id){
				this.isViewLoader = true;
				this.clearFilters(); // очистка фильтров детальной АП
				this.detailTargetGridData = [];
				await helper.pause(100);

				this.$router.push({
					name: 'target-program-item',
					params: {
						id: data.id,
					},
				});
			}
		}
	},
	created() {
		this.initViewData();
	},
}