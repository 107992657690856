import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "design-marker-popup" }
const _hoisted_2 = { class: "design-marker-popup__photo" }
const _hoisted_3 = { class: "design-marker-popup__group justify-space-between" }
const _hoisted_4 = ["title"]
const _hoisted_5 = { class: "design-marker-popup__gid" }
const _hoisted_6 = { class: "design-marker-popup__group justify-space-between align-center" }
const _hoisted_7 = { class: "design-marker-popup__type" }
const _hoisted_8 = { class: "design-marker-popup__mech" }
const _hoisted_9 = { class: "design-marker-popup__side-select" }
const _hoisted_10 = { class: "design-marker-popup__group" }
const _hoisted_11 = { class: "control design-marker-popup__grp-ots" }
const _hoisted_12 = { class: "control__value" }
const _hoisted_13 = { class: "control design-marker-popup__price-month" }
const _hoisted_14 = { class: "control__value" }
const _hoisted_15 = {
  key: 0,
  class: "design-marker-popup__group"
}
const _hoisted_16 = { class: "control design-marker-popup__digital" }
const _hoisted_17 = { class: "control__value" }
const _hoisted_18 = { class: "design-marker-popup__actions-btn" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ElImage = _resolveComponent("ElImage")
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_SideSelect = _resolveComponent("SideSelect")
  const _component_BsBtn = _resolveComponent("BsBtn")
  const _component_BtnAction = _resolveComponent("BtnAction")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ElImage, {
        src: _ctx.firstPhoto,
        "zoom-rate": 1.2,
        "max-scale": 7,
        "min-scale": 0.2,
        "preview-src-list": _ctx.sideData.photoList,
        fit: "cover"
      }, null, 8 /* PROPS */, ["src", "preview-src-list"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: "design-marker-popup__address",
        title: _ctx.defaultData.address
      }, _toDisplayString(_ctx.defaultData.address), 9 /* TEXT, PROPS */, _hoisted_4),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.defaultData.gid), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createTextVNode(_toDisplayString(_ctx.defaultData.structureType), 1 /* TEXT */),
        _createVNode(_component_SvgIcon, {
          name: _ctx.getTypeDesignIcon(_ctx.defaultData.structureType)
        }, null, 8 /* PROPS */, ["name"])
      ]),
      _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.sideData.mechanism), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_component_SideSelect, {
        modelValue: _ctx.selectSide,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.selectSide) = $event)),
        options: _ctx.sideSelectOptions,
        onChange: _cache[1] || (_cache[1] = () => {})
      }, null, 8 /* PROPS */, ["modelValue", "options"])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "control__label" }, "GRP/OTS:", -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.sideData.grp ?? '-') + "/" + _toDisplayString(_ctx.sideData.ots ?? '-'), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_13, [
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "control__label" }, "Прайс/мес:", -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.getMoney(_ctx.sideData.priceMonth)) + " ₽", 1 /* TEXT */)
      ])
    ]),
    (_ctx.sideData?.isDigital)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, [
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "control__label" }, "Блок/слот/показы:", -1 /* HOISTED */)),
            _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.sideData?.block ?? '-') + " мин/" + _toDisplayString(_ctx.sideData?.slot ?? '-') + " сек/" + _toDisplayString(_ctx.sideData?.shows ?? '-'), 1 /* TEXT */)
          ])
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_18, [
      (!_ctx.sideData?.inTargetProgram)
        ? (_openBlock(), _createBlock(_component_BsBtn, {
            key: 0,
            onClick: _ctx.addTargetProg
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode("Добавить в АП")
            ])),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["onClick"]))
        : (_openBlock(), _createBlock(_component_BsBtn, {
            key: 1,
            onClick: _ctx.removeSide
          }, {
            default: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode("Удалить из АП")
            ])),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["onClick"])),
      _createVNode(_component_BtnAction, {
        name: "14-non-categorized",
        title: "Открыть РК",
        disabled: _ctx.isLoadingOpenStructure,
        loading: _ctx.isLoadingOpenStructure,
        onClick: _ctx.openStructure
      }, null, 8 /* PROPS */, ["disabled", "loading", "onClick"])
    ])
  ]))
}