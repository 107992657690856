import { mapActions, mapState, mapWritableState } from 'pinia';
import { useAllTargetProgStore } from '@/app/stores/all-target-prog-store';
import { ElTable, ElTableColumn } from "element-plus";
import 'element-plus/es/components/table/style/css';


import { colConfigList } from "./table-config";
import helper from "@/app/utils/helper";

import HeadBtnSort from "@/app/components/table/header/head-btn-sort/HeadBtnSort.vue";
import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';
import TprogCellDate from '@/app/components/target-programs/tprog-all-table/tprog-cell-types/tprog-cell-date/TprogCellDate.vue';
import TprogCellCity from '@/app/components/target-programs/tprog-all-table/tprog-cell-types/tprog-cell-city/TprogCellCity.vue';
import TprogCellPeriod from '@/app/components/target-programs/tprog-all-table/tprog-cell-types/tprog-cell-period/TprogCellPeriod.vue';
import TprogCellStatus from '@/app/components/target-programs/tprog-all-table/tprog-cell-types/tprog-cell-status/TprogCellStatus.vue';
import CellPrice from "@/app/components/table/cell-types/cell-price/CellPrice.vue";
import TprogCellNotific from '@/app/components/target-programs/tprog-all-table/tprog-cell-types/tprog-cell-notific/TprogCellNotific.vue';
import TprogCellOther from '@/app/components/target-programs/tprog-all-table/tprog-cell-types/tprog-cell-other/TprogCellOther.vue';
import TprogCellTitleLink from '@/app/components/target-programs/tprog-all-table/tprog-cell-types/tprog-cell-title-link/TprogCellTitleLink.vue';

export default {
	name: 'tprog-all-table',
	components: {
		ElTable, ElTableColumn,
		HeadBtnSort, SvgIcon,
		TprogCellDate, TprogCellCity, TprogCellPeriod,
		TprogCellStatus, CellPrice, TprogCellNotific,
		TprogCellOther, TprogCellTitleLink,
	},
	props: {
		tableData: {
			type: Array,
			default: [],
		}
	},
	emits: ['update:tableData', 'dbClickRow', 'changeTable'],
	data() {
		return {
			colConfigList,
		}
	},
	methods: {
		...mapActions(useAllTargetProgStore, ['getAllTargetListData', 'getSubmitFilterData', 'setSortData']),

		async sortTable(order, key){
			const tableData = [...this.tableData];
			// this.sortData = { order, key };

			for(let item of this.colConfigList){
				if(item.keyProp == key) continue;
				item.order = '';
			}

			/* tableData.sort((a,b)=>{
				if( ['id', 'createdDate', 'updatedDate', 'priceNotVAT',
					'structuresCount', 'sidesCount'].includes(key) ){
					if(order=='asc') return a[key] - b[key];
					else if(order=='desc') return b[key] - a[key];
				}
				else {
					if(order=='asc') return a[key].toLowerCase().localeCompare(b[key].toLowerCase());
					else if(order=='desc') return b[key].toLowerCase().localeCompare(a[key].toLowerCase());
				}

				if(!order) return a['id'] - b['id'];
			}); */

			let sort = { [key]: order }
			const currentParams = { ...this.$route.query };

			if(!order || !order.length) sort = { updated_at: 'asc' };
			let updatedParams = {
				...currentParams,
				sort: JSON.stringify(sort),
			};

			this.$router.push({ query: updatedParams });
			this.setSortData(sort);
			this.getAllTargetListData({ sort });

			this.$emit('update:tableData', tableData);
		},
		changeTable(data){
			console.log('changeTable', data);
			this.$emit('changeTable', data);
		},
		dbClickRow(data){
			// console.log(data);
			this.$emit('dbClickRow', data);
		}
	},
	created() {
		const { query } = this.$route;

		// Данные сортировки таблицы и URLа
		if(query?.sort){
			const sort = JSON.parse(query.sort);
			const [ key, order ] = Object.entries(sort)[0];
			const findCol = this.colConfigList.find(item=>item.keyProp==key);
			// console.log(key, order , findCol);
			if(findCol && order){
				findCol.order = order;
			}
		}
	},
}