import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dstr-side-item" }
const _hoisted_2 = { class: "dstr-side-item__top" }
const _hoisted_3 = { class: "dstr-side-item__top-info" }
const _hoisted_4 = { class: "dstr-side-item__top-col col-1" }
const _hoisted_5 = ["title"]
const _hoisted_6 = { class: "dstr-side-item__side" }
const _hoisted_7 = ["title"]
const _hoisted_8 = { class: "dstr-side-item__top-col col-2" }
const _hoisted_9 = { class: "dstr-side-item__mech" }
const _hoisted_10 = { class: "dstr-side-item__top-col col-3" }
const _hoisted_11 = { class: "dstr-side-item__grp-ots" }
const _hoisted_12 = { class: "dstr-side-item__price" }
const _hoisted_13 = { class: "dstr-side-item__bottom" }
const _hoisted_14 = ["title"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            class: "dstr-side-item__gid",
            title: _ctx.data?.rimGid ?? '',
            onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.openMap && _ctx.openMap(...args)))
          }, _toDisplayString(_ctx.data?.partnerGid), 9 /* TEXT, PROPS */, _hoisted_5),
          _createElementVNode("div", _hoisted_6, [
            _createTextVNode(_toDisplayString(_ctx.data?.sideDirection), 1 /* TEXT */),
            (_ctx.data?.isLighted)
              ? (_openBlock(), _createBlock(_component_SvgIcon, {
                  key: 0,
                  name: "lamp"
                }))
              : _createCommentVNode("v-if", true)
          ]),
          _createElementVNode("div", {
            class: "dstr-side-item__type",
            title: _ctx.data?.structureType
          }, [
            _createVNode(_component_SvgIcon, {
              name: _ctx.getTypeDesignIcon(_ctx.data?.structureType)
            }, null, 8 /* PROPS */, ["name"])
          ], 8 /* PROPS */, _hoisted_7)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.data?.sideType), 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.data?.grp) + "/" + _toDisplayString(_ctx.data?.ots), 1 /* TEXT */)
        ])
      ]),
      _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.getMoney(_ctx.data?.price ?? 0)) + " ₽", 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", {
        class: "dstr-side-item__address",
        title: _ctx.fullAddress,
        onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.openMap && _ctx.openMap(...args)))
      }, _toDisplayString(_ctx.fullAddress), 9 /* TEXT, PROPS */, _hoisted_14),
      _createElementVNode("div", {
        class: "dstr-side-item__remove",
        title: "Удалить",
        onClick: _cache[2] || (_cache[2] = (...args) => (_ctx.remove && _ctx.remove(...args)))
      }, [
        _createVNode(_component_SvgIcon, { name: "10-delete" })
      ])
    ])
  ]))
}