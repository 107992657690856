import { ElPopover, ElMessageBox } from "element-plus";


import BsBtn from '@/app/components/controls/buttons/bs-btn/BsBtn.vue';
import DatepickerMonthRange from '@/app/components/datepickers/datepicker-month-range/DatepickerMonthRange.vue';

export default {
	name: 'po-edit-month-range',
	components: {
		ElPopover,
		BsBtn, DatepickerMonthRange,
	},
	props:{
		fieldData: {
			type: Object,
			default: {},
		},
	},
	emits:['update:visible','change'],
	data() {
		return {
			isVisible: false,
			value: this.fieldValue,
			period: this.fieldData,
		}
	},
	methods: {
		clickCancel(){
			this.isVisible = false;
		},
		clickSave(){
			// console.log(this.period);
			this.$emit('change', this.period);
			this.isVisible = false;
		}
	},
	created(){
		this.period = this.fieldData;
	},
	watch: {
		fieldData(data){
			this.period = data;
		}
	},
}