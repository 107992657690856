import { mapState, mapActions, mapWritableState } from 'pinia';
import { useRootStore } from '@/app/stores/root-store';
import { usePricingDetailStore } from '@/app/stores/pricing-policies/pricing-detail-store';
import { useFiltersStore } from '@/app/stores/filters-store';

import { columnsPrices, columnsAddServ } from '@/app/components/pricing-policies/pricing-detail-table/table-config';

import { ElBadge } from 'element-plus';

import LoaderSpinner from '@/app/components/loaders/loader-spinner/LoaderSpinner.vue';
import GridPagination from '@/app/components/grid/grid-pagination/GridPagination.vue';
import BtnAction from '@/app/components/controls/buttons/btn-action/BtnAction.vue';
import PricingDetailTable from '@/app/components/pricing-policies/pricing-detail-table/PricingDetailTable.vue';
import PrSettingTable from '@/app/components/pricing-policies/other/pr-setting-table/PrSettingTable.vue';

export default {
	name: 'pricing-detail-view',
	components: {
		ElBadge,
		LoaderSpinner, GridPagination, BtnAction,
		PricingDetailTable, PrSettingTable,
	},
	data() {
		return {
			countMessage: 3,
			isCreateRuleLoading: false,
			columnsAddServ,
			columnsPrices
		}
	},
	computed: {
		...mapState(useRootStore, ['isDevServer', 'devMode', 'isShowSecondAside']),
		...mapState(usePricingDetailStore, ['countFilters', 'summaryData']),
		...mapWritableState(usePricingDetailStore, ['isViewLoader', 'tableData']),
		...mapWritableState(useRootStore, ['pageData']),

		type(){
			const { type } = this.summaryData ?? {};
			return type?.value ?? 'prices';
		},
		columnsType(){
			if(this.type == 'additional-services'){
				return this.columnsAddServ;
			} else {
				return this.columnsPrices;
			}
		},
		countSetSettings(){
			const arrNotShow = this.columnsType.filter(item=>!item.show);
			return arrNotShow.length;
		},
	},
	methods: {
		...mapActions(useRootStore, ['setShowSecondAside' ]),
		...mapActions(useFiltersStore, ['getBatchFiltersData']),
		...mapActions(usePricingDetailStore, ['getPricePolicyData', 'getRuleList', 'createRuleItem']),

		openAsideFilter(){
			this.setShowSecondAside(!this.isShowSecondAside);
		},
		saveSettingsColumn(config){
			// console.log(config);
			try {
				const data = JSON.stringify(config);
				window.localStorage.setItem(`settings--${this.type}--pricing-detail-table`, data);
			} catch (error) {
				console.error(error);
			}
		},
		setSettingColumn(){
			try {
				const data = window.localStorage.getItem(`settings--${this.type}--pricing-detail-table`);
				const config = JSON.parse(data);

				if(config?.length) for( const item of config ){
					const { key, show } = item;
					const findItem = this.columnsType.find(item=>item.key==key);

					if(findItem) findItem.show = show;
				}
			} catch (error) {
				console.error(error);
			}
		},
		async initViewData(){
			const { params } = this.$route ?? {};

			this.getPricePolicyData(params?.id).then((res)=>{
				const { name } = res?.pricePolicy ?? {};
				this.pageData.title = name;
			});
			this.getRuleList({ pricePolicyId: params?.id });
		},
		async createRule(){
			const { params } = this.$route ?? {};

			console.log('createRule');
			this.isCreateRuleLoading = true;
			await this.createRuleItem(params?.id);
			this.isCreateRuleLoading = false;
		}
	},
	watch: {
		type(){
			this.setSettingColumn();
		}
	},
	async created() {
		this.setSettingColumn();
		await this.getBatchFiltersData();
		this.initViewData();
	},
	unmounted() {
		this.pageData.title = '';
	},
}