import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "tprog-head-design" }
const _hoisted_2 = { class: "tprog-head-design__right" }
const _hoisted_3 = { class: "tprog-head-design__label" }
const _hoisted_4 = {
  key: 0,
  class: "tprog-head-design__search-panel"
}
const _hoisted_5 = {
  key: 0,
  class: "tprog-head-design__left"
}
const _hoisted_6 = ["title"]
const _hoisted_7 = { class: "tprog-head-design__btn-settings" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SearchPanel = _resolveComponent("SearchPanel")
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_TprogSettingsTable = _resolveComponent("TprogSettingsTable")
  const _component_ElPopover = _resolveComponent("ElPopover")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.label), 1 /* TEXT */),
      (!_ctx.isCompact)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_SearchPanel, { onOnSearch: _ctx.onSearch }, null, 8 /* PROPS */, ["onOnSearch"])
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    (!_ctx.notActions)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", {
            class: _normalizeClass(["tprog-head-design__btn-collapsed", { '--is-collapsed': _ctx.isCompact }]),
            title: _ctx.isCompact? 'Развернуть':'Свернуть',
            onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.clickCompact && _ctx.clickCompact(...args)))
          }, [
            _createVNode(_component_SvgIcon, { name: "14-arrow-left" })
          ], 10 /* CLASS, PROPS */, _hoisted_6),
          _createVNode(_component_ElPopover, {
            trigger: "click",
            visible: _ctx.isVisiblePopover,
            "onUpdate:visible": _cache[2] || (_cache[2] = $event => ((_ctx.isVisiblePopover) = $event)),
            offset: 8,
            persistent: false,
            width: "auto",
            placement: "bottom-start",
            "popper-class": "tprog-head-design__popover"
          }, {
            reference: _withCtx(() => [
              _createVNode(_Transition, {
                name: "el-fade-in",
                mode: "out-in"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_component_SvgIcon, { name: "16-setting" })
                  ])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            default: _withCtx(() => [
              (_ctx.isVisiblePopover)
                ? (_openBlock(), _createBlock(_component_TprogSettingsTable, {
                    key: 0,
                    settings: _ctx.getTableSettingsLocStorage(),
                    onCancel: _cache[1] || (_cache[1] = $event => (_ctx.isVisiblePopover=false)),
                    onSave: _ctx.saveSettings
                  }, null, 8 /* PROPS */, ["settings", "onSave"]))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["visible"])
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}