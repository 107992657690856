import cellMixin from '@/app/mixins/table/cell-mixin';

import NotifUserInfo from '@/app/components/notification/other/notif-user-info/NotifUserInfo.vue';
import NotifSystemInfo from '@/app/components/notification/other/notif-system-info/NotifSystemInfo.vue';

export default {
	name: 'NtCellUser',
	mixins: [ cellMixin ],
	components: {
		NotifUserInfo, NotifSystemInfo,
	},
	computed: {
		senderType(){
			return this.rowData?.senderType ?? '';
		},
		userData(){
			return this.rowData?.user;
		}
	},
}