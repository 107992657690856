import { mapState, mapActions, mapWritableState } from 'pinia';
import { usePricingDetailStore } from '@/app/stores/pricing-policies/pricing-detail-store';

import { ElTabs, ElTabPane, ElBadge, ElMessageBox, ElMessage } from 'element-plus';

import LoaderSpinner from '@/app/components/loaders/loader-spinner/LoaderSpinner.vue';
import EditField from '@/app/components/controls/edit-field/EditField.vue';
import PricingSummary from '@/app/components/pricing-policies/aside/pricing-summary/PricingSummary.vue';
import PricingFilter from '@/app/components/pricing-policies/aside/pricing-filter/PricingFilter.vue';

export default {
	name: 'pricing-detail-aside',
	components: {
		ElTabs, ElTabPane, ElBadge,
		LoaderSpinner, EditField,
		PricingSummary, PricingFilter,
	},
	data() {
		return {
			countFilter: 2,
			activeTab: 'pricing-summary',
			// activeTab: 'pricing-filter',
		}
	},
	computed: {
		...mapState( usePricingDetailStore, ['isAsideLoader', 'countFilters'] ),
		...mapWritableState( usePricingDetailStore, ['summaryData'] ),
	},
	methods: {
		...mapActions(usePricingDetailStore, ['updateFieldItem', 'buildSummaryData']),

		updateFieldState({ action, data, sucMessage }={}){
			if(!data) return;
			if(action) action();

			ElMessage({
				message: sucMessage ?? 'Данные успешно изменены',
				type: 'success',
			});
		},

		async changeEditField(title){
			console.log(title);
			if(!title) return;

			const { id } = this.summaryData;
			const res = await this.updateFieldItem({
				pricePolicyId: id,
				key: 'title',
				value: title,
			});
			const { pricePolicy } = res ?? {};

			this.updateFieldState({ data: pricePolicy, action: ()=>{
				this.summaryData = this.buildSummaryData(pricePolicy);
			}});
		},
	},
}