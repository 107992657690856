import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withKeys as _withKeys, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "tg-mass-data__content" }
const _hoisted_2 = { class: "control" }
const _hoisted_3 = { class: "control__group" }
const _hoisted_4 = { class: "tg-mass-data__tab-pane-container" }
const _hoisted_5 = { class: "tg-mass-data__form" }
const _hoisted_6 = { class: "control" }
const _hoisted_7 = { class: "control__group" }
const _hoisted_8 = { class: "control" }
const _hoisted_9 = { class: "control__group" }
const _hoisted_10 = { class: "control" }
const _hoisted_11 = { class: "control__group" }
const _hoisted_12 = { class: "control" }
const _hoisted_13 = { class: "control__group" }
const _hoisted_14 = { class: "control" }
const _hoisted_15 = { class: "control__group" }
const _hoisted_16 = { class: "control" }
const _hoisted_17 = { class: "control__group" }
const _hoisted_18 = { class: "control" }
const _hoisted_19 = { class: "control__group" }
const _hoisted_20 = { class: "tg-mass-data__tab-pane-container" }
const _hoisted_21 = { class: "tg-mass-data__form" }
const _hoisted_22 = { class: "control" }
const _hoisted_23 = { class: "control__group group-row" }
const _hoisted_24 = { class: "tg-mass-data__input-number" }
const _hoisted_25 = { class: "tg-mass-data__input" }
const _hoisted_26 = { class: "control" }
const _hoisted_27 = { class: "control__group" }
const _hoisted_28 = { class: "control__group group-row" }
const _hoisted_29 = { class: "tg-mass-data__input-number" }
const _hoisted_30 = { class: "tg-mass-data__input" }
const _hoisted_31 = { class: "control" }
const _hoisted_32 = { class: "control__group" }
const _hoisted_33 = { class: "control__group group-row" }
const _hoisted_34 = { class: "tg-mass-data__input-number" }
const _hoisted_35 = { class: "tg-mass-data__input" }
const _hoisted_36 = { class: "control" }
const _hoisted_37 = { class: "control__group" }
const _hoisted_38 = { class: "control__group group-row" }
const _hoisted_39 = { class: "tg-mass-data__input-number" }
const _hoisted_40 = { class: "tg-mass-data__input" }
const _hoisted_41 = { class: "control" }
const _hoisted_42 = { class: "control__group" }
const _hoisted_43 = { class: "control__group group-row" }
const _hoisted_44 = { class: "tg-mass-data__input-number" }
const _hoisted_45 = { class: "tg-mass-data__input" }
const _hoisted_46 = { class: "control" }
const _hoisted_47 = { class: "control__group" }
const _hoisted_48 = { class: "control__group group-row" }
const _hoisted_49 = { class: "tg-mass-data__input-number" }
const _hoisted_50 = { class: "tg-mass-data__input" }
const _hoisted_51 = { class: "tg-mass-data__tab-pane-container" }
const _hoisted_52 = { class: "tg-mass-data__form" }
const _hoisted_53 = { class: "control" }
const _hoisted_54 = { class: "control__group" }
const _hoisted_55 = { class: "control" }
const _hoisted_56 = { class: "control__group" }
const _hoisted_57 = { class: "control" }
const _hoisted_58 = { class: "control__group" }
const _hoisted_59 = { class: "control" }
const _hoisted_60 = { class: "control__group" }
const _hoisted_61 = { class: "control__group group-row gap-16" }
const _hoisted_62 = { class: "control full-free-width" }
const _hoisted_63 = { class: "control__group" }
const _hoisted_64 = { class: "control full-free-width" }
const _hoisted_65 = { class: "control__group" }
const _hoisted_66 = { class: "control" }
const _hoisted_67 = { class: "control__group" }
const _hoisted_68 = { class: "control__group group-row gap-16" }
const _hoisted_69 = { class: "control full-free-width" }
const _hoisted_70 = { class: "control__group" }
const _hoisted_71 = { class: "control full-free-width" }
const _hoisted_72 = { class: "control__group" }
const _hoisted_73 = { class: "tg-mass-data__action-buttons" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsMultiSelect = _resolveComponent("BsMultiSelect")
  const _component_BsSelectV2 = _resolveComponent("BsSelectV2")
  const _component_ElTabPane = _resolveComponent("ElTabPane")
  const _component_ElInputNumber = _resolveComponent("ElInputNumber")
  const _component_BsInput = _resolveComponent("BsInput")
  const _component_ElTabs = _resolveComponent("ElTabs")
  const _component_BsBtn = _resolveComponent("BsBtn")
  const _component_ElDialog = _resolveComponent("ElDialog")

  return (_openBlock(), _createBlock(_component_ElDialog, {
    class: "tg-mass-data",
    modelValue: _ctx.isVisible,
    "onUpdate:modelValue": _cache[38] || (_cache[38] = $event => ((_ctx.isVisible) = $event)),
    "append-to-body": true,
    "destroy-on-close": true,
    title: "Изменение данных",
    "align-center": ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[39] || (_cache[39] = _createElementVNode("div", { class: "control__label" }, "Выберите месяцы", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_BsMultiSelect, {
              modelValue: _ctx.months,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.months) = $event)),
              placeholder: "Введите или выберите месяцы из списка",
              options: this.detailMonthSelectData.options
            }, null, 8 /* PROPS */, ["modelValue", "options"])
          ])
        ]),
        _createVNode(_component_ElTabs, {
          class: "tg-mass-data__tabs",
          modelValue: _ctx.activeTab,
          "onUpdate:modelValue": _cache[35] || (_cache[35] = $event => ((_ctx.activeTab) = $event)),
          onTabClick: _cache[36] || (_cache[36] = () => {})
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ElTabPane, {
              class: "tg-mass-data__tab-pane",
              label: "Юр.лица",
              name: "customer"
            }, {
              label: _withCtx(() => _cache[40] || (_cache[40] = [
                _createElementVNode("div", { class: "tg-mass-data__tab-pane-label" }, "Юр.лица", -1 /* HOISTED */)
              ])),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _cache[41] || (_cache[41] = _createElementVNode("div", { class: "control__label" }, "Юр. лицо клиента", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_BsSelectV2, {
                          placeholder: "Выберите из списка",
                          modelValue: _ctx.customerFieldData.customer,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.customerFieldData.customer) = $event)),
                          options: _ctx.getOptions('customer_legal_entity'),
                          clearable: true,
                          filterable: true,
                          size: "large"
                        }, null, 8 /* PROPS */, ["modelValue", "options"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _cache[42] || (_cache[42] = _createElementVNode("div", { class: "control__label" }, "Юр. лицо МГ РИМ", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_BsSelectV2, {
                          placeholder: "Выберите из списка",
                          modelValue: _ctx.customerFieldData.rimLegalEntity,
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.customerFieldData.rimLegalEntity) = $event)),
                          options: _ctx.getOptions('rim_legal_entity'),
                          clearable: true,
                          filterable: true,
                          size: "large"
                        }, null, 8 /* PROPS */, ["modelValue", "options"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _cache[43] || (_cache[43] = _createElementVNode("div", { class: "control__label" }, "Оператор", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_11, [
                        _createVNode(_component_BsSelectV2, {
                          placeholder: "Выберите из списка",
                          modelValue: _ctx.customerFieldData.operator,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.customerFieldData.operator) = $event)),
                          options: _ctx.getOptions('operator'),
                          clearable: true,
                          filterable: true,
                          size: "large"
                        }, null, 8 /* PROPS */, ["modelValue", "options"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                      _cache[44] || (_cache[44] = _createElementVNode("div", { class: "control__label" }, "Печать", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_13, [
                        _createVNode(_component_BsSelectV2, {
                          placeholder: "Выберите из списка",
                          modelValue: _ctx.customerFieldData.printer,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.customerFieldData.printer) = $event)),
                          options: _ctx.getOptions('printer'),
                          clearable: true,
                          filterable: true,
                          size: "large"
                        }, null, 8 /* PROPS */, ["modelValue", "options"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                      _cache[45] || (_cache[45] = _createElementVNode("div", { class: "control__label" }, "Монтаж", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_15, [
                        _createVNode(_component_BsSelectV2, {
                          placeholder: "Выберите из списка",
                          modelValue: _ctx.customerFieldData.installer,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.customerFieldData.installer) = $event)),
                          options: _ctx.getOptions('installer'),
                          clearable: true,
                          filterable: true,
                          size: "large"
                        }, null, 8 /* PROPS */, ["modelValue", "options"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_16, [
                      _cache[46] || (_cache[46] = _createElementVNode("div", { class: "control__label" }, "Дизайн", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_17, [
                        _createVNode(_component_BsSelectV2, {
                          placeholder: "Выберите из списка",
                          modelValue: _ctx.customerFieldData.designer,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.customerFieldData.designer) = $event)),
                          options: _ctx.getOptions('designer'),
                          clearable: true,
                          filterable: true,
                          size: "large"
                        }, null, 8 /* PROPS */, ["modelValue", "options"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_18, [
                      _cache[47] || (_cache[47] = _createElementVNode("div", { class: "control__label" }, "Фотоотчет", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_19, [
                        _createVNode(_component_BsSelectV2, {
                          placeholder: "Выберите из списка",
                          modelValue: _ctx.customerFieldData.photoReporter,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_ctx.customerFieldData.photoReporter) = $event)),
                          options: _ctx.getOptions('photo_reporter'),
                          clearable: true,
                          filterable: true,
                          size: "large"
                        }, null, 8 /* PROPS */, ["modelValue", "options"])
                      ])
                    ])
                  ])
                ])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_ElTabPane, {
              class: "tg-mass-data__tab-pane",
              label: "Доп.услуги",
              name: "add-service"
            }, {
              label: _withCtx(() => _cache[48] || (_cache[48] = [
                _createElementVNode("div", { class: "tg-mass-data__tab-pane-label" }, "Доп.услуги", -1 /* HOISTED */)
              ])),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("div", _hoisted_21, [
                    _createElementVNode("div", _hoisted_22, [
                      _cache[49] || (_cache[49] = _createElementVNode("div", { class: "control__label" }, "Монтаж", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_23, [
                        _createElementVNode("div", _hoisted_24, [
                          _createVNode(_component_ElInputNumber, {
                            modelValue: _ctx.serviceFieldData.installCount,
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => ((_ctx.serviceFieldData.installCount) = $event)),
                            "controls-position": "right",
                            min: 0,
                            step: 1,
                            onKeydown: _cache[9] || (_cache[9] = _withKeys(() => {}, ["enter"]))
                          }, null, 8 /* PROPS */, ["modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_25, [
                          _createVNode(_component_BsInput, {
                            placeholder: "Введите стоимость",
                            modelValue: _ctx.serviceFieldData.installPrice,
                            "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => ((_ctx.serviceFieldData.installPrice) = $event)),
                            clearable: true,
                            type: "number",
                            min: "0",
                            step: "0.01"
                          }, null, 8 /* PROPS */, ["modelValue"])
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_26, [
                      _cache[50] || (_cache[50] = _createElementVNode("div", { class: "control__label" }, "Доп. монтаж", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_27, [
                        _createElementVNode("div", _hoisted_28, [
                          _createElementVNode("div", _hoisted_29, [
                            _createVNode(_component_ElInputNumber, {
                              modelValue: _ctx.serviceFieldData.addInstallCount,
                              "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => ((_ctx.serviceFieldData.addInstallCount) = $event)),
                              "controls-position": "right",
                              min: 0,
                              step: 1,
                              onKeydown: _cache[12] || (_cache[12] = _withKeys(() => {}, ["enter"]))
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ]),
                          _createElementVNode("div", _hoisted_30, [
                            _createVNode(_component_BsInput, {
                              placeholder: "Введите стоимость",
                              modelValue: _ctx.serviceFieldData.addInstallPrice,
                              "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => ((_ctx.serviceFieldData.addInstallPrice) = $event)),
                              clearable: true,
                              type: "number",
                              min: "0",
                              step: "0.01"
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ])
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_31, [
                      _cache[51] || (_cache[51] = _createElementVNode("div", { class: "control__label" }, "Печать", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_32, [
                        _createElementVNode("div", _hoisted_33, [
                          _createElementVNode("div", _hoisted_34, [
                            _createVNode(_component_ElInputNumber, {
                              modelValue: _ctx.serviceFieldData.printCount,
                              "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => ((_ctx.serviceFieldData.printCount) = $event)),
                              "controls-position": "right",
                              min: 0,
                              step: 1,
                              onKeydown: _cache[15] || (_cache[15] = _withKeys(() => {}, ["enter"]))
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ]),
                          _createElementVNode("div", _hoisted_35, [
                            _createVNode(_component_BsInput, {
                              placeholder: "Введите стоимость",
                              modelValue: _ctx.serviceFieldData.printPrice,
                              "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => ((_ctx.serviceFieldData.printPrice) = $event)),
                              clearable: true,
                              type: "number",
                              min: "0",
                              step: "0.01"
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ])
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_36, [
                      _cache[52] || (_cache[52] = _createElementVNode("div", { class: "control__label" }, "Срочная печать", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_37, [
                        _createElementVNode("div", _hoisted_38, [
                          _createElementVNode("div", _hoisted_39, [
                            _createVNode(_component_ElInputNumber, {
                              modelValue: _ctx.serviceFieldData.printExpCount,
                              "onUpdate:modelValue": _cache[17] || (_cache[17] = $event => ((_ctx.serviceFieldData.printExpCount) = $event)),
                              "controls-position": "right",
                              min: 0,
                              step: 1,
                              onKeydown: _cache[18] || (_cache[18] = _withKeys(() => {}, ["enter"]))
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ]),
                          _createElementVNode("div", _hoisted_40, [
                            _createVNode(_component_BsInput, {
                              placeholder: "Введите стоимость",
                              modelValue: _ctx.serviceFieldData.printExpPrice,
                              "onUpdate:modelValue": _cache[19] || (_cache[19] = $event => ((_ctx.serviceFieldData.printExpPrice) = $event)),
                              clearable: true,
                              type: "number",
                              min: "0",
                              step: "0.01"
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ])
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_41, [
                      _cache[53] || (_cache[53] = _createElementVNode("div", { class: "control__label" }, "Дизайн", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_42, [
                        _createElementVNode("div", _hoisted_43, [
                          _createElementVNode("div", _hoisted_44, [
                            _createVNode(_component_ElInputNumber, {
                              modelValue: _ctx.serviceFieldData.designCount,
                              "onUpdate:modelValue": _cache[20] || (_cache[20] = $event => ((_ctx.serviceFieldData.designCount) = $event)),
                              "controls-position": "right",
                              min: 0,
                              step: 1,
                              onKeydown: _cache[21] || (_cache[21] = _withKeys(() => {}, ["enter"]))
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ]),
                          _createElementVNode("div", _hoisted_45, [
                            _createVNode(_component_BsInput, {
                              placeholder: "Введите стоимость",
                              modelValue: _ctx.serviceFieldData.designPrice,
                              "onUpdate:modelValue": _cache[22] || (_cache[22] = $event => ((_ctx.serviceFieldData.designPrice) = $event)),
                              clearable: true,
                              type: "number",
                              min: "0",
                              step: "0.01"
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ])
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_46, [
                      _cache[54] || (_cache[54] = _createElementVNode("div", { class: "control__label" }, "Фотоотчет", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_47, [
                        _createElementVNode("div", _hoisted_48, [
                          _createElementVNode("div", _hoisted_49, [
                            _createVNode(_component_ElInputNumber, {
                              modelValue: _ctx.serviceFieldData.photoReportCount,
                              "onUpdate:modelValue": _cache[23] || (_cache[23] = $event => ((_ctx.serviceFieldData.photoReportCount) = $event)),
                              "controls-position": "right",
                              min: 0,
                              step: 1,
                              onKeydown: _cache[24] || (_cache[24] = _withKeys(() => {}, ["enter"]))
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ]),
                          _createElementVNode("div", _hoisted_50, [
                            _createVNode(_component_BsInput, {
                              placeholder: "Введите стоимость",
                              modelValue: _ctx.serviceFieldData.photoReportPrice,
                              "onUpdate:modelValue": _cache[25] || (_cache[25] = $event => ((_ctx.serviceFieldData.photoReportPrice) = $event)),
                              clearable: true,
                              type: "number",
                              min: "0",
                              step: "0.01"
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_ElTabPane, {
              class: "tg-mass-data__tab-pane",
              label: "Другое",
              name: "other"
            }, {
              label: _withCtx(() => _cache[55] || (_cache[55] = [
                _createElementVNode("div", { class: "tg-mass-data__tab-pane-label" }, "Другое", -1 /* HOISTED */)
              ])),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_51, [
                  _createElementVNode("div", _hoisted_52, [
                    _createElementVNode("div", _hoisted_53, [
                      _cache[56] || (_cache[56] = _createElementVNode("div", { class: "control__label" }, "Тип размещения", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_54, [
                        _createVNode(_component_BsSelectV2, {
                          placeholder: "Выберите из списка",
                          modelValue: _ctx.otherFieldData.placementType,
                          "onUpdate:modelValue": _cache[26] || (_cache[26] = $event => ((_ctx.otherFieldData.placementType) = $event)),
                          options: _ctx.getOptions('placement_type'),
                          clearable: true,
                          filterable: true,
                          size: "large"
                        }, null, 8 /* PROPS */, ["modelValue", "options"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_55, [
                      _cache[57] || (_cache[57] = _createElementVNode("div", { class: "control__label" }, "Категория клиента", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_56, [
                        _createVNode(_component_BsSelectV2, {
                          placeholder: "Выберите из списка",
                          modelValue: _ctx.otherFieldData.customerCategory,
                          "onUpdate:modelValue": _cache[27] || (_cache[27] = $event => ((_ctx.otherFieldData.customerCategory) = $event)),
                          options: _ctx.getOptions('customer_category'),
                          clearable: true,
                          filterable: true,
                          size: "large"
                        }, null, 8 /* PROPS */, ["modelValue", "options"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_57, [
                      _cache[58] || (_cache[58] = _createElementVNode("div", { class: "control__label" }, "Акция", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_58, [
                        _createVNode(_component_BsSelectV2, {
                          placeholder: "Выберите из списка",
                          modelValue: _ctx.otherFieldData.promo,
                          "onUpdate:modelValue": _cache[28] || (_cache[28] = $event => ((_ctx.otherFieldData.promo) = $event)),
                          options: _ctx.getOptions('promo'),
                          clearable: true,
                          filterable: true,
                          size: "large"
                        }, null, 8 /* PROPS */, ["modelValue", "options"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_59, [
                      _cache[59] || (_cache[59] = _createElementVNode("div", { class: "control__label" }, "Продукт", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_60, [
                        _createVNode(_component_BsSelectV2, {
                          placeholder: "Выберите из списка",
                          modelValue: _ctx.otherFieldData.product,
                          "onUpdate:modelValue": _cache[29] || (_cache[29] = $event => ((_ctx.otherFieldData.product) = $event)),
                          options: _ctx.getOptions('product'),
                          clearable: true,
                          filterable: true,
                          size: "large"
                        }, null, 8 /* PROPS */, ["modelValue", "options"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_61, [
                      _createElementVNode("div", _hoisted_62, [
                        _cache[60] || (_cache[60] = _createElementVNode("div", { class: "control__label" }, "Ответственный менеджер", -1 /* HOISTED */)),
                        _createElementVNode("div", _hoisted_63, [
                          _createVNode(_component_BsSelectV2, {
                            placeholder: "Выберите из списка",
                            modelValue: _ctx.otherFieldData.respManager,
                            "onUpdate:modelValue": _cache[30] || (_cache[30] = $event => ((_ctx.otherFieldData.respManager) = $event)),
                            options: _ctx.getOptions('managers'),
                            clearable: true,
                            filterable: true,
                            size: "large"
                          }, null, 8 /* PROPS */, ["modelValue", "options"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_64, [
                        _cache[61] || (_cache[61] = _createElementVNode("div", { class: "control__label" }, "Менеджер привлечения", -1 /* HOISTED */)),
                        _createElementVNode("div", _hoisted_65, [
                          _createVNode(_component_BsSelectV2, {
                            placeholder: "Выберите из списка",
                            modelValue: _ctx.otherFieldData.engManager,
                            "onUpdate:modelValue": _cache[31] || (_cache[31] = $event => ((_ctx.otherFieldData.engManager) = $event)),
                            options: _ctx.getOptions('managers'),
                            clearable: true,
                            filterable: true,
                            size: "large"
                          }, null, 8 /* PROPS */, ["modelValue", "options"])
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_66, [
                      _cache[62] || (_cache[62] = _createElementVNode("div", { class: "control__label" }, "Коэффициент", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_67, [
                        _createVNode(_component_BsInput, {
                          placeholder: "Введите данные",
                          modelValue: _ctx.otherFieldData.coefficient,
                          "onUpdate:modelValue": _cache[32] || (_cache[32] = $event => ((_ctx.otherFieldData.coefficient) = $event)),
                          clearable: true,
                          type: "number",
                          min: "0",
                          step: "0.01"
                        }, null, 8 /* PROPS */, ["modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_68, [
                      _createElementVNode("div", _hoisted_69, [
                        _cache[63] || (_cache[63] = _createElementVNode("div", { class: "control__label" }, "Агентское СК", -1 /* HOISTED */)),
                        _createElementVNode("div", _hoisted_70, [
                          _createVNode(_component_BsInput, {
                            placeholder: "Введите данные",
                            modelValue: _ctx.otherFieldData.agentCommission,
                            "onUpdate:modelValue": _cache[33] || (_cache[33] = $event => ((_ctx.otherFieldData.agentCommission) = $event)),
                            clearable: true,
                            type: "number",
                            min: "0",
                            step: "0.01"
                          }, null, 8 /* PROPS */, ["modelValue"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_71, [
                        _cache[64] || (_cache[64] = _createElementVNode("div", { class: "control__label" }, " Клиентское СК", -1 /* HOISTED */)),
                        _createElementVNode("div", _hoisted_72, [
                          _createVNode(_component_BsInput, {
                            placeholder: "Введите данные",
                            modelValue: _ctx.otherFieldData.customerCommission,
                            "onUpdate:modelValue": _cache[34] || (_cache[34] = $event => ((_ctx.otherFieldData.customerCommission) = $event)),
                            clearable: true,
                            type: "number",
                            min: "0",
                            step: "0.01"
                          }, null, 8 /* PROPS */, ["modelValue"])
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_73, [
        _createVNode(_component_BsBtn, {
          disabled: _ctx.disabledBtnChange,
          type: "primary",
          size: "small",
          onClick: _ctx.clickChange
        }, {
          default: _withCtx(() => _cache[65] || (_cache[65] = [
            _createTextVNode("Изменить")
          ])),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["disabled", "onClick"]),
        _createVNode(_component_BsBtn, {
          size: "small",
          onClick: _cache[37] || (_cache[37] = $event => (_ctx.isVisible=false))
        }, {
          default: _withCtx(() => _cache[66] || (_cache[66] = [
            _createTextVNode("Отмена")
          ])),
          _: 1 /* STABLE */
        })
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}