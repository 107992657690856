import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = {
  key: 1,
  class: "pr-title-link"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_ctx.fieldData)
    ? (_openBlock(), _createElementBlock("a", {
        key: 0,
        class: "pr-title-link",
        title: "Открыть",
        href: _ctx.link
      }, _toDisplayString(_ctx.label), 9 /* TEXT, PROPS */, _hoisted_1))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, "–"))
}