import { ElTag, ElMessage } from "element-plus";
import 'element-plus/es/components/tag/style/css';

import helper from "@/app/utils/helper";

import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';
import DiEditSelect from '@/app/components/controls/edit-field/di-edit-select/DiEditSelect.vue';

export default {
	name: 'target-manager',
	components: {
		SvgIcon, DiEditSelect,
	},
	props:{
		isListAddition: {
			type: Boolean,
			default: false,
		},
		fieldData: {
			type: Object,
			default: {
				value: '',
				options: [],
			},
		},
		fieldListData: {
			type: Object,
			default: {
				value: '',
				options: [],
			},
		},
		titleDialog: {
			type: String,
			default: '',
		},
		typeSelect: String,
	},
	emits: ['change', 'onAdd', 'onRemove'],
	data() {
		return {
			isVisible: false,
			isVisibleList: false,
		}
	},
	computed: {
		/*
		{
			id: helper.hash,
			name: 'Владислав',
			lastName: 'Петров',
			group: 'ФРА',
			contacts: {
				phone: '89998887766',
				email: 'test@test.ru',
				telegram: 'https://t.me/testerp',
			},
		},
		*/
		managerData(){
			const { value, options } = this.fieldData;
			if(!value) return;

			const findItem = options.find(item=>item.value==value);
			let { id, firstName, lastName, department, phone, email, tgId } = findItem ?? {};
			let contacts = {};

			if(tgId) tgId = `https://t.me/${tgId}`;
			if(phone || email || tgId) contacts = {
				phone: phone ?? '',
				email: email ?? '',
				telegram: tgId ?? '',
			}

			return {
				id, contacts,
				name: firstName ?? 'no name',
				lastName: lastName ?? '',
				group: department ?? '',
			}
		},
		label(){
			const fullName = `${this.managerData?.lastName ?? ''} ${this.managerData?.name ?? ''}`.trim();
			let label = fullName;
			if(this.managerData?.group) label += ` (${this.managerData.group})`;

			return label || 'пусто';
		},
		contacts(){
			return this.managerData?.contacts;
		},
		labelActiveButton(){
			return this.isListAddition ? 'Добавить' : 'Изменить';
		},
	},
	methods: {
		clickEdit(){
			console.log('clickEdit');
			this.isVisible = true;
		},
		async clickPhone(){
			const {phone} = this.contacts;

			try {
				const res = await helper.copyTextBuffer(phone);

				ElMessage({
					type: 'success',
					message: 'Номер телефона успешно скопирован',
				});
			} catch (error) {
				ElMessage({
					type: 'error',
					message: 'Номер телефона не удалось скопировать',
				});
			}

			console.log('copyPhone');
		},
		async clickEmail(){
			const {email} = this.contacts;

			try {
				const res = await helper.copyTextBuffer(email);

				ElMessage({
					type: 'success',
					message: 'Email успешно скопирован',
				});
			} catch (error) {
				ElMessage({
					type: 'error',
					message: 'Email не удалось скопировать',
				});
			}

			console.log('copyEmail');
		},
		async clickTelegram(){
			const {telegram} = this.contacts;

			try {
				const res = await helper.copyTextBuffer(telegram);

				ElMessage({
					type: 'success',
					message: 'Ссылка успешно скопирован',
				});
			} catch (error) {
				ElMessage({
					type: 'error',
					message: 'Ссылку не удалось скопировать',
				});
			}

			console.log('copyLinkTelegram');
		},
		clickAdd(){
			console.log('clickAdd');
			this.isVisibleList = true;
		},
		clickRemove(){
			console.log('clickRemove');
			this.$emit('onRemove', this.fieldData.value);
		},

		changeEdit(value){
			this.$emit('change', { value, fieldData: this.fieldData });
		}
	},
}