import { mapActions, mapState, mapWritableState } from 'pinia';
import { useRootStore } from '@/app/stores/root-store';
import { useDetailTargetProgStore } from '@/app/stores/detail-target-prog-store';

import { ElPopover, ElMessage } from 'element-plus';
import 'element-plus/es/components/popover/style/css';

import { colConfigList } from '../../table-config';

import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';
import TprogSettingsTable from '@/app/components/target-programs/tprog-detail-table/tprog-settings-table/TprogSettingsTable.vue';
import SearchPanel from '@/app/components/controls/search-panel/SearchPanel.vue';

export default {
	name: 'tprog-head-design',
	components: {
		ElPopover, SvgIcon,
		TprogSettingsTable, SearchPanel,
	},
	props:{
		colData: Object,
		notActions: {
			type: Boolean,
			default: false,
		}
	},
	emits: ['changeCompact', 'clickSettings', 'onSearch'],
	data() {
		return {
			colConfigList,
			isVisiblePopover: false,
			isCompact: false,
			isColumnCollapse: false,
			collapsedColumnData: {},
		}
	},
	computed: {
		label(){
			return this.colData.label;
		},
	},
	methods: {
		...mapActions( useDetailTargetProgStore, ['getTableSettingsLocStorage', 'setTableSettingsLocStorage'] ),

		clickCompact(){
			this.isCompact = !this.isCompact;
		},
		setSettingsConfig({ colConfig, isCollapsed, isCompact }){
			for(let item of this.colConfigList){
				const { key } = item;
				if(item.show === undefined || colConfig?.[key]===undefined) continue;
				item.show = colConfig[key];
			}

			this.isColumnCollapse = isCollapsed;
			this.isCompact = isCompact;
		},
		saveSettings(config){
			const res = this.setTableSettingsLocStorage({
				...config,
				isCompact: this.isCompact,
			});

			if(res){
				this.setSettingsConfig(res);
				this.isVisiblePopover = false;
				ElMessage({
					message: 'Настройки успешно установленны',
					type: 'success',
				});
			} else {
				ElMessage.error('Ошибка, что-то пошло не так.')
			}
		},
		onSearch(query){
			this.$emit('onSearch', query);
		}
	},
	watch: {
		isColumnCollapse(is){
			if(is) for(let item of this.colConfigList){
				if(item?.collapsed){
					const { key, show } = item;

					this.collapsedColumnData[key] = show;
					item.show = false;
				}
			}
			else for(let item of this.colConfigList){
				if(item?.collapsed){
					const { key } = item;
					item.show = this.collapsedColumnData[key];
				}
			}
		},
		isCompact(is){
			this.setTableSettingsLocStorage({
				colConfigList: this.colConfigList,
				isCompact: is,
				isCollapsed: this.isColumnCollapse,
			});

			this.$emit('changeCompact', is);
		}
	},
	created() {
		const settings = this.getTableSettingsLocStorage();
		console.log(settings);
		if(settings) this.setSettingsConfig(settings);
	},
}