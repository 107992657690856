/*
{
	key: '',
	keyProp: '',
	keyGroup: '',
	label: '',
	order: '',
	sort: true,
	show: true,
	minWidth: 160,
	maxWidth: '',
	classNameTh: '',
	classNameTd: '',
	fixed: false, // true, false, 'right'
},
*/

const columns = [
	{
		key: 'designInfo',
		keyProp: '',
		keyGroup: 'designInfo',
		label: 'РК',
		order: '',
		sort: true,
		show: true,
		minWidth: 530,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
		fixed: true,
	},
	{
		key: 'employ',
		keyProp: '',
		keyGroup: 'employ',
		label: 'Занятость',
		order: '',
		sort: false,
		show: true,
		minWidth: 530,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
		fixed: false,
	},
	{
		key: 'priceMonth',
		keyProp: '',
		keyGroup: 'priceMonth',
		label: 'Прайс/месяц',
		order: '',
		sort: true,
		show: true,
		minWidth: 170,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
		fixed: false,
	},
	{
		key: 'pricePeriod',
		keyProp: '',
		keyGroup: 'pricePeriod',
		label: 'Прайс/период',
		order: '',
		sort: true,
		show: true,
		minWidth: 170,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
		fixed: false,
	},
	{
		key: 'priceInstallationFra',
		keyProp: '',
		keyGroup: 'priceInstallationFra',
		label: 'Прайс монтаж ФРА',
		order: '',
		sort: true,
		show: true,
		minWidth: 140,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
		fixed: false,
	},
	{
		key: 'priceInstallationLocal',
		keyProp: '',
		keyGroup: 'priceInstallationLocal',
		label: 'Прайс монтаж Локалы',
		order: '',
		sort: true,
		show: true,
		minWidth: 140,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
		fixed: false,
	},
	{
		key: 'pricePrint',
		keyProp: '',
		keyGroup: 'pricePrint',
		label: 'Прайс печать',
		order: '',
		sort: true,
		show: true,
		minWidth: 140,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
		fixed: false,
	},
	{
		key: 'countFreeDays',
		keyProp: '',
		keyGroup: 'countFreeDays',
		label: 'Cвободных дней',
		order: '',
		sort: true,
		show: true,
		minWidth: 180,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
		fixed: false,
	},
	{
		key: 'grp',
		keyProp: '',
		keyGroup: 'grp',
		label: 'GRP',
		order: '',
		sort: true,
		show: true,
		minWidth: 90,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
		fixed: false,
	},
	{
		key: 'ots',
		keyProp: '',
		keyGroup: 'ots',
		label: 'OTS',
		order: '',
		sort: true,
		show: true,
		minWidth: 90,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
		fixed: false,
	},
	{
		key: 'photo',
		keyProp: '',
		keyGroup: 'photo',
		label: 'Фото',
		order: '',
		sort: false,
		show: true,
		minWidth: 140,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
		fixed: false,
	},
	{
		key: 'queue',
		keyProp: '',
		keyGroup: 'queue',
		label: 'Очередь',
		order: '',
		sort: false,
		show: true,
		minWidth: 250,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
		fixed: false,
	},
];

export { columns };