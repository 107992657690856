import { mapState, mapActions, mapWritableState } from 'pinia';
import { usePricingListStore } from '@/app/stores/pricing-policies/pricing-list-store';
import { useFiltersStore } from '@/app/stores/filters-store';

import { columns } from "./table-config";

import { ElMessage, ElMessageBox } from "element-plus";

import TableGrid from "@/app/components/table-grid/TableGrid.vue";
import CellMassActions from "@/app/components/table/cell-types/cell-mass-actions/CellMassActions.vue";
import CellDate from '@/app/components/table/cell-types/cell-date/CellDate.vue';
import CellTags from "@/app/components/table/cell-types/cell-tags/CellTags.vue";
import PrCellResponsible from "@/app/components/pricing-policies/type-cell/pr-cell-responsible/PrCellResponsible.vue";
import CellNumber from "@/app/components/table/cell-types/cell-number/CellNumber.vue";
import PrTitleLink from "@/app/components/pricing-policies/type-cell/pr-title-link/PrTitleLink.vue";

import MassPricesStatusDialog from '@/app/components/pricing-policies/dialogs/mass-prices-status-dialog/MassPricesStatusDialog.vue';
import MassPricesDepartDialog from '@/app/components/pricing-policies/dialogs/mass-prices-depart-dialog/MassPricesDepartDialog.vue';
import MassPricesManagerDialog from '@/app/components/pricing-policies/dialogs/mass-prices-manager-dialog/MassPricesManagerDialog.vue';

export default {
	name: 'pricing-list-table',
	components: {
		TableGrid, CellMassActions, CellDate,
		CellTags, PrCellResponsible, CellNumber,
		PrTitleLink,
		MassPricesStatusDialog, MassPricesDepartDialog, MassPricesManagerDialog,
	},
	props: {
		tableData: {
			type: Array,
			default: [],
		}
	},
	emits: ['update:tableData'],
	data() {
		return {
			columns,
			massActionsList: [
				{
					key: 'status',
					label: 'Изменить статус',
				},
				{
					key: 'depart',
					label: 'Изменить отделы',
				},
				{
					key: 'manager',
					label: 'Изменить менеджера',
				},
				{
					key: 'remove',
					label: 'Удалить',
				},
			],
			actionList: [
				{
					key: 'remove',
					label: 'Удалить',
				},
			],
			selectedRows: [],

			isShowMassStatusDialog: false,
			isShowMassDepartDialog: false,
			isShowMassManagerDialog: false,
		}
	},
	methods: {
		...mapActions(useFiltersStore, ['getOptions']),
		...mapActions(usePricingListStore, [
			'getPriceList', 'removePricePolicy', 'massPricesRemoved',
			'massChangeStatus', 'massChangeDepart', 'massChangeManager',
		]),

		// Массовые действия
		changeMass(key){
			console.log('changeMass', {key});
			if(!this.selectedRows?.length) return;

			if(key=='remove') this.massRemoved();
			else if(key=='status') this.isShowMassStatusDialog = true;
			else if(key=='depart') this.isShowMassDepartDialog = true;
			else if(key=='manager') this.isShowMassManagerDialog = true;
		},

		massRemoved(){
			const ids = this.selectedRows.map(item=>item.id);

			ElMessageBox.confirm(null, null, {
				title: 'Внимание',
				message: 'Вы точно хотите удалить выбранные прайсовые политики?',
				showClose: false,
				customClass: 'bs-message-box',
				type: 'warning',
			}).then(async () => {
				const res = await this.massPricesRemoved(ids);
				if(!res) return;

				this.getPriceList();
				ElMessage({
					type: 'success',
					message: 'Прайсовые политики успешно удалёны',
				});
			}).catch(() => {
				ElMessage({
					type: 'info',
					message: 'Удаление отменено',
				})
			});
		},

		async massStatus({ status }){
			console.log(status);
			const ids = this.selectedRows.map(item=>item.id);

			const res = await this.massChangeStatus({ ids, status });
			if(!res) return;

			this.getPriceList();
			ElMessage({
				type: 'success',
				message: 'Статус успешно изменен',
			});
		},

		async massDepart({ department }){
			console.log(department);
			const ids = this.selectedRows.map(item=>item.id);

			const res = await this.massChangeDepart({ ids, departmentIds: department });
			if(!res) return;

			this.getPriceList();
			ElMessage({
				type: 'success',
				message: 'Отделы успешно изменены',
			});
		},

		async massManager({ manager }){
			console.log(manager);
			const ids = this.selectedRows.map(item=>item.id);

			const res = await this.massChangeManager({ ids, managerId: manager });
			if(!res) return;

			this.getPriceList();
			ElMessage({
				type: 'success',
				message: 'Менеджер успешно изменен',
			});
		},

		// Изменение строки
		changeRow(key, rowData){
			console.log('changeRow', {key, rowData});

			if(key=='remove'){
				ElMessageBox.confirm(null, null, {
					title: 'Внимание',
					message: 'Вы точно хотите удалить прайсовую политику?',
					showClose: false,
					customClass: 'bs-message-box',
					type: 'warning',
				}).then(async () => {
					const res = await this.removePricePolicy(rowData?.id);
					if(!res) return;

					const tableData = this.tableData.filter(item=>item.id!=rowData.id);
					this.$emit('update:tableData', tableData);

					ElMessage({
						type: 'success',
						message: 'Прайсовая политика успешно удалёна',
					});
				}).catch(() => {
					ElMessage({
						type: 'info',
						message: 'Удаление отменено',
					})
				});
			}
		},

		changeSelectedRows(list){
			// console.log('changeSelectedRows', list);
			this.selectedRows = list;
		},
		dblClickRow(rowData){
			console.log('dblClickRow', rowData);

			const { id } = rowData;
			this.$router.push({
				name: 'pricing-policies-item',
				params: { id },
			});
		}
	},
}