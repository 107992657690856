/*
{
	key: '',
	keyProp: '',
	keyGroup: '',
	label: '',
	order: '',
	sort: true,
	show: true,
	minWidth: 160,
	maxWidth: '',
	classNameTh: '',
	classNameTd: '',
	fixed: false, // true, false, 'right'
},
*/

const columns = [
	{
		key: 'id',
		keyProp: 'id',
		keyGroup: '',
		label: 'ID',
		order: '',
		sort: true,
		show: true,
		minWidth: 70,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
	},
	{
		key: 'status',
		keyProp: 'status',
		keyGroup: '',
		label: 'Статус',
		order: '',
		sort: true,
		show: true,
		minWidth: 140,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
	},
	{
		key: 'type',
		keyProp: 'type',
		keyGroup: '',
		label: 'Тип',
		order: '',
		sort: true,
		show: true,
		minWidth: 140,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
	},
	{
		key: 'title',
		keyProp: 'name',
		keyGroup: '',
		label: 'Название',
		order: '',
		sort: true,
		show: true,
		minWidth: 140,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
	},
	{
		key: 'count',
		keyProp: 'targetProgramsCount',
		keyGroup: '',
		label: 'Кол-во АП',
		order: '',
		sort: true,
		show: true,
		minWidth: 90,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
	},
	{
		key: 'createdAt',
		keyProp: 'createdAt',
		keyGroup: '',
		label: 'Дата создания',
		order: '',
		sort: true,
		show: true,
		minWidth: 140,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
	},
	{
		key: 'updatedAt',
		keyProp: 'updatedAt',
		keyGroup: '',
		label: 'Дата/время <br> обновления',
		order: '',
		sort: true,
		show: true,
		minWidth: 140,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
	},
	{
		key: 'responsible',
		keyProp: 'manager',
		keyGroup: '',
		label: 'Ответственный <br> менеджер',
		order: '',
		sort: true,
		show: true,
		minWidth: 140,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
	},
	{
		key: 'department',
		keyProp: 'departments',
		keyGroup: '',
		label: 'Отделы',
		order: '',
		sort: true,
		show: true,
		minWidth: 280,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
	},

	{
		key: 'massAction',
		keyProp: '',
		keyGroup: '',
		label: '',
		order: '',
		sort: false,
		show: true,
		minWidth: 30,
		maxWidth: '',
		classNameTh: 'th-mass-actions',
		classNameTd: 'td-mass-actions',
		noSettings: true,
		fixed: 'right',
	},
];

export { columns };