import { ElDialog } from "element-plus";


import BsBtn from "@/app/components/controls/buttons/bs-btn/BsBtn.vue";
import BsInput from "@/app/components/controls/bs-input/BsInput.vue";

export default {
	name: 'tp-temp-save-dialog',
	components: {
		ElDialog,
		BsBtn, BsInput,
	},
	props: {
		visible:{
			type: Boolean,
			default: false,
		},
	},
	emits: ['update:visible', 'save'],
	data() {
		return {
			tempName: '',
		}
	},
	computed: {
		isVisible: {
			get(){
				return this.visible;
			},
			set(is){
				this.$emit('update:visible', is);
			},
		},
		isBtnSaveDIsabled() {
			return !(this.tempName?.length > 2);
		}
	},
	methods: {
		clickSave(){
			this.$emit('save', this.tempName);
			this.tempName = '';
			this.isVisible = false;
		},
		clickCancel(){
			this.tempName = '';
			this.isVisible = false;
		}
	},
}