import { mapState, mapActions, mapWritableState } from 'pinia';
import { useRootStore } from '@/app/stores/root-store';
import { useMapDesignStore } from "@/app/stores/map-design-store";
import { useDesignFilterStore } from '@/app/stores/design-filter-store';
import { useDialogStore } from '@/app/stores/dialog-store';
import { useDetailTargetProgStore } from '@/app/stores/detail-target-prog-store';

import dayjs from 'dayjs';

import { ElMessage, ElMessageBox, ElTooltip } from 'element-plus';

import helper from '@/app/utils/helper';
import api from '@/app/utils/service-api';

import LoaderSpinner from '@/app/components/loaders/loader-spinner/LoaderSpinner.vue';
import SideSelect from '@/app/components/map/map-design-info/side-select/SideSelect.vue';
import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';
import BsInput from '@/app/components/controls/bs-input/BsInput.vue';
import BsMultiSelect from '@/app/components/controls/selects/bs-multi-select/BsMultiSelect.vue';
import BsSingleSelect from '@/app/components/controls/selects/bs-single-select/BsSingleSelect.vue';
import BsBtn from '@/app/components/controls/buttons/bs-btn/BsBtn.vue';
import TagList from '@/app/components/map/map-design-info/tag-list/TagList.vue';
import SideQueue from '@/app/components/map/map-design-info/side-queue/SideQueue.vue';
import SideQueueV2 from '@/app/components/map/map-design-info/side-queue-v2/SideQueueV2.vue';
import BsPhotoSlider from '@/app/components/bs-photo-slider/BsPhotoSlider.vue';
import EmployInfo from '@/app/components/map/map-design-info/employ-info/EmployInfo.vue';
import EmployCalendar from '@/app/components/map/map-design-info/employ-calendar/EmployCalendar.vue';
import EmployDatepicker from '@/app/components/datepickers/employ-datepicker/EmployDatepicker.vue';

export default {
	name: 'map-design-info',
	components: {
		ElTooltip,
		LoaderSpinner,
		SideSelect, SvgIcon, BsInput, BsMultiSelect, BsSingleSelect, BsBtn,
		TagList, SideQueue, SideQueueV2, BsPhotoSlider, EmployInfo, EmployCalendar,
		EmployDatepicker,
	},
	data() {
		return {

		}
	},
	computed: {
		...mapState(useDesignFilterStore, ['targetProgram', 'city']),

		...mapWritableState(useRootStore, ['activeDesignView']),
		...mapWritableState(useDesignFilterStore, ['isFilterDataLoad']),
		...mapWritableState(useMapDesignStore, [ 'detailDesignData','fakeDesignInfoData' ]),


		sideSelectData(){
			return this.detailDesignData?.sideSelectData;
		},
		side(){
			const sideId = this.sideSelectData?.value;
			const sides = this.detailDesignData.sides;
			return sides.find(item=>item.id==sideId);
		},
		tagList(){
			return this.fakeDesignInfoData.tagList;
		},
		sideQueueList(){
			return this.fakeDesignInfoData.sideQueueList;
		},
		// digitalData(){
		// 	return this.fakeDesignInfoData?.digitalData;
		// },
		staticOccupancy(){
			return this.side?.occupancies;
		},
		digitalOccupancy(){
			// return this.fakeDesignInfoData?.digitalOccupancy;
			return this.side?.occupancies;
		},
		linkAddressYMap(){
			const { coords } = this.detailDesignData;
			let url = new URL('https://yandex.ru/maps/');
			const params = {
				'll': coords.join(','),
				'whatshere[point]': coords.join(','),
				'whatshere[zoom]': '16',
				'z': '17',
				mode: 'whatshere',
			}
			url.search = new URLSearchParams(params).toString();

			return url.toString();
		},
		calendar(){
			// Данные поменялись, сейчас календаря нет
			const occupancy = this.side?.occupancy;
			const checkMonthDate = this.detailDesignData.checkMonthDate;
			return occupancy?.[checkMonthDate];
		},
		// queueData(){
		// 	const queue = this.calendar?.queue;
		// 	if(!queue?.list?.length) return;

		// 	return queue;
		// },
		queueData(){
			const occupancy = this.side?.queues;
			const checkMonthDate = this.detailDesignData.checkMonthDate;

			return occupancy?.[checkMonthDate];
		},
		title(){
			return `Очередь (${this.side?.gid})`
		},
		sideInfo(){
			return {
				gid: this.side.gid,
				period: this.detailDesignData.checkMonthDate,
				serviceStatus: this.side?.serviceStatus,
			}
		},
		pricePeriod(){
			const { selectedPeriods, priceMonth } = this.side;
			const { checkMonthDate } = this.detailDesignData;
			const periods = selectedPeriods?.[checkMonthDate] ?? [];

			const countDaysInMonth = dayjs(checkMonthDate).daysInMonth();
			let totalSelectedDays = 0;

			for(const [ from, to ] of periods){
				totalSelectedDays += dayjs(to).diff(from, 'day')+1;
			}
			// console.log({ totalSelectedDays, countDaysInMonth, checkMonthDate, selectedPeriods });
			return (totalSelectedDays/countDaysInMonth) * priceMonth;
		},
		contractEndDate(){
			return dayjs(this.detailDesignData?.contractEndDate).format('DD.MM.YYYY');
		}
	},
	methods: {
		...mapActions(useDialogStore, ['openTargetProgDialog', 'addTargetProgram']),
		...mapActions(useMapDesignStore, ['getStructure']),
		...mapActions(useDetailTargetProgStore, ['getTargetTabData']),

		openSideQueue(){
			this.isOpenSideQueue = !this.isOpenSideQueue;
		},
		async copyLink(){
			const { origin } = window.location;
			const { id, gid, cityId } = this.detailDesignData;
			let city = [];

			if(cityId) city = [cityId];

			const data = {
				structureId: id,
				sideId: this.sideSelectData.value,
				city
			}
			const params = api.buildParams(data).toString();
			const link = `${origin}/structures?${params}`;

			console.log('copyLink', params);



			try {
				await helper.copyTextBuffer(link);

				ElMessage({
					type: 'success',
					message: 'Ссылка успешно скопирована',
				});
			} catch (error) {
				ElMessage({
					type: 'error',
					message: 'Ссылку не удалось скопировать',
				});
			}

			console.log('copyLink');
		},
		openTechReq(){
			const { techRequirements } = this.side;
			if( techRequirements ) window.open(techRequirements, '_blank');
		},
		getMoney(num){
			return helper.getMoney(num);
		},
		async addTargetProg(){
			console.log('addTargetProg', this.side.id);
			const targetProgId = this.targetProgram.value;
			let data = {
				side_id: this.side.id,
				periods: [],
				status: '',
			};

			if(this.side.selectedPeriods) for(let [key, monthPeriods] of Object.entries(this.side.selectedPeriods)){
				data.periods.push(...monthPeriods);
			}

			// Если периодов в градуснике не выбрано, берем период из фильтра
			// if(!data.periods?.length){
			// 	const { from, to } = this.detailDesignData.period;
			// 	data.periods = [[from, to]];
			// }

			if(this.side?.digitalData){
				const { digitalDuration, outFrequency } = this.side;
				data = {
					...data,
					duration: digitalDuration.value,
					frequency: outFrequency.value,
				}
			}
			console.log('addTargetProg', data);

			if(targetProgId){
				const res = await this.addTargetProgram({
					orders: [data],
					target_program_id: targetProgId,
				});
				console.log(res);
				if(!res) return;

				this.getTargetTabData(targetProgId);
				ElMessage({
					type: 'success',
					message: 'РС успешно добавлена',
				});
			} else {
				this.openTargetProgDialog(data);
			}
		},
		async clickRefresh(){
			console.log('clickRefresh');
			const { id } = this.detailDesignData;

			this.isFilterDataLoad = true;
			await this.getStructure({ id, noDataMap: false });
			this.isFilterDataLoad  =false;
		},
		async clickCenterMap(){
			if( this.activeDesignView=='map' ){
				const { coords } = this.detailDesignData;
				if(coords === undefined) return;
				// console.log('clickCenterMap', coords);

				$(window).trigger('set-center:map', { coords });
			}
			else if(this.activeDesignView=='grid') {
				const { id } = this.detailDesignData;

				this.$router.push({
					name: 'structures',
					query: {
						structureId: id,
						'city[]': this.city.value,
					}
				});
				await helper.pause(0);
				this.activeDesignView = 'map';
			}
		},

	},
	created() {
		let str = 'с. Высокая гора, Большая Красная ул..';
		console.log(str.length);
	},
}