import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "pricing-detail-table" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "table-grid__cell"
}
const _hoisted_4 = {
  key: 0,
  class: "table-grid__cell"
}
const _hoisted_5 = {
  key: 1,
  class: "table-grid__cell"
}
const _hoisted_6 = {
  key: 2,
  class: "table-grid__cell"
}
const _hoisted_7 = {
  key: 3,
  class: "table-grid__cell"
}
const _hoisted_8 = {
  key: 4,
  class: "table-grid__cell"
}
const _hoisted_9 = {
  key: 5,
  class: "table-grid__cell"
}
const _hoisted_10 = {
  key: 6,
  class: "table-grid__cell"
}
const _hoisted_11 = {
  key: 7,
  class: "table-grid__cell"
}
const _hoisted_12 = {
  key: 8,
  class: "table-grid__cell"
}
const _hoisted_13 = {
  key: 9,
  class: "table-grid__cell"
}
const _hoisted_14 = {
  key: 10,
  class: "table-grid__cell"
}
const _hoisted_15 = {
  key: 11,
  class: "table-grid__cell"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CellMassActions = _resolveComponent("CellMassActions")
  const _component_HeadCellDefault = _resolveComponent("HeadCellDefault")
  const _component_CellSwitch = _resolveComponent("CellSwitch")
  const _component_PrCellEditNum = _resolveComponent("PrCellEditNum")
  const _component_CellDate = _resolveComponent("CellDate")
  const _component_PrCellRules = _resolveComponent("PrCellRules")
  const _component_CellPrice = _resolveComponent("CellPrice")
  const _component_PrCellCountStruct = _resolveComponent("PrCellCountStruct")
  const _component_PrCellEditPrice = _resolveComponent("PrCellEditPrice")
  const _component_PrCellEditPercent = _resolveComponent("PrCellEditPercent")
  const _component_PrCellCoef = _resolveComponent("PrCellCoef")
  const _component_PrCellComment = _resolveComponent("PrCellComment")
  const _component_TableGrid = _resolveComponent("TableGrid")
  const _component_MassRuleStatusDialog = _resolveComponent("MassRuleStatusDialog")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TableGrid, {
      columns: _ctx.columns,
      rows: _ctx.sortRowList,
      isSelected: true,
      onChangeSelectedRows: _ctx.changeSelectedRows
    }, {
      th: _withCtx(({ colData }) => [
        (colData.key=='key1')
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, "key1"))
          : (colData.key=='massAction')
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_CellMassActions, {
                  title: "Массовые действия",
                  count: _ctx.selectedRows.length,
                  actionList: _ctx.massActionList,
                  onChange: _ctx.changeMass
                }, null, 8 /* PROPS */, ["count", "actionList", "onChange"])
              ]))
            : (_openBlock(), _createBlock(_component_HeadCellDefault, {
                key: 2,
                modelValue: colData.order,
                "onUpdate:modelValue": $event => ((colData.order) = $event),
                label: colData.label,
                sort: colData.sort,
                btnSortType: "type-2",
                onChange: $event => (_ctx.sortTable(colData.order, colData.key))
              }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "label", "sort", "onChange"]))
      ]),
      td: _withCtx(({ colData, rowData }) => [
        (colData.key=='status')
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_CellSwitch, {
                data: { row: rowData },
                groupKey: colData.keyGroup,
                fieldKey: colData.keyProp,
                onChange: _ctx.changeDefaultCell
              }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey", "onChange"])
            ]))
          : (colData.key=='pos')
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_PrCellEditNum, {
                  data: { row: rowData },
                  groupKey: colData.keyGroup,
                  fieldKey: colData.keyProp,
                  onChange: _ctx.changeDefaultCell
                }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey", "onChange"])
              ]))
            : (['createdAt', 'updatedAt'].includes(colData.key))
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_CellDate, {
                    data: { row: rowData },
                    groupKey: colData.keyGroup,
                    fieldKey: colData.keyProp,
                    showTime: true
                  }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"])
                ]))
              : (colData.key=='rules')
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createVNode(_component_PrCellRules, {
                      data: { row: rowData },
                      groupKey: colData.keyGroup,
                      fieldKey: colData.keyProp,
                      onChange: _ctx.changeDefaultCell
                    }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey", "onChange"])
                  ]))
                : (colData.key=='avgPriceMonth')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createVNode(_component_CellPrice, {
                        data: { row: rowData },
                        groupKey: colData.keyGroup,
                        fieldKey: colData.keyProp
                      }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"])
                    ]))
                  : (colData.key=='countStructure')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createVNode(_component_PrCellCountStruct, {
                          data: { row: rowData },
                          groupKey: colData.keyGroup,
                          fieldKey: colData.keyProp
                        }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"])
                      ]))
                    : (_ctx.getPriceCell(colData.key))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _createVNode(_component_PrCellEditPrice, {
                            data: { row: rowData },
                            groupKey: colData.keyGroup,
                            fieldKey: colData.keyProp,
                            onChange: _ctx.changeDefaultCell
                          }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey", "onChange"])
                        ]))
                      : (['targetDiscount', 'maxDiscount', 'curMonthDiscount'].includes(colData.key))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                            _createVNode(_component_PrCellEditPercent, {
                              data: { row: rowData },
                              groupKey: colData.keyGroup,
                              fieldKey: colData.keyProp,
                              onChange: _ctx.changeDefaultCell
                            }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey", "onChange"])
                          ]))
                        : (colData.key=='coefficient')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                              _createVNode(_component_PrCellCoef, {
                                data: { row: rowData },
                                groupKey: colData.keyGroup,
                                fieldKey: colData.keyProp,
                                onChangeCoef: _ctx.changeDefaultCell,
                                onChangeMultip: _ctx.changeDefaultCell
                              }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey", "onChangeCoef", "onChangeMultip"])
                            ]))
                          : (colData.key=='comment')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                _createVNode(_component_PrCellComment, {
                                  data: { row: rowData },
                                  groupKey: colData.keyGroup,
                                  fieldKey: colData.keyProp,
                                  onChange: _ctx.changeDefaultCell
                                }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey", "onChange"])
                              ]))
                            : (colData.key=='massAction')
                              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                                  _createVNode(_component_CellMassActions, {
                                    actionList: _ctx.actionList,
                                    onChange: $event => (_ctx.changeRow($event, rowData))
                                  }, null, 8 /* PROPS */, ["actionList", "onChange"])
                                ]))
                              : (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(rowData[colData.key]), 1 /* TEXT */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["columns", "rows", "onChangeSelectedRows"]),
    _createVNode(_component_MassRuleStatusDialog, {
      visible: _ctx.isShowMassRuleStatusDialog,
      "onUpdate:visible": _cache[0] || (_cache[0] = $event => ((_ctx.isShowMassRuleStatusDialog) = $event)),
      onChange: _ctx.changeMassStatus
    }, null, 8 /* PROPS */, ["visible", "onChange"])
  ]))
}