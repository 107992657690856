import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "dstr-side-list__item" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_DstrSideItem = _resolveComponent("DstrSideItem")
  const _component_RecycleScroller = _resolveComponent("RecycleScroller")

  return (_openBlock(), _createBlock(_component_RecycleScroller, {
    class: "dstr-side-list",
    items: _ctx.list,
    "item-size": 52,
    keyField: "orderId"
  }, {
    default: _withCtx(({ item  }) => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_DstrSideItem, {
          data: item,
          onRemove: _ctx.removeItem,
          onOpenMap: _ctx.openSideMap
        }, null, 8 /* PROPS */, ["data", "onRemove", "onOpenMap"])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["items"]))
}