import { toRaw } from 'vue';
import { mapState, mapActions, mapWritableState } from 'pinia';
import { useRootStore } from '@/app/stores/root-store';
import { useMapDesignStore } from "@/app/stores/map-design-store";

import { ElPopover } from 'element-plus';

import helper from '@/app/utils/helper';

import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';
import MarkerSideSystem from '@/app/components/map/map-marker-2/marker-side-system/MarkerSideSystem.vue';
import DesignMarkerPopup from '@/app/components/map/design-marker-popup/DesignMarkerPopup.vue';

export default {
	name: 'map-marker-2',
	components:{
		ElPopover,
		SvgIcon, MarkerSideSystem, DesignMarkerPopup,
	},
	props: {
		data: {
			type: Object,
			default: {},
		},
	},
	data() {
		return {
			isShow: false,
			isHover: false,
			isOpenPopup: false,
			isHoverPopup: false,
			popupData: null,
		}
	},
	computed: {
		...mapState(useMapDesignStore, ['detailDesignData', 'checkMapMarkerView']),
		...mapState(useRootStore, ['isShowSecondAside'] ),

		structData(){
			return this.data?.structData || {};
		},
		markerSelector(){
			return `#marker-${this.data.code}`;
		},
		sides(){
			let sides = helper.clone(this.structData?.sides || []);
			sides.sort((a,b)=>(a.angle - b.angle));
			// console.log(sides);
			return sides;
		},
		designIcon(){
			return this.getTypeDesignIcon(this.structData.type);
		},
		statusIcon(){
			const { free, reserve, busy } = this.data?.statusInfo?.marker || {};

			if(free && reserve && busy) return 'fr-re-bs';
			else if(free && reserve) return 'fr-re';
			else if(free && busy) return 'fr-bs';
			else if(reserve && busy) return 're-bs';
			else if(reserve) return 're';
			else if(busy) return 'bs';
			else return 'fr';
		},
		inTargetProg(){
			return !!this.sides.find(item=>item.inTargetProgram);
		},
		isActive(){
			return this.data?.id && (this.detailDesignData?.id == this.data?.id);
		},
		isDigital(){
			return !!this.sides.find(item=>item?.isDigital || item?.is_digital);
		},
		typeView(){
			let type = 'view-design';

			if(this.checkMapMarkerView) type = 'view-sides';
			if(this.isHover){
				if(type=='view-sides') return 'view-design';
				else if(type=='view-design') return 'view-sides';
			}

			return type; // 'view-design', 'view-sides'
		},

	},
	methods: {
		...mapActions( useMapDesignStore, ['getTypeDesignIcon', 'getStructure', 'getPopupStructure'] ),
		...mapActions(useRootStore, ['setShowSecondAside']),

		async openStructure(){
			console.log(this.data.id, this.data);

			await this.getStructure({ id: this.data.id, noDataMap: true });
			if(!this.isShowSecondAside) this.setShowSecondAside(true);
		},
		hasMarkerContainer(){
			const markerContainer = document.querySelector(this.markerSelector);
			this.isShow = !!markerContainer;
		},

		mouseEnter(){
			this.isHover = true;
		},
		mouseLeave(){
			this.isHover = false;
		},
		async openPopup(){
			console.log('markerData',{
				structData: this.structData,
				sides: this.sides,
				statusInfo: this.data?.statusInfo,
			});

			const { id } = this.structData;
			const res = await this.getPopupStructure(id);
			if(!res?.data) return;

			console.log('openPopup', res);
			this.popupData = res.data;
			$(window).trigger('open:markerPopup', { code: this.data.code });
			this.isOpenPopup = true;
		},

		mouseEnterPopup(){
			this.isHoverPopup = true;
		},
		mouseLeavePopup(){
			this.isHoverPopup = false;
		},
		closePopup(){
			this.isOpenPopup = false;
			this.isHoverPopup = false;
		}
	},
	watch: {
		isHover(is){
			if(is) helper.debounce({
				keyTimer: 'hover-enter-map-marker',
				duration: 500,
				action: ()=>{
					if(!this.isHover) return;

					this.openPopup();
				},
			});
			else helper.debounce({
				keyTimer: 'hover-leave-map-marker',
				duration: 500,
				action: ()=>{
					if(!this.isHover && !this.isHoverPopup) this.isOpenPopup = false;
				},
			});
		}
	},
	created() {
		// console.log(this.structData);
	},
	mounted(){
		const { placemark } = this.data;
		this.hasMarkerContainer();

		placemark.events.add('click', (e)=>{
			this.openStructure();
			// this.isOpenPopup = true;
		});
		placemark.events.add('mouseenter', (e)=>{
			this.mouseEnter();
		});
		placemark.events.add('mouseleave', (e)=>{
			this.mouseLeave();
		});

		$(window).on(`update:map.${this.data.code}`, ()=>{
			this.hasMarkerContainer();
			// this.closePopup();
		});
		$(window).on(`open:markerPopup.${this.data.code}`, (event, data)=>{
			if(data.code == this.data.code) return;

			this.closePopup();
		});
	},
	unmounted() {
		const { placemark } = this.data;
		placemark.events.remove('click', (e)=>{
			this.openStructure();
		});
		placemark.events.remove('mouseenter', (e)=>{
			this.mouseEnter();
		});
		placemark.events.remove('mouseleave', (e)=>{
			this.mouseLeave();
		});

		$(window).off(`update:map.${this.data.code}`);
		$(window).off(`open:markerPopup.${this.data.code}`);

		this.closePopup();
	},
}