import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "pricing-summary" }
const _hoisted_2 = {
  key: 0,
  class: "pricing-summary__container"
}
const _hoisted_3 = { class: "pricing-summary__block" }
const _hoisted_4 = { class: "control__group group-row gap-32" }
const _hoisted_5 = { class: "control" }
const _hoisted_6 = { class: "control__value" }
const _hoisted_7 = { class: "control" }
const _hoisted_8 = { class: "control__value" }
const _hoisted_9 = { class: "pricing-summary__block" }
const _hoisted_10 = { class: "control__group group-row gap-32" }
const _hoisted_11 = { class: "control" }
const _hoisted_12 = { class: "control__group" }
const _hoisted_13 = { class: "control" }
const _hoisted_14 = { class: "control__group" }
const _hoisted_15 = { class: "pricing-summary__block" }
const _hoisted_16 = { class: "control" }
const _hoisted_17 = { class: "control__group" }
const _hoisted_18 = { class: "pricing-summary__block" }
const _hoisted_19 = { class: "control" }
const _hoisted_20 = { class: "control__group" }
const _hoisted_21 = { class: "pricing-summary__block" }
const _hoisted_22 = { class: "pricing-summary__block" }
const _hoisted_23 = { class: "control" }
const _hoisted_24 = { class: "control__group" }
const _hoisted_25 = { class: "pricing-summary__block" }
const _hoisted_26 = { class: "pricing-summary__block" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_EditField = _resolveComponent("EditField")
  const _component_BsCheckbox = _resolveComponent("BsCheckbox")
  const _component_TargetManager = _resolveComponent("TargetManager")
  const _component_EditComment = _resolveComponent("EditComment")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.summaryData)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _cache[5] || (_cache[5] = _createElementVNode("div", { class: "control__label" }, "Дата/время создания", -1 /* HOISTED */)),
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.date.create), 1 /* TEXT */)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _cache[6] || (_cache[6] = _createElementVNode("div", { class: "control__label" }, "Дата/время обновления", -1 /* HOISTED */)),
                _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.date.update), 1 /* TEXT */)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _cache[7] || (_cache[7] = _createElementVNode("div", { class: "control__label" }, "Статус", -1 /* HOISTED */)),
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_EditField, {
                    editType: "select",
                    titleDialog: "Статус",
                    label: "пусто",
                    fieldData: _ctx.summaryData.status,
                    onChange: _cache[0] || (_cache[0] = $event => (_ctx.changeEditField($event, 'status')))
                  }, null, 8 /* PROPS */, ["fieldData"])
                ])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _cache[8] || (_cache[8] = _createElementVNode("div", { class: "control__label" }, "Тип", -1 /* HOISTED */)),
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_EditField, {
                    editType: "select",
                    titleDialog: "Тип",
                    label: "пусто",
                    fieldData: _ctx.summaryData.type,
                    onChange: _cache[1] || (_cache[1] = $event => (_ctx.changeEditField($event, 'type')))
                  }, null, 8 /* PROPS */, ["fieldData"])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _cache[9] || (_cache[9] = _createElementVNode("div", { class: "control__label" }, "Отделы, которые могут применять", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_17, [
                _createVNode(_component_EditField, {
                  editType: "multi-select",
                  titleDialog: "Отделы",
                  label: "пусто",
                  fieldData: _ctx.summaryData.departments,
                  onChange: _cache[2] || (_cache[2] = $event => (_ctx.changeEditField($event, 'departments')))
                }, null, 8 /* PROPS */, ["fieldData"])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, [
              _cache[10] || (_cache[10] = _createElementVNode("div", { class: "control__label" }, "Клиенты, на которые можно применять", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_20, [
                _createVNode(_component_EditField, {
                  editType: "multi-select",
                  titleDialog: "Клиенты",
                  label: "пусто",
                  fieldData: _ctx.summaryData.customers,
                  onChange: _cache[3] || (_cache[3] = $event => (_ctx.changeEditField($event, 'customers')))
                }, null, 8 /* PROPS */, ["fieldData"])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_21, [
            _createVNode(_component_BsCheckbox, {
              modelValue: _ctx.summaryData.showForAll,
              onChange: _ctx.changeShowAll
            }, {
              default: _withCtx(() => _cache[11] || (_cache[11] = [
                _createTextVNode("Показать всем")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["modelValue", "onChange"])
          ]),
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("div", _hoisted_23, [
              _cache[12] || (_cache[12] = _createElementVNode("div", { class: "control__label" }, "Ответственный менеджер", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_24, [
                _createVNode(_component_TargetManager, {
                  fieldData: _ctx.summaryData.responsibleManager,
                  titleDialog: "Изменить ответственного",
                  onChange: _cache[4] || (_cache[4] = $event => (_ctx.changeEditField($event, 'responsibleManager')))
                }, null, 8 /* PROPS */, ["fieldData"])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_25, [
            _createVNode(_component_EditComment, {
              comment: _ctx.summaryData.comment,
              titleDialog: "Изменение комментария",
              onChange: _ctx.changeComment
            }, null, 8 /* PROPS */, ["comment", "onChange"])
          ]),
          _createElementVNode("div", _hoisted_26, [
            _createVNode(_component_BsCheckbox, {
              modelValue: _ctx.summaryData.allowEdit,
              onChange: _ctx.changeBanOnChange
            }, {
              default: _withCtx(() => _cache[13] || (_cache[13] = [
                _createTextVNode("Запрет на изменение")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["modelValue", "onChange"])
          ])
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}