import { defineStore } from 'pinia';
import dayjs from 'dayjs';

import { useFiltersStore } from '../filters-store';
import { summaryUpdateConfig, ruleUpdateConfig } from './update-fields-map';

import api from '@/app/utils/service-api';
import helper from '@/app/utils/helper';
import errorService from '@/app/utils/error-service';


const fakeOptions = [
	{
		value: 2,
		label: 'Значение 2',
	},
	{
		value: 3,
		label: 'Значение 3',
	},
	{
		value: 4,
		label: 'Значение 4',
	},
	{
		value: 5,
		label: 'Значение 5',
	},
	{
		value: 6,
		label: 'Значение 6',
	},
];

export const usePricingDetailStore = defineStore('pricingDetailStore', {
	state() {
		return {
			isViewLoader: false,
			isAsideLoader: true,

			summaryData: null,

			filterData: {
				department: '',
				userRole: '',
				user: '',
				city: '',
				structureCategory: '',
				serviceStatus: '',
				structureType: '',
				sideDirection: '',
				sideSize: '',
				sideType: '',
				operator: '',
				rimGid: '',
				etherFraction: { input: '', select: '' },
				daysCount: { input: '', select: '' },
				isCurrentMonth: '',
				promo: '',
				isFragmentingPeriod: '',
			},

			tableData: [],
		}
	},

	getters: {
		countFilters(){
			let count = 0;

			for(const [key, value] of Object.entries(this.filterData)){
				if(['etherFraction', 'daysCount'].includes(key)){
					const { input } = value ?? {};
					if(input) count++;
				}
				else {
					if(value?.length) count++;
				}
			}

			return count;
		},
	},
	actions: {
		clearFilters(){
			for(const [key, value] of Object.entries(this.filterData)){
				if(['etherFraction', 'daysCount'].includes(key)) this.filterData[key] = { input: '', select: '' };
				else this.filterData[key] = '';
			}
		},

		buildSummaryData(data){
			const filterStore = useFiltersStore();
			const { getOptions } = filterStore;

			const {
				id, createdAt, updatedAt, name, status, type,
				departments, customers, manager, comment, showForAll, allowEdit,
			} = data ?? {};
			let responsibleManager = { value: '', options: [] };
			let typeOptions = getOptions('pricePolicyTypes');

			if(type=='additional-services'){
				typeOptions = typeOptions.map(item=>{
					return {
						...item,
						disabled: item.value != type,
					}
				});
			} else {
				typeOptions = typeOptions.map(item=>{
					return {
						...item,
						disabled: item.value == 'additional-services',
					}
				});
			}

			if(manager){
				const { id, name, email, phone, telegram, department } = manager;
				const fullName = name?.trim() ?? '';
				const [ lastName, firstName ] = fullName?.split(' ') ?? ['', ''];
				const options = getOptions('managers');
				const findIndex = options.findIndex(item=>item.value==id);

				const tmpObj = {
					id: id,
					value: id,
					label: fullName,
					firstName: firstName,
					lastName: lastName,
					department: department?.name ?? '',
					phone: phone ?? '',
					tgId: telegram ?? '',
					email: email ?? '',
				};

				if(findIndex >= 0){
					options[findIndex] = {
						...options[findIndex],
						...tmpObj,
					};
				} else {
					options.push(tmpObj);
				}

				responsibleManager = { value: id, options };
			}


			return {
				id,
				title: name ?? '',
				createDate: new Date(createdAt),
				updateDate: new Date(updatedAt),
				status: {
					value: status ?? '',
					options: getOptions('pricePolicyStatuses'),
				},
				type: {
					value: type ?? '',
					options: typeOptions,
				},
				departments: {
					value: departments?.map(item=>item.id) ?? [],
					options: getOptions('department'),
				},
				customers: {
					value: customers?.map(item=>item.id) ?? [],
					options: getOptions('customer'),
				},
				showForAll: !!showForAll,
				responsibleManager,
				comment: comment ?? '',
				allowEdit: !!allowEdit,
			};
		},

		async getPricePolicyData(id){
			if(!id) return;

			this.isAsideLoader = true;
			try {
				const res = await api.postData({
					model: `price-policy/${id}/get`,
				});
				console.log(res);
				if(res?.pricePolicy) this.summaryData = this.buildSummaryData(res.pricePolicy);

				this.isAsideLoader = false;
				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({error});
			}

			this.isAsideLoader = false;
			return;
		},

		/** Обновление одного поля прайсовой политики */
		async updateFieldItem({ pricePolicyId, key, value, fieldData }){
			const updateData = summaryUpdateConfig.find(item=>item.key==key);
			if(!updateData) return;

			const { param, keyField } = updateData;

			try {
				const res = await api.postData({
					model: `price-policy/${pricePolicyId}/${param}`,
					data: {
						[keyField]: value,
					}
				});
				console.log('updateFieldItem', res);

				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({error});
			}
			return;
		},

		/** Обновление одного поля правила прайсовой политики */
		async updateFieldRuleItem({ pricePolicyId, pricePolicyRuleId ,fieldKey, fieldData }){
			console.log({ pricePolicyId, pricePolicyRuleId, fieldKey, fieldData });
			const updateData = ruleUpdateConfig.find(item=>item.key==fieldKey);
			const { param, keyField } = updateData;

			try {
				const res = await api.postData({
					model: `price-policy/${pricePolicyId}/rule/${pricePolicyRuleId}/${param}`,
					data: {
						[keyField]: fieldData,
					}
				});

				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({error});
			}

			return;
		},

		updateTableRow(data){
			const rowData = this.buildRuleData([data])[0];
			const rowIndex = this.tableData.findIndex(item=>item.id==rowData.id);
			if(rowIndex<0) return;

			const curRowData = this.tableData[rowIndex];
			this.tableData[rowIndex] = {
				...curRowData,
				...rowData
			}
		},

		buildFilterData(){
			const data = {};

			for(const [key, value] of Object.entries(this.filterData)){
				if(['etherFraction', 'daysCount'].includes(key)){
					const { select, input } = value ?? {};
					if(input){
						data[key] = [ select||'', input||'' ];
					}
				}
				else {
					if(value?.length) data[key] = value;
				}
			}

			return data;
		},

		buildRuleData(data){
			const list = [];

			if(data?.length) for( const item of data ){
				const { id, isEnabled, priority, createdAt, updatedAt,
					inventoryCounts, prices, addserv, comment, filters } = item ?? {};

				const { sides, structures, surfaces } = inventoryCounts ?? {};

				let rules = {};
				if(filters && !Array.isArray(filters) && Object.keys(filters).length){
					rules = filters;
				}

				list.push({
					id,
					status: isEnabled,
					pos: priority,
					createdAt: new Date(createdAt),
					updatedAt: new Date(updatedAt),
					avgPriceMonth: prices?.price || 0 ,
					countStructure: {
						structs: structures || 0,
						sides: sides || 0,
						surfaces: surfaces || 0,
					},

					// поля скидок
					targetDiscount: prices?.targetDiscount || 0,
					maxDiscount: prices?.maxDiscount || 0,
					curMonthDiscount: prices?.discountForCurrentMonth || 0,
					coefficient: prices?.coefficient || 1,
					isMultipCoefficient: !!prices?.coefficient_mult,

					// поля доп услуг
					design: addserv?.design || 0,
					print: addserv?.print || 0,
					installation: addserv?.installation || 0,
					photoReport: addserv?.photoReport || 0,

					comment: comment || '',
					rules,
					/*
					{
						"department": 19,
						"customer": 2508,
						"customerLegalAddService": 2747,
						"customerCategory": 4,
						"cities": [ 50, 34, 61, 121, 62, 10, 49, 44, 91, 75, 100 ],
						"structureType": 2,
						"direction": 2,
						"size": 14,
						"mechanism": 3,
						"operator": 7,
						"net": "local",
						"fractionEther": "123"
					}
					*/
				})
			}

			return list;
		},

		async getRuleList({ pricePolicyId, filters }){
			if(!pricePolicyId) return;
			this.isViewLoader = true;

			try {
				if(!filters) filters = this.buildFilterData();

				const res = await api.postData({
					model: `price-policy/${pricePolicyId}/rule/list`,
					data: {
						filters,
					},
				});
				console.log(res);
				if(res?.data) this.tableData = this.buildRuleData(res.data);

				this.isViewLoader = false;
				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({error});
			}

			this.isViewLoader = false;
			return;
		},

		async createRuleItem(pricePolicyId){
			if(!pricePolicyId) return;

			this.isViewLoader = true;
			try {
				const res = await api.postData({
					model: `price-policy/${pricePolicyId}/rule/create`,
				});

				if(res?.rule){
					const ruleData = this.buildRuleData([res.rule])[0];
					this.tableData.unshift(ruleData);
				}

				console.log(res);
				this.isViewLoader = false;
				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({error});
			}

			this.isViewLoader = false;
			return;
		},

		async findSideRimGids(query){
			if(!query) return;

			try {
				const res = await api.getData({
					model: `filter/side-rim-gids`,
					params: {
						query,
						limit: 30,
					}
				});
				console.log(res);

				return res
			} catch (error) {
				console.error(error);
			}

			return;
		},

		async massRulesRemoved({ pricePolicyId, ids }){
			if(!(pricePolicyId && ids?.length)) return;

			this.isViewLoader = true;
			try {
				const res = await api.postData({
					model: `price-policy/${pricePolicyId}/rule/bulk/delete`,
					data: { ids }
				});
				console.log(res);

				this.isViewLoader = false;
				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({error});
			}

			this.isViewLoader = false;
			return;
		},

		async massRulesChangeStatus({ pricePolicyId, ids, isEnabled }){
			if(!(pricePolicyId && ids?.length)) return;

			this.isViewLoader = true;
			try {
				const res = await api.postData({
					model: `price-policy/${pricePolicyId}/rule/bulk/change-enable`,
					data: { isEnabled, ids }
				});
				console.log(res);

				this.isViewLoader = false;
				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({error});
			}

			this.isViewLoader = false;
			return;
		},

		async removeRuleItem({ pricePolicyId, ruleId }){
			if(!(pricePolicyId && ruleId)) return;

			this.isViewLoader = true;
			try {
				const res = await api.postData({
					model: `price-policy/${pricePolicyId}/rule/${ruleId}/delete`,
				});
				console.log(res);

				this.isViewLoader = false;
				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({error});
			}

			this.isViewLoader = false;
			return;
		}
	},
});