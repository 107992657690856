import { mapState, mapActions, mapWritableState } from 'pinia';
import { useFiltersStore } from '@/app/stores/filters-store';
import { useDetailTargetProgStore } from '@/app/stores/detail-target-prog-store';
import { useDesignFilterStore } from '@/app/stores/design-filter-store';
import { useMapDesignStore } from '@/app/stores/map-design-store';

import { ElMessageBox, ElMessage } from 'element-plus';

import helper from '@/app/utils/helper';

import DstrInfoFilters from "./dstr-info-filters/DstrInfoFilters.vue";
import DstrMonths from "./dstr-months/DstrMonths.vue";
import DstrSideList from "./dstr-side-list/DstrSideList.vue";
import DstrTotalPrice from "./dstr-total-price/DstrTotalPrice.vue";

import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';
import BsSelectV2 from "@/app/components/controls/selects/bs-select-v2/BsSelectV2.vue";

export default {
	name: 'design-target-info',
	components: {
		BsSelectV2, SvgIcon,
		DstrInfoFilters, DstrMonths, DstrSideList, DstrTotalPrice,
	},
	data() {
		return {
			filters: '',
			targetProgramId: '',
			targetOptions: [],
		}
	},
	computed: {
		...mapWritableState( useDetailTargetProgStore, ['targetTabData'] ),
		...mapWritableState( useDesignFilterStore, ['targetProgram', 'isFilterDataLoad']),

		sides(){
			const { sides } = this.targetTabData ?? {};
			if(!sides) return [];
			if(!this.filters) return sides;
			const filterSides = [];
			const hasFilter = ({keyProp, item, list})=>{
				const labels = list.map((item)=>{
					return item.label.trim().toLowerCase();
				});
				return labels.includes(item?.[keyProp].trim().toLowerCase());
			}

			for(const item of sides){
				let is = true;

				for(const [key, list] of Object.entries(this.filters)){
					if(key=='city'){
						is = is && hasFilter({keyProp: 'city', item, list});
						if(!is) break;
					}
					if(key=='direction'){
						is = is && hasFilter({keyProp: 'sideDirection', item, list});
						if(!is) break;
					}
					if(key=='structureType'){
						is = is && hasFilter({keyProp: 'structureType', item, list});
						if(!is) break;
					}
					if(key=='mechanism'){
						is = is && hasFilter({keyProp: 'sideType', item, list});
						if(!is) break;
					}
				}

				if(is) filterSides.push(item);
			}

			return filterSides;
		},
		months(){
			if(this.targetTabData?.months) return this.targetTabData.months;
			return [];
		},
		priceData(){
			const { pureCost, pureCostWithVat } = this.targetTabData ?? {};

			return {
				pureCost: pureCost ?? 0,
				pureCostWithVat: pureCostWithVat ?? 0,
			}
		},
	},
	methods: {
		...mapActions(useDetailTargetProgStore, ['getTargetTabData', 'removeSideInTp', 'searchTargetProgram']),
		...mapActions(useFiltersStore, ['getOptions']),
		...mapActions(useDesignFilterStore, ['clearFilter']),
		...mapActions(useMapDesignStore, ['getMapData', 'getStructure']),

		changeFilters(filters){
			if(Object.keys(filters).length) this.filters = filters;
			else this.filters = '';
		},

		async removeSideItem(item){
			const { canDelete, orderId, sideId, rimGid } = item;
			console.log('removeSideItem', item);
			// Нельзя удалить РС из АП со своими статусом в одном из месяцев.
			if(!canDelete){
				ElMessageBox.alert(null, null, {
					type: 'warning',
					title: 'Внимание!',
					message: 'Нельзя удалить РС из АП со своим статусом в одном из месяцев.',
					customClass: 'bs-message-box',
				}).then(() => { }).catch(() => { });
				return;
			}

			const res = await this.removeSideInTp(orderId);
			if(res?.success){
				this.targetTabData.sides = this.sides.filter(item=>item.sideId!=sideId && item.orderId!=orderId);
				ElMessage({
					type: 'success',
					message: `Сторона ${rimGid} успешно удалена из АП`,
				});
			}
			else if(res?.message){
				ElMessageBox.alert(null, null, {
					type: 'warning',
					title: 'Внимание!',
					message: res.message,
					customClass: 'bs-message-box',
				}).then(() => { }).catch(() => { });
			}
		},

		searchOptions(query){
			// if(query?.length < 3) return;
			helper.debounce({
				keyTimer: 'search-target-program-options',
				duration: 300,
				action: async ()=>{
					const res = await this.searchTargetProgram(query);
					if(res?.length) this.targetOptions = res;
				}
			});
		},
		async changeSelectTP(){
			if(!this.targetProgramId) return;
			this.isFilterDataLoad = true;

			const res = await this.getTargetTabData(this.targetProgramId);

			this.isFilterDataLoad = false;
			if(res){
				const { refFilters } = this.$refs;

				this.filters = '';
				refFilters?.clear();
				this.clearFilter();
				this.targetProgram.value = this.targetProgramId;
				await this.getMapData();
			}
		},

		openTP(){
			const { id } = this.targetTabData;
			window.open(`/target-programs/${id}`,'_blank');
		},

		async openSideMap(data){
			const { sideId, cityId, structureId } = data ?? {};
			if(!structureId) return;

			const res = await this.getStructure({
				id: structureId,
				noDataMap: true,
				isMutableStore: false,
				filter: {}
			});
			// console.log(res);
			if(res?.data){
				const { latitude, longitude } = res?.data ?? {};
				const coords = [ latitude, longitude ];
				$(window).trigger('set-center:map', { coords });
			}

		}
	},
	created(){
		if(this.targetTabData){
			const { id, name } = this.targetTabData;
			this.targetProgramId = id,
			this.targetOptions = [{
				value: id,
				label: `[${id}] ${name}`,
			}];
		}
	}
}