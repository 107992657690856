import { ElDialog } from "element-plus";

import BsBtn from "@/app/components/controls/buttons/bs-btn/BsBtn.vue";
import BsSelectV2 from "@/app/components/controls/selects/bs-select-v2/BsSelectV2.vue";

export default {
	name: 'MassPricesManagerDialog',
	components: {
		ElDialog,
		BsBtn, BsSelectV2,
	},
	props: {
		visible:{
			type: Boolean,
			default: false,
		},
		options: {
			type: Array,
			default: [],
		}
	},
	emits: ['update:visible', 'change'],
	data() {
		return {
			manager: '',
		}
	},
	computed: {
		isVisible: {
			get(){
				return this.visible;
			},
			set(is){
				this.$emit('update:visible', is);
			},
		},

		disabledChangeBtn(){
			return !this.manager;
		}
	},
	methods: {
		clickChange(){
			this.$emit('change', {
				manager: this.manager,
			});

			this.isVisible = false;
		},
	},
	watch: {
		isVisible(is){
			if(is){ } else {
				this.manager = '';
			}
		}
	},
}