import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "nt-cell-user"
}
const _hoisted_2 = {
  key: 1,
  class: "nt-cell-user"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_NotifUserInfo = _resolveComponent("NotifUserInfo")
  const _component_NotifSystemInfo = _resolveComponent("NotifSystemInfo")

  return (_ctx.senderType)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.senderType=='user')
          ? (_openBlock(), _createBlock(_component_NotifUserInfo, {
              key: 0,
              user: _ctx.userData
            }, null, 8 /* PROPS */, ["user"]))
          : _createCommentVNode("v-if", true),
        (_ctx.senderType=='system')
          ? (_openBlock(), _createBlock(_component_NotifSystemInfo, { key: 1 }))
          : _createCommentVNode("v-if", true)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, "–"))
}