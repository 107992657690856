import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "pr-rules-edit-item" }
const _hoisted_2 = { class: "pr-rules-edit-item__label" }
const _hoisted_3 = { class: "pr-rules-edit-item__group-control" }
const _hoisted_4 = {
  key: 0,
  class: "pr-rules-edit-item__control"
}
const _hoisted_5 = {
  key: 1,
  class: "pr-rules-edit-item__control"
}
const _hoisted_6 = {
  key: 2,
  class: "pr-rules-edit-item__control"
}
const _hoisted_7 = {
  key: 3,
  class: "pr-rules-edit-item__control"
}
const _hoisted_8 = {
  key: 4,
  class: "pr-rules-edit-item__control control control__builder"
}
const _hoisted_9 = { class: "control__group group-row gap-8 flex-no-wrap" }
const _hoisted_10 = {
  key: 5,
  class: "pr-rules-edit-item__control"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsSelectV2 = _resolveComponent("BsSelectV2")
  const _component_BsInput = _resolveComponent("BsInput")
  const _component_SvgIcon = _resolveComponent("SvgIcon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.data?.label), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.data?.type=='single-select')
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_BsSelectV2, {
              placeholder: _ctx.data?.placeholder,
              modelValue: _ctx.data.value,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.data.value) = $event)),
              options: _ctx.selectOptions,
              clearable: true,
              filterable: true,
              "popper-class": "pr-rules-edit__select-popper",
              "item-height": 28
            }, null, 8 /* PROPS */, ["placeholder", "modelValue", "options"])
          ]))
        : (_ctx.data?.type=='multi-select')
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_component_BsSelectV2, {
                placeholder: _ctx.data?.placeholder,
                modelValue: _ctx.data.value,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.data.value) = $event)),
                options: _ctx.selectOptions,
                clearable: true,
                filterable: true,
                "popper-class": "pr-rules-edit__select-popper",
                "item-height": 28,
                "collapse-tags": "",
                "collapse-tags-tooltip": "",
                "max-collapse-tags": 2,
                multiple: ""
              }, null, 8 /* PROPS */, ["placeholder", "modelValue", "options"])
            ]))
          : (_ctx.data?.type=='remote-multi-select')
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_BsSelectV2, {
                  placeholder: _ctx.data?.placeholder,
                  modelValue: _ctx.data.value,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.data.value) = $event)),
                  options: _ctx.gidOptions,
                  clearable: true,
                  filterable: true,
                  "popper-class": "pr-rules-edit__select-popper",
                  "item-height": 28,
                  "collapse-tags": "",
                  "collapse-tags-tooltip": "",
                  "max-collapse-tags": 2,
                  multiple: "",
                  remote: true,
                  "remote-method": _ctx.searchGids
                }, null, 8 /* PROPS */, ["placeholder", "modelValue", "options", "remote-method"])
              ]))
            : (_ctx.data?.type=='input-number')
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createVNode(_component_BsInput, {
                    placeholder: _ctx.data?.placeholder,
                    modelValue: _ctx.data.value,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.data.value) = $event)),
                    clearable: true,
                    type: "number",
                    size: "small",
                    min: "0",
                    step: "0.01"
                  }, null, 8 /* PROPS */, ["placeholder", "modelValue"])
                ]))
              : (_ctx.data?.type=='compare')
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_BsSelectV2, {
                        placeholder: "=",
                        modelValue: _ctx.data.value.select,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.data.value.select) = $event)),
                        "item-height": 28,
                        clearable: true,
                        "popper-class": "pr-rules-edit__select-popper",
                        options: _ctx.getOptions('compare')
                      }, null, 8 /* PROPS */, ["modelValue", "options"]),
                      _createVNode(_component_BsInput, {
                        placeholder: "Введите данные",
                        modelValue: _ctx.data.value.input,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.data.value.input) = $event)),
                        type: "number",
                        min: "0",
                        step: "0.01",
                        size: "small",
                        clearable: true
                      }, null, 8 /* PROPS */, ["modelValue"])
                    ])
                  ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_10)),
      _createElementVNode("div", {
        class: "pr-rules-edit-item__btn-remove",
        title: "Удалить",
        onClick: _cache[6] || (_cache[6] = (...args) => (_ctx.removeRule && _ctx.removeRule(...args)))
      }, [
        _createVNode(_component_SvgIcon, { name: "16-close" })
      ])
    ])
  ]))
}