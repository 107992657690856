import helper from "@/app/utils/helper";

import BsInput from "@/app/components/controls/bs-input/BsInput.vue";

export default {
	name: 'dstr-total-price',
	components: {
		BsInput,
	},
	props: {
		data: {
			type: Object,
			default: {
				pureCost: 0,
				pureCostWithVat: 0,
			},
		}
	},
	data() {
		return {
			discount: 0,
		}
	},
	computed: {
		discountTotalPrice(){
			const { pureCost } = this.data;
			const discountPrice = Number(pureCost) * (Number(this.discount) / 100);

			return Number(pureCost) - discountPrice;
		},
		discountTotalPriceWithVat(){
			const { pureCostWithVat } = this.data;
			const discountPrice = Number(pureCostWithVat) * (Number(this.discount) / 100);

			return Number(pureCostWithVat) - Number(discountPrice);
		}
	},
	methods: {
		getMoney: helper.getMoney,

		changeDiscount(val){
			let value = Number(val);

			if(value < 0) value = 0;
			else if(value > 100) value = 100;

			this.discount = helper.getNum(value);
		}
	},
}