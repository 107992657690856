import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tp-temp-settings" }
const _hoisted_2 = { class: "tp-temp-settings__select" }
const _hoisted_3 = { class: "tp-temp-settings__btn-save" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsSingleSelect = _resolveComponent("BsSingleSelect")
  const _component_BtnAction = _resolveComponent("BtnAction")
  const _component_TpTempSaveDialog = _resolveComponent("TpTempSaveDialog")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BsSingleSelect, {
        placeholder: "Выберите из списка",
        "no-match-text": "Шаблона не найдено",
        modelValue: _ctx.template,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.template) = $event)),
        options: _ctx.options,
        clearable: true,
        filterable: true,
        showRemoved: true,
        teleported: false,
        onChange: _ctx.changeTemplate,
        onRemove: _ctx.removeTemplate
      }, null, 8 /* PROPS */, ["modelValue", "options", "onChange", "onRemove"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_BtnAction, {
        name: "24-save-line",
        onClick: _cache[1] || (_cache[1] = $event => (_ctx.isVisibleSaveDialog=true))
      })
    ]),
    _createVNode(_component_TpTempSaveDialog, {
      visible: _ctx.isVisibleSaveDialog,
      "onUpdate:visible": _cache[2] || (_cache[2] = $event => ((_ctx.isVisibleSaveDialog) = $event)),
      onSave: _ctx.saveTemplate
    }, null, 8 /* PROPS */, ["visible", "onSave"])
  ]))
}