import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dstr-info-filters" }
const _hoisted_2 = { class: "control__group group-row gap-8 align-center" }
const _hoisted_3 = { class: "control full-free-width" }
const _hoisted_4 = { class: "control" }
const _hoisted_5 = { class: "control__group group-row gap-8" }
const _hoisted_6 = { class: "control full-free-width" }
const _hoisted_7 = { class: "control full-free-width" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsSelectV2 = _resolveComponent("BsSelectV2")
  const _component_BsCheckboxGroup = _resolveComponent("BsCheckboxGroup")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_BsSelectV2, {
          placeholder: "Город",
          modelValue: _ctx.filterData.city,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.filterData.city) = $event)),
          options: _ctx.getOptions('city'),
          clearable: true,
          filterable: true,
          size: "small",
          multiple: "",
          showCheckBox: true,
          "collapse-tags": "",
          "collapse-tags-tooltip": "",
          "max-collapse-tags": 3,
          onChange: _ctx.changeFilter
        }, null, 8 /* PROPS */, ["modelValue", "options", "onChange"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_BsCheckboxGroup, {
          class: "dstr-info-filters__checkbox-group",
          modelValue: _ctx.filterData.direction,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.filterData.direction) = $event)),
          list: _ctx.getOptions('direction'),
          onChange: _ctx.changeFilter
        }, null, 8 /* PROPS */, ["modelValue", "list", "onChange"])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_BsSelectV2, {
          placeholder: "Тип РК",
          modelValue: _ctx.filterData.structureType,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.filterData.structureType) = $event)),
          options: _ctx.getOptions('structureType'),
          clearable: true,
          filterable: true,
          size: "small",
          multiple: "",
          showCheckBox: true,
          "collapse-tags": "",
          "collapse-tags-tooltip": "",
          "max-collapse-tags": 3,
          onChange: _ctx.changeFilter
        }, null, 8 /* PROPS */, ["modelValue", "options", "onChange"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_BsSelectV2, {
          placeholder: "Механизм РС",
          modelValue: _ctx.filterData.mechanism,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.filterData.mechanism) = $event)),
          options: _ctx.getOptions('mechanism'),
          clearable: true,
          filterable: true,
          size: "small",
          multiple: "",
          showCheckBox: true,
          "collapse-tags": "",
          "collapse-tags-tooltip": "",
          "max-collapse-tags": 3,
          onChange: _ctx.changeFilter
        }, null, 8 /* PROPS */, ["modelValue", "options", "onChange"])
      ])
    ])
  ]))
}