import { ElTable, ElTableColumn } from "element-plus";
import 'element-plus/es/components/table/style/css';


import { colConfigList } from "./table-config";

import HeadBtnSort from "@/app/components/table/header/head-btn-sort/HeadBtnSort.vue";
import CellOperator from '../type-cell/cell-operator/CellOperator.vue'

export default {
	name: 'all-operator-table',
	components: {
		ElTable, ElTableColumn,
		HeadBtnSort, CellOperator,
	},
	props: {
		tableData: {
			type: Array,
			default: [],
		}
	},
	emits: ['update:tableData', 'dbClickRow', 'changeTable'],
	data() {
		return {
			colConfigList,
		}
	},
	methods: {
		changeTable(data){
			console.log('changeTable', data);
			this.$emit('changeTable', data);
		},
		dbClickRow(data){
			// console.log(data);
			this.$emit('dbClickRow', data);
		},
		sortTable(order, key){
			const tableData = [...this.tableData];
			// this.sortData = { order, key };

			for(let item of this.colConfigList){
				if(item.key == key) continue;
				item.order = '';
			}

			if( ['countConstruct', 'countSide', 'countSurface', ].includes(key) ){
				if(order=='asc') tableData.sort((a,b)=>a[key] - b[key]);
				else if(order=='desc') tableData.sort((a,b)=>b[key] - a[key]);

			}
			else if(key=='operator') {
				const date = (row)=>(row[key]['name'].toLowerCase());

				if(order=='asc') tableData.sort((a,b)=>date(a).localeCompare(date(b)));
				else if(order=='desc') tableData.sort((a,b)=>date(b).localeCompare(date(a)));
			}

			if(!order) tableData.sort((a,b)=>a['operator']['id'] - b['operator']['id']);

			this.$emit('update:tableData', tableData);
		},
	},
}