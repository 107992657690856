import dayjs from 'dayjs';

import mixinNotifMessage from '../mixins/mixin-notif-message';

import BsBtn from "@/app/components/controls/buttons/bs-btn/BsBtn.vue";
import NotifUserInfo from '@/app/components/notification/other/notif-user-info/NotifUserInfo.vue';

export default {
	name: 'NotifMessageManager',
	mixins: [mixinNotifMessage],
	components: {
		BsBtn, NotifUserInfo,
	},
	props: {
		data: {
			type: Object,
			default: ()=>({
				id: '',
				targetProgramId: '',
				createAt: '',
				senderType: '',
				notifType: '',
				isRead: true,
				message: '',
				user: { },
			}),
		}
	},
	computed: {

	},
	methods: {

	},
}