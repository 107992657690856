import { mapState, mapActions, mapWritableState } from 'pinia';
import { useRootStore } from '@/app/stores/root-store';
import { useMapDesignStore } from '@/app/stores/map-design-store';
import { useDialogStore } from '@/app/stores/dialog-store';
import { useDesignFilterStore } from '@/app/stores/design-filter-store';
import { useDetailTargetProgStore } from '@/app/stores/detail-target-prog-store';

import { ElMessageBox, ElMessage, ElCollapseTransition } from 'element-plus';

import cellMixin from '@/app/mixins/table/cell-mixin.js'
import helper from '@/app/utils/helper';
import api from '@/app/utils/service-api';

import SideSelect from '@/app/components/map/map-design-info/side-select/SideSelect.vue';
import BtnAction from '@/app/components/controls/buttons/btn-action/BtnAction.vue';
import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';

export default {
	name: 'grid-designs-info',
	mixins: [cellMixin],
	components: {
		ElCollapseTransition,
		SideSelect, BtnAction, SvgIcon,
	},
	props: {
		cityMap: {
			type: Object,
			default: {},
		},
		compact: {
			type: Boolean,
			default: false,
		}
	},
	data() {
		return {
			isOpen: false,
		}
	},
	computed: {
		...mapState( useRootStore, ['isShowSecondAside'] ),
		...mapState( useDesignFilterStore, ['targetProgram', 'city'] ),
		...mapWritableState( useDesignFilterStore, ['isFilterDataLoad'] ),

		sideData(){
			return this.rowData?.curSideData ?? {};
		},
		linkAddressYMap(){
			const { coords } = this.rowData;
			let url = new URL('https://yandex.ru/maps/');
			const params = {
				'll': coords.join(','),
				'whatshere[point]': coords.join(','),
				'whatshere[zoom]': '16',
				'z': '17',
				mode: 'whatshere',
			}
			url.search = new URLSearchParams(params).toString();

			return url.toString();
		},
		labelCity(){
			const { cityId } = this.rowData;
			// console.log(cityId, this.cityMap);
			return this.cityMap?.[cityId]?.label ?? '';
		},
	},
	methods: {
		...mapActions( useMapDesignStore, ['getTypeDesignIcon', 'getStructure'] ),
		...mapActions( useRootStore, ['setShowSecondAside']),
		...mapActions( useDialogStore, ['openTargetProgDialog', 'addTargetProgram'] ),
		...mapActions( useDetailTargetProgStore, ['getTargetTabData'] ),

		async clickAddTarget(){
			const targetProgId = this.targetProgram.value;
			let data = {
				side_id: this.sideData.id,
				periods: [],
				status: '',
			};

			if(this.sideData.selectedPeriods) for(let [key, monthPeriods] of Object.entries(this.sideData.selectedPeriods)){
				data.periods.push(...monthPeriods);
			}

			// Если периодов в градуснике не выбрано, берем период из фильтра
			// if(!data.periods?.length){
			// 	const { from, to } = this.rowData.period;
			// 	data.periods = [[from, to]];
			// }

			if(this.sideData?.digitalData){
				const { digitalDuration, outFrequency } = this.sideData;
				data = {
					...data,
					duration: digitalDuration.value,
					frequency: outFrequency.value,
				}
			}
			console.log('clickAddTarget', data);

			if(targetProgId){
				const res = await this.addTargetProgram({
					orders: [data],
					target_program_id: targetProgId,
				});
				console.log(res);
				if(!res) return;

				this.getTargetTabData(targetProgId);
				ElMessage({
					type: 'success',
					message: 'РС успешно добавлена',
				});
			} else {
				this.openTargetProgDialog(data);
			}
		},

		async clickCopyShareLink(){
			const { origin } = window.location;
			const { id, gid, cityId } = this.rowData;
			let city = [];

			if(cityId) city = [cityId];

			const data = {
				structureId: id,
				city,
			}
			const params = api.buildParams(data).toString();
			const link = `${origin}/structures?${params}`;

			try {
				await helper.copyTextBuffer(link);

				ElMessage({
					type: 'success',
					message: 'Ссылка успешно скопирована',
				});
			} catch (error) {
				ElMessage({
					type: 'error',
					message: 'Ссылку не удалось скопировать',
				});
			}

			console.log('clickCopyShareLink');
		},
		openTechReq(){
			const { techRequirements } = this.sideData ?? {};
			if( techRequirements ) window.open(techRequirements, '_blank');
		},
		clickAddWishList(){
			console.log('clickAddWishList');
		},
		async openSideConstruct(){
			this.isFilterDataLoad = true;
			await this.getStructure({ id: this.rowData.id, noDataMap: true });
			if(!this.isShowSecondAside) this.setShowSecondAside(true);
			this.isFilterDataLoad = false;
		},
		toggleWrap(){
			this.isOpen = !this.isOpen;
		},
	},
	created() {
		// console.log(this.data);
	},
}