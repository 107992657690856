import { mapActions, mapState, mapWritableState } from 'pinia';
import { useDetailTargetProgStore } from '@/app/stores/detail-target-prog-store';

import { ElDialog } from "element-plus";


import BsBtn from "@/app/components/controls/buttons/bs-btn/BsBtn.vue";
import BsInput from "@/app/components/controls/bs-input/BsInput.vue";
import DatepickerMonthPeriodV2 from "@/app/components/datepickers/datepicker-month-period-2/DatepickerMonthPeriodV2.vue";

export default {
	name: 'tg-mass-period',
	components: {
		ElDialog,
		BsBtn, BsInput, DatepickerMonthPeriodV2,
	},
	props: {
		visible:{
			type: Boolean,
			default: false,
		},
	},
	emits: ['update:visible', 'change'],
	data() {
		return {
			periods: [],
		}
	},
	computed: {
		...mapState( useDetailTargetProgStore, ['detailMonthSelectData'] ),

		isVisible: {
			get(){
				return this.visible;
			},
			set(is){
				this.$emit('update:visible', is);
			},
		},
	},
	methods: {
		changePeriods(periods){
			// console.log(periods);
			this.periods = periods;
		},
		clickChange(){
			this.$emit('change', this.periods);
			this.isVisible = false;
		},
	},
	watch: {
		isVisible(is){
			if(!is){
				this.periods = '';
			}
		}
	},
}