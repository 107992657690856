import { mapActions, mapState, mapWritableState } from 'pinia';
import { useDesignFilterStore } from '@/app/stores/design-filter-store';
import { useMapDesignStore } from '@/app/stores/map-design-store';
import { useGridDesignStore } from '@/app/stores/grid-design-store';
import { useFiltersStore } from '@/app/stores/filters-store';
import { useDetailTargetProgStore } from '@/app/stores/detail-target-prog-store';
import { useRootStore } from '@/app/stores/root-store';

import { ElMessage, ElMessageBox, ElCollapseTransition } from 'element-plus';



import dayjs from 'dayjs';
import helper from '@/app/utils/helper';

import LoaderSpinner from '@/app/components/loaders/loader-spinner/LoaderSpinner.vue';
import FilterInfo from '@/app/components/design-filter/filter-info/FilterInfo.vue';
import DirectSideInfo from '@/app/components/design-filter/direct-side-info/DirectSideInfo.vue';
import TempSettings from '@/app/components/design-filter/temp-settings/TempSettings.vue';

import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';
import BsBtn from '@/app/components/controls/buttons/bs-btn/BsBtn.vue';
import BsSingleSelect from '@/app/components/controls/selects/bs-single-select/BsSingleSelect.vue';
import BsMultiSelect from '@/app/components/controls/selects/bs-multi-select/BsMultiSelect.vue';
import BsSelectV2 from '@/app/components/controls/selects/bs-select-v2/BsSelectV2.vue';
import BsRadioGroup from '@/app/components/controls/bs-radio-group/BsRadioGroup.vue';
import BsDatePicker from '@/app/components/controls/bs-date-picker/BsDatePicker.vue';
import BsCheckbox from '@/app/components/controls/bs-checkbox/BsCheckbox.vue';
import BsCheckboxGroup from '@/app/components/controls/bs-checkbox-group/BsCheckboxGroup.vue';
import BsInput from '@/app/components/controls/bs-input/BsInput.vue';
import LabelInfo from '@/app/components/controls/label-info/LabelInfo.vue';

import EmployCalendar from '@/app/components/map/map-design-info/employ-calendar/EmployCalendar.vue';
import DesignSearchGid from '@/app/components/design-search-gid/DesignSearchGid.vue';


export default {
	name: 'design-filter',
	components: {
		LoaderSpinner, ElCollapseTransition,
		FilterInfo, DirectSideInfo, TempSettings,
		BsBtn, BsSingleSelect, BsMultiSelect, BsSelectV2,
		BsRadioGroup, BsDatePicker, BsCheckbox, BsCheckboxGroup,
		BsInput, LabelInfo, EmployCalendar,
		DesignSearchGid, SvgIcon,
	},
	data() {
		return {
			isOpenDigitalGroup: false,
			isOpenSearchGid: false,
		}
	},
	computed: {
		...mapState(useRootStore, ['devMode', 'activeDesignView']),
		...mapState(useDesignFilterStore, ['settingsShowMap', 'totalFilters'] ),
		...mapState(useMapDesignStore, ['designsData']),
		...mapState(useFiltersStore, ['filtersMap'] ),
		...mapWritableState(useMapDesignStore, ['isSetBounds']),
		...mapWritableState(useDesignFilterStore, [
			'isFilterDataLoad', 'countFreeDays',
			'template', 'settingList', 'targetProgram', 'city', 'searchGidData',
			'placePeriodType', 'placePeriodDate', 'sideType', 'structureCategory', 'structureType',
			'size', 'mechanism', 'lighted', 'freedom', 'bookingStatus', 'serviceStatus',
			'countFreeSide', 'operator', 'owner', 'priceBefore', 'net', 'direction',
			'compareOptions', 'otsData', 'grpData', 'wifiTrap',
			'tag', 'frequency', 'blockDuration', 'slotDuration',
			'digitalVideoLength', 'isOpenSaveFiltertempForm',
		]),
		...mapState(useDetailTargetProgStore, ['targetTabData']),

		periodDatePicker(){
			if(this.placePeriodType?.value=='month') return 'month';
			return 'date';
		},
		placeholderDatePicker(){
			if(this.placePeriodType?.value=='month') return 'Месяц';
			return 'Дата';
		},
		isShowDigitalBlock(){
			let is = true;
			let findDigitalItem = '';

			// console.log(this.mechanism);
			for(const item of this.getOptions('mechanism')){
				const { value, label } = item;

				if(label.toLocaleLowerCase()=='digital' && this.mechanism.value.includes(value)){
					findDigitalItem = item;
				}
			}

			if(!findDigitalItem && this.mechanism.value?.length) is = false;

			return is;
		},
		filterInfoData(){
			const { structuresCount, sidesCount, surfacesCount } = (this.totalFilters || {});

			return [
				{ title: 'РК', count: structuresCount || 0 },
				{ title: 'РС', count: sidesCount || 0 },
				{ title: 'РП', count: surfacesCount || 0 },
			];
		},
		directInfoData(){
			const { sidesACount, sidesBCount, sidesCCount } = (this.totalFilters || {});

			return {
				a: sidesACount || 0,
				b: sidesBCount || 0,
				c: sidesCCount || 0,
			}
		}
	},
	methods: {
		...mapActions(useMapDesignStore, ['getMapData']),
		...mapActions(useGridDesignStore, ['getDesignGridData']),
		...mapActions(useDesignFilterStore, [
			'getSubmitFilterData', 'updateFilterTemplate', 'removeFilterTemplate', 'setFilterField',
			'clearFilter',
		]),
		...mapActions(useFiltersStore, ['getFilterOptions', 'getOptions']),
		...mapActions(useDetailTargetProgStore, ['getTargetTabData']),

		removeTemplate(data){
			console.log('removeTemplate', data);
			const { opt, options } = data;

			ElMessageBox.confirm(
				`Вы действительно хотите удалить шаблон фильтра "${opt.label}"?`,
				'Внимание',
				{
					customClass: 'bs-message-box',
					confirmButtonText: 'Продолжить',
					cancelButtonText: 'Отмена',
					type: 'warning',
				}
			).then(async () => {
				const res = await this.removeFilterTemplate(opt.value);
				if(!res) return;

				this.template.options = options.filter(item=>item.value != opt.value);
				if(this.template.value==opt.value) this.template.value = '';

				ElMessage({
					type: 'success',
					message: 'Шаблон успешно удалён',
				});

			}).catch(() => {
				ElMessage({
					type: 'info',
					message: 'Удаление отменено',
				})
			})
		},
		updateTemplate(){
			console.log('updateTemplate');

			const { value, options } = this.template;
			const opt = this.template.options.find(item=>item.value==value);

			ElMessageBox.confirm(
				`Вы действительно хотите обновить шаблон фильтра "${opt.label}"?`,
				'Внимание',
				{
					customClass: 'bs-message-box',
					confirmButtonText: 'Продолжить',
					cancelButtonText: 'Отмена',
					type: 'warning',
				}
			).then(async () => {
				const res = await this.updateFilterTemplate(opt.value);
				if(!res) return;

				const { filters } = res;
				opt.data = filters;

				ElMessage({
					type: 'success',
					message: 'Шаблон успешно обновлён',
				});

			}).catch(() => {
				ElMessage({
					type: 'info',
					message: 'Обновление шаблона отменено',
				})
			})
		},
		saveTemplate(){
			console.log('saveTemplate');
			this.isOpenSaveFiltertempForm = true;
		},
		clickSaveTemplate(){
			if(this.template?.value) this.updateTemplate();
			else this.saveTemplate();
		},

		disabledDateFrom(data){
			const periodType = this.placePeriodType.value;
			const today = dayjs(new Date()).format('YYYY-MM-DD');
			let nextDate = dayjs(today).toDate();

			if(periodType=='day'){
				nextDate = dayjs(nextDate).startOf('month').toDate();
				if(nextDate < new Date()) nextDate = dayjs(today).toDate();
			}

			return nextDate > data;
		},
		disabledDateTo(data){
			const { from, to } = this.placePeriodDate;
			const periodType = this.placePeriodType.value;
			const today = dayjs(new Date()).format('YYYY-MM-DD');
			let nextDate = dayjs(today).toDate();

			if(from){
				const fromDate = dayjs(from).toDate();

				if( fromDate > nextDate ){
					nextDate = dayjs(from).toDate();
				}
			}

			return nextDate > dayjs(data).toDate();
		},
		changeDatePickerFrom(){
			const { from, to } = this.placePeriodDate;
			const dateFrom = dayjs(from).toDate();
			const dateTo = dayjs(to).toDate();

			if(dateFrom > dateTo){
				const monthDays = dayjs(from).daysInMonth();
				// this.placePeriodDate.to = dayjs(dateFrom).add(1, 'months').format('YYYY-MM-DD');
				this.placePeriodDate.to = dayjs(from).date(monthDays).format('YYYY-MM-DD');
			}
		},
		changeDatePickerTo(){
			const { to } = this.placePeriodDate;
			const periodType = this.placePeriodType.value;

			if(periodType=='month'){
				const monthDays = dayjs(to).daysInMonth();
				this.placePeriodDate.to = dayjs(to).date(monthDays).format('YYYY-MM-DD');
			}
		},
		async changeFilterTemplate(id){
			// console.log('changeFilterTemplate', id);
			const findOpt = this.template.options.find(item=>item.value==id);
			const filters = findOpt?.data;

			if(!filters || !Object.keys(filters)?.length) return;

			console.log('changeFilterTemplate', filters);
			this.setFilterField(filters);
			await helper.pause(100);
			this.submit();
		},

		async clear(){
			this.clearFilter();
			await helper.pause(100);
			// this.submit();
			const data = this.getSubmitFilterData();
			const strFilters = JSON.stringify(data);

			this.$router.push({
				name: 'structures',
				query: {
					filters: strFilters,
				},
			});

			if(this.activeDesignView == 'map') this.getMapData();
			else if(this.activeDesignView == 'grid') this.getDesignGridData();
		},

		async submit(){
			const data = this.getSubmitFilterData();
			const { query } = this.$route;
			const strFilters = JSON.stringify(data);

			console.log('submit', data);

			if(this.activeDesignView == 'map'){
				if(query?.structureId) this.$router.push({ name: 'structures' });
				else{
					const { filters } = this.$route?.query ?? {};
					if(filters){
						const queryFiltersData = JSON.parse(filters);
						const queryCity = queryFiltersData?.city;
						const filterCity = data?.city;

						if( queryCity?.length && filterCity?.length
							&& JSON.stringify(queryCity) == JSON.stringify(filterCity)
						) this.isSetBounds = false;
					}

					this.$router.push({
						name: 'structures',
						query: {
							...this.$route?.query,
							filters: strFilters
						},
					});
				}

				await this.getMapData(data);
				this.isSetBounds = true;
			}
			if(this.activeDesignView == 'grid'){
				this.$router.push({
					name: 'structures',
					query: {
						...this.$route?.query,
						filters: strFilters
					},
				});
				this.getDesignGridData(data);
			}
		},
		remoteLoadOptions(query, key){
			if(query.length < 2) return;

			helper.debounce({
				keyTimer: 'remoteLoadOptions',
				duration: 500,
				action: ()=>{
					this.getFilterOptions({ query, key });
				}
			});
		},
		openSearchGid(is){
			try {
				window.localStorage.setItem('open-search-gid-design', is);
			} catch (error) {
				console.error(error);
			}
		},
		async openTargetProgram(){
			// console.log('openTpInTab');
			// const tabTpId = this.targetTabData?.id;
			// const selectTpId = this.targetProgram.value;

			// if(tabTpId==selectTpId) return;

			// this.isFilterDataLoad = true;
			// await this.getTargetTabData(selectTpId);
			// this.isFilterDataLoad = false;

			const selectTpId = this.targetProgram.value;
			window.open(`/target-programs/${selectTpId}`,'_blank');
		}
	},
	watch:{
		['placePeriodType.value'](type, oldType){
			// console.log({ type, oldType });
			if(type=='month' && oldType=='day'){
				const { from, to } = this.placePeriodDate;
				const fromYearMonth = dayjs(from).format('YYYY-MM');
				const toYearMonth = dayjs(to).format('YYYY-MM');

				let fromDate = dayjs(fromYearMonth).startOf('month').toDate();
				let toDate = dayjs(toYearMonth).endOf('month').toDate();

				if(fromDate < new Date()){
					fromDate = new Date();
				}

				this.placePeriodDate = {
					from: dayjs(fromDate).format('YYYY-MM-DD'),
					to: dayjs(toDate).format('YYYY-MM-DD'),
				};
			}
		}
	},
	created() {
		this.isOpenSearchGid = window?.localStorage?.getItem('open-search-gid-design')=='true';

	},
}