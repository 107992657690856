import { ElDialog } from "element-plus";


import BsBtn from '@/app/components/controls/buttons/bs-btn/BsBtn.vue';
import SideQueueItem from '@/app/components/map/map-design-info/side-queue/side-queue-item/SideQueueItem.vue';
import SideQueuePersonalItem from "@/app/components/map/map-design-info/side-queue/side-queue-personal-item/SideQueuePersonalItem.vue";

export default {
	name: 'side-queue-popup',
	components: {
		ElDialog,
		BsBtn, SideQueueItem, SideQueuePersonalItem
	},
	props:{
		modelValue: {
			type: Boolean,
			default: false,
		},
		list: Object,
		title: String
	},
	emits: ['update:modelValue'],
	data() {
		return {
			isVisible: this.modelValue,
			freeQueue: {
				status: 'Свобода',
				position: 1
			}
		}
	},
	computed: {
		firstQueueList(){
			const fistQueueData = [...this.list.first]
			if(this.list?.freeSurfaces > 0) {
				for (let i = 0; i < this.list.freeSurfaces; i++) {
					fistQueueData.push(this.freeQueue);
				}
			} else if(this.list?.freeTime > 0) {
				this.freeQueue.time = this.list.freeTime
				fistQueueData.push(this.freeQueue)
			}

			return fistQueueData
		},
	},
	methods: {
		cancel(){
			this.isVisible = false;
		},
	},
	watch: {
		isVisible(is){
			this.$emit('update:modelValue', is);
			console.log(this.list);
		},
		modelValue(is){
			this.isVisible = is;
		}
	},
}