import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "dstr-months" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "dstr-months__item",
        key: _ctx.getHash(item)
      }, _toDisplayString(_ctx.getFormatMonth(item)), 1 /* TEXT */))
    }), 128 /* KEYED_FRAGMENT */)),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "dstr-months__add" }, "Добавить", -1 /* HOISTED */))
  ]))
}