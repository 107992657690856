import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "map-marker-2__status" }
const _hoisted_2 = {
  key: 0,
  class: "map-marker-2__view type-view-design"
}
const _hoisted_3 = {
  key: 0,
  class: "map-marker-2__view type-view-sides"
}
const _hoisted_4 = {
  key: 2,
  class: "map-marker-2__is-digital"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_MarkerSideSystem = _resolveComponent("MarkerSideSystem")
  const _component_DesignMarkerPopup = _resolveComponent("DesignMarkerPopup")
  const _component_ElPopover = _resolveComponent("ElPopover")

  return (_ctx.isShow)
    ? (_openBlock(), _createBlock(_Teleport, {
        key: 0,
        to: _ctx.markerSelector
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["map-marker-2", { 'map-marker-2--active': _ctx.isActive }]),
          ref: "refMarker",
          onMouseenter: _cache[3] || (_cache[3] = (...args) => (_ctx.mouseEnter && _ctx.mouseEnter(...args))),
          onMouseleave: _cache[4] || (_cache[4] = (...args) => (_ctx.mouseLeave && _ctx.mouseLeave(...args)))
        }, [
          (_ctx.isActive)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: _normalizeClass(["map-marker-2__icon", { 'icon-in-target-prog': _ctx.inTargetProg }])
              }, [
                (_ctx.inTargetProg)
                  ? (_openBlock(), _createBlock(_component_SvgIcon, {
                      key: 0,
                      name: "marker-active-in-tprog"
                    }))
                  : (_openBlock(), _createBlock(_component_SvgIcon, {
                      key: 1,
                      name: "marker-active-empty"
                    }))
              ], 2 /* CLASS */))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(["map-marker-2__icon", { 'icon-in-target-prog': _ctx.inTargetProg }])
              }, [
                (_ctx.inTargetProg)
                  ? (_openBlock(), _createBlock(_component_SvgIcon, {
                      key: 0,
                      name: "marker-in-tprog"
                    }))
                  : (_openBlock(), _createBlock(_component_SvgIcon, {
                      key: 1,
                      name: "marker-empty"
                    }))
              ], 2 /* CLASS */)),
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_SvgIcon, {
              name: `marker-st-${_ctx.statusIcon}`
            }, null, 8 /* PROPS */, ["name"])
          ]),
          _createVNode(_Transition, {
            name: "el-fade-in",
            mode: "out-in"
          }, {
            default: _withCtx(() => [
              (_ctx.typeView=='view-design')
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_SvgIcon, { name: _ctx.designIcon }, null, 8 /* PROPS */, ["name"])
                  ]))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_Transition, {
            name: "el-fade-in",
            mode: "out-in"
          }, {
            default: _withCtx(() => [
              (_ctx.typeView=='view-sides')
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createVNode(_component_MarkerSideSystem, { sideList: _ctx.sides }, null, 8 /* PROPS */, ["sideList"])
                  ]))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }),
          (_ctx.isDigital)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_SvgIcon, { name: "8-digital-badge-2" })
              ]))
            : _createCommentVNode("v-if", true),
          (_ctx.isOpenPopup)
            ? (_openBlock(), _createBlock(_component_ElPopover, {
                key: 3,
                visible: _ctx.isOpenPopup,
                "onUpdate:visible": _cache[2] || (_cache[2] = $event => ((_ctx.isOpenPopup) = $event)),
                persistent: false,
                teleported: true,
                offset: 10,
                width: "auto",
                placement: "top",
                "popper-class": "map-marker-2__popover"
              }, {
                reference: _withCtx(() => [
                  _createVNode(_Transition, {
                    name: "el-fade-in",
                    mode: "out-in"
                  }, {
                    default: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createElementVNode("div", { class: "map-marker-2__ref-popup" }, null, -1 /* HOISTED */)
                    ])),
                    _: 1 /* STABLE */
                  })
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: "map-marker-2__popover-container",
                    onMouseenter: _cache[0] || (_cache[0] = (...args) => (_ctx.mouseEnterPopup && _ctx.mouseEnterPopup(...args))),
                    onMouseleave: _cache[1] || (_cache[1] = (...args) => (_ctx.mouseLeavePopup && _ctx.mouseLeavePopup(...args)))
                  }, [
                    _createVNode(_component_DesignMarkerPopup, {
                      data: _ctx.popupData,
                      onClosePopup: _ctx.closePopup
                    }, null, 8 /* PROPS */, ["data", "onClosePopup"])
                  ], 32 /* NEED_HYDRATION */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["visible"]))
            : _createCommentVNode("v-if", true)
        ], 34 /* CLASS, NEED_HYDRATION */)
      ], 8 /* PROPS */, ["to"]))
    : _createCommentVNode("v-if", true)
}