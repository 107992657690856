import { defineStore } from 'pinia';
import api from '@/app/utils/service-api';
import helper from '@/app/utils/helper';

const isDevServer = ['localhost','outplan-dev.devtestprod.ru'].includes(window.location.hostname);
const devMode = window?.localStorage?.getItem('dev');

export const useRootStore = defineStore('rootStore', {
	state() {
		return {
			devMode, isDevServer,

			isShowSecondAside: true,
			// isShowSecondAside: false,
			activeDesignView: 'map', // 'map', 'grid', 'hybrid'
			// activeDesignView: 'grid',
			activeTargetView: 'grid',
			// activeTargetView: 'map',

			pageData: {
				title: '',
			},

			mainMenuData: [
				{
					url: '/structures',
					title: 'Все конструкции',
					icon: '24-map',
					show: true,
				},
				{
					url: '/target-programs',
					title: 'Адресные программы',
					icon: '24-map-marker',
					show: true,
				},
				{
					url: '/operators',
					title: 'Операторы',
					icon: '24-system',
					show: isDevServer,
				},
				{
					url: '/pricing-policies',
					title: 'Прайсовые политики',
					icon: '24-сurrencies',
					show: true,
				},
			],

			statusMap: {
				free: 'Свободно',
				reserved: 'Резерв',
				busy: 'Занято',
				temporary_approved: 'Временное подтверждение',
				approved: 'Подтверждено',
				unavailable: 'Недоступно',
			},

			statusMap2: {
				free: 'Свободно',
				reserved: 'Резерв',
				busy: 'Занято',
				temporary_approved: 'Врем. подтв.',
				approved: 'Подтверждено',
				unavailable: 'Недоступно',
			}
		}
	},

	getters: { },
	actions: {
		setShowSecondAside(is){
			this.isShowSecondAside = is;

			helper.pause(400, ()=>{
				$(window).trigger('collapse:aside');
			});
		}
	},
});