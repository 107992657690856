import { mapState, mapActions, mapWritableState } from 'pinia';
import { useNotifStore } from '@/app/stores/notification/notif-store';

import { ElPopover, ElMessageBox, ElBadge } from "element-plus";

import SvgIcon from "@/app/components/svg-icon/SvgIcon.vue";
import BtnHeadNotif from "@/app/components/controls/buttons/btn-head-notif/BtnHeadNotif.vue";
import NotifMessageManager from "@/app/components/header/notif-feed/message-types/notif-message-manager/NotifMessageManager.vue";
import NotifMessageSystem from '@/app/components/header/notif-feed/message-types/notif-message-system/NotifMessageSystem.vue';

export default {
	name: 'NotifFeed',
	components: {
		ElPopover, ElBadge,
		SvgIcon, BtnHeadNotif,
		NotifMessageManager, NotifMessageSystem,
	},
	data() {
		return {
			isVisiblePopover: false,
		}
	},
	computed: {
		...mapState(useNotifStore, ['notifData']),

		countNotRead(){
			const notReadList = this.notifData?.filter(item=>!item.isRead);

			return notReadList?.length ?? 0;
		}
	},
	methods: {
		clickAllCheck(){
			ElMessageBox.confirm(null, null, {
				title: 'Отметить все как прочитанные?',
				// message: 'Вы точно хотите применить изменения?',
				showClose: false,
				customClass: 'bs-message-box',
				// type: 'warning',
			}).then(() => {
				console.log('Пометить всё прочитанным');
				const notReadNotifItems = this.notifData.filter(item=>!item.isRead);
				for( const item of notReadNotifItems ){
					item.isRead = true;
				}
			}).catch(() => {
				console.log('Отмена');
			})
		},
	},
	created(){
		// console.log(this.notifData);
	}
}