import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pr-cell-edit-percent" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsInput = _resolveComponent("BsInput")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BsInput, {
      modelValue: _ctx.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.value) = $event)),
      type: "number",
      min: "0",
      step: "0.01",
      onChange: _ctx.change
    }, null, 8 /* PROPS */, ["modelValue", "onChange"]),
    _cache[1] || (_cache[1] = _createElementVNode("span", { class: "pr-cell-edit-percent__unit" }, "%", -1 /* HOISTED */))
  ]))
}