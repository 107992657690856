import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "design-target-info" }
const _hoisted_2 = { class: "design-target-info__select-tp control" }
const _hoisted_3 = { class: "control full-free-width" }
const _hoisted_4 = {
  key: 0,
  class: "design-target-info__container"
}
const _hoisted_5 = { class: "design-target-info__filters" }
const _hoisted_6 = { class: "design-target-info__months" }
const _hoisted_7 = { class: "design-target-info__side-list" }
const _hoisted_8 = { class: "design-target-info__total-price" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsSelectV2 = _resolveComponent("BsSelectV2")
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_DstrInfoFilters = _resolveComponent("DstrInfoFilters")
  const _component_DstrMonths = _resolveComponent("DstrMonths")
  const _component_DstrSideList = _resolveComponent("DstrSideList")
  const _component_DstrTotalPrice = _resolveComponent("DstrTotalPrice")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "control__label" }, "Выбрать АП", -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_BsSelectV2, {
          placeholder: "Введите название или ID",
          modelValue: _ctx.targetProgramId,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.targetProgramId) = $event)),
          onChange: _ctx.changeSelectTP,
          options: _ctx.targetOptions,
          filterable: true,
          remote: true,
          "remote-method": _ctx.searchOptions,
          size: "small"
        }, null, 8 /* PROPS */, ["modelValue", "onChange", "options", "remote-method"])
      ]),
      (_ctx.targetTabData?.id)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "design-target-info__open-tp",
            title: "Открыть АП",
            onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.openTP && _ctx.openTP(...args)))
          }, [
            _createVNode(_component_SvgIcon, { name: "12-open" })
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    _createVNode(_Transition, {
      name: "el-fade-n",
      mode: "out-in"
    }, {
      default: _withCtx(() => [
        (_ctx.targetTabData)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_DstrInfoFilters, {
                  ref: "refFilters",
                  onChange: _ctx.changeFilters
                }, null, 8 /* PROPS */, ["onChange"])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_DstrMonths, { list: _ctx.months }, null, 8 /* PROPS */, ["list"])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_DstrSideList, {
                  list: _ctx.sides,
                  onRemoveItem: _ctx.removeSideItem,
                  onOpenSideMap: _ctx.openSideMap
                }, null, 8 /* PROPS */, ["list", "onRemoveItem", "onOpenSideMap"])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_DstrTotalPrice, { data: _ctx.priceData }, null, 8 /* PROPS */, ["data"])
              ])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    })
  ]))
}