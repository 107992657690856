const summaryUpdateConfig = [
	{
		key: 'title',
		param: 'change-name',
		keyField: 'name',
	},
	{
		key: 'status',
		param: 'change-status',
		keyField: 'status',
	},
	{
		key: 'departments',
		param: 'change-departments',
		keyField: 'departmentIds',
	},
	{
		key: 'responsibleManager',
		param: 'change-manager',
		keyField: 'managerId',
	},
	{
		key: 'customers',
		param: 'change-customers',
		keyField: 'customerIds',
	},
	{
		key: 'type',
		param: 'change-type',
		keyField: 'type',
	},
	{
		key: 'comment',
		param: 'change-comment',
		keyField: 'comment',
	},
	{
		key: 'showForAll',
		param: 'change-show-for-all',
		keyField: 'showForAll',
	},
	{
		key: 'allowEdit',
		param: 'change-allow-edit',
		keyField: 'allowEdit',
	},
];

const ruleUpdateConfig = [
	{
		key: 'status',
		param: 'change-enable',
		keyField: 'isEnabled',
	},
	{
		key: 'pos',
		param: 'change-priority',
		keyField: 'priority',
	},
	{
		key: 'rules',
		param: 'change-filters',
		keyField: 'filters',
	},

	{
		key: 'targetDiscount',
		param: 'change-prices-data',
		keyField: 'targetDiscount',
	},
	{
		key: 'maxDiscount',
		param: 'change-prices-data',
		keyField: 'maxDiscount',
	},
	{
		key: 'curMonthDiscount',
		param: 'change-prices-data',
		keyField: 'discountForCurrentMonth',
	},
	{
		key: 'coefficient',
		param: 'change-prices-data',
		keyField: 'coefficient',
	},

	{
		key: 'design',
		param: 'change-addserv-data',
		keyField: 'designPrice',
	},
	{
		key: 'print',
		param: 'change-addserv-data',
		keyField: 'printPrice',
	},
	{
		key: 'installation',
		param: 'change-addserv-data',
		keyField: 'installationPrice',
	},
	{
		key: 'photoReport',
		param: 'change-addserv-data',
		keyField: 'photoReportPrice',
	},

	{
		key: 'isMultipCoefficient',
		param: 'change-prices-data',
		keyField: 'coefficientMult',
	},
	{
		key: 'comment',
		param: 'change-comment',
		keyField: 'comment',
	},
];

export { summaryUpdateConfig, ruleUpdateConfig };