import dayjs from 'dayjs';

import helper from '@/app/utils/helper';

export default {
	name: 'dstr-months',
	props: {
		list: {
			type: Array,
			default: [],
		}
	},
	methods: {
		getFormatMonth(data){
			if(Array.isArray(data)){
				const [from, to] = data;
				const formatFrom = helper.upFirstLetter(dayjs(from).format('MMM’YY'));
				const formatTo = helper.upFirstLetter(dayjs(to).format('MMM’YY'));

				return `${formatFrom} - ${formatTo}`;
			}
			else if( typeof data == 'string' ){
				return helper.upFirstLetter(dayjs(data).format('MMM’YY'));
			}
		},
		getHash(data){
			return JSON.stringify(data);
		}
	},
}