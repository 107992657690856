// SCSS
// Стили компонентов Element Plus
import 'element-plus/es/components/header/style/css';
import 'element-plus/es/components/dropdown/style/css';
import 'element-plus/es/components/dropdown-menu/style/css';
import 'element-plus/es/components/dropdown-item/style/css';
import 'element-plus/es/components/tabs/style/css';
import 'element-plus/es/components/tab-pane/style/css';
import 'element-plus/es/components/badge/style/css';
import 'element-plus/es/components/message-box/style/css';
import 'element-plus/es/components/message/style/css';
import 'element-plus/es/components/switch/style/css';
import "element-plus/es/components/table-v2/style/css";
import 'element-plus/es/components/table-column/style/css';
import 'element-plus/es/components/button/style/css';
import 'element-plus/es/components/checkbox/style/css';
import 'element-plus/es/components/image/style/css';
import 'element-plus/es/components/dialog/style/css';
import 'element-plus/es/components/form/style/css';

import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

// Общие стили
import '@/styles/main.scss';

// JS
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import router from '@/app/router';
import * as Sentry from "@sentry/vue";

import { DraggableDirective } from '@braks/revue-draggable';
import { ElInfiniteScroll } from 'element-plus';
import VueVirtualScroller from 'vue-virtual-scroller';

import App from '@/app/App.vue';

// console.clear();
const pinia = createPinia();
const app = createApp(App);

const isDev = window.localStorage.getItem('dev');
if(!isDev) Sentry.init({
	app,
	dsn: "https://0ec07e30d0ea7d32be5273c541e56bcd@o4507458911404032.ingest.de.sentry.io/4507459127935056",
	release:'0.0.1',
	integrations: [
		Sentry.browserTracingIntegration({ router }),
		Sentry.replayIntegration(),
	],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for tracing.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0,

	// Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
	tracePropagationTargets: ['localhost', /^https:\/\/outplan-test.devtestprod.ru\/api/, /^https:\/\/outplan-prod.devtestprod.ru\/api/ ],

	// Capture Replay for 10% of all sessions,
	// plus for 100% of sessions with an error
	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1.0,
});

app.use(router);
app.use(pinia);
app.use(ElInfiniteScroll);
app.use(VueVirtualScroller);
app.directive('draggable', DraggableDirective);
app.mount('#app');