import { mapActions, mapState, mapWritableState } from 'pinia';
import { useFiltersStore } from '@/app/stores/filters-store';

import { ElDialog } from "element-plus";

import BsInput from "@/app/components/controls/bs-input/BsInput.vue";
import BsSingleSelect from "@/app/components/controls/selects/bs-single-select/BsSingleSelect.vue";
import BsBtn from "@/app/components/controls/buttons/bs-btn/BsBtn.vue";

export default {
	name: 'AddPricingDialog',
	components: {
		ElDialog,
		BsBtn, BsInput, BsSingleSelect,
	},
	props: {
		visible:{
			type: Boolean,
			default: false,
		},
	},
	emits: ['update:visible', 'change'],
	data() {
		return {
			name: '',
			type: '',
		}
	},
	computed: {
		isVisible: {
			get(){
				return this.visible;
			},
			set(is){
				this.$emit('update:visible', is);
			},
		},

		disabledCreateBtn(){
			return !(this.name && this.name?.length>2 && this.type);
		}
	},
	methods: {
		...mapActions(useFiltersStore, ['getOptions']),

		create(){
			this.$emit('change', {
				name: this.name,
				type: this.type
			});

			this.isVisible = false;
		},
	},
	watch: {
		isVisible(is){
			if(is){ } else {
				this.name = '';
				this.type = '';
			}
		}
	},
}