import { colConfigList } from '../table-config';
import helper from '@/app/utils/helper';

import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';
import BsBtn from '@/app/components/controls/buttons/bs-btn/BsBtn.vue';
import BsInput from '@/app/components/controls/bs-input/BsInput.vue';
import BsCheckbox from '@/app/components/controls/bs-checkbox/BsCheckbox.vue';
import TpTempSettings from './tp-temp-settings/TpTempSettings.vue';

export default {
	name: 'tprog-settings-table',
	components: {
		BsBtn, BsInput, BsCheckbox,
		SvgIcon, TpTempSettings,
	},
	props: {
		settings:{
			type: Object,
			default: {},
		},
	},
	emits: ['cancel', 'save'],
	data() {
		return {
			strSearch: '',
			isInputFocus: false,
			colConfigList: helper.clone(colConfigList),
			isCollapsed: this.settings?.isCollapsed ?? false,
			templateConfigData: [],
		}
	},
	computed: {
		searchList(){
			let list = this.colConfigList;
			const strFormat = this.strSearch.toLocaleLowerCase();
			if(this.strSearch?.length > 2){
				list = this.colConfigList.filter(
					item=>item.label?.toLocaleLowerCase().includes(strFormat)
				);
			}
			return list;
		},
		btnOkDisabled(){
			let is = true;
			for(let { key, show } of this.colConfigList){
				const findItem = colConfigList.find(item=>item.key==key);
				is = findItem.show == show;
				// if(!show) console.log(show);
				if(!is) break;
			}
			return is && (this.settings?.isCollapsed === this.isCollapsed);
		},
		colConfigsMap(){
			const data = {};
			for(const item of this.colConfigList){
				data[item.key] = item;
			}
			return data;
		},
		templateOptions(){
			return this.templateConfigData.map(item=>{
				const { id, name } = item;
				return {
					value: id,
					label: name,
				}
			});
		}
	},
	methods: {
		clickCollapse(){
			this.isCollapsed = !this.isCollapsed;
		},
		clickItem(item){
			console.log(item);
			if(!item.disabledCheck) item.show = !item.show;
		},
		clickSave(){
			if(!this.btnOkDisabled) this.$emit('save', {
				isCollapsed: this.isCollapsed,
				colConfigList: this.colConfigList,
			});
		},
		clickCancel(){
			this.colConfigList = helper.clone(colConfigList),
			this.$emit('cancel');
		},
		clickAllShow(){
			for(let item of this.colConfigList){
				if(item.key!='hr') item.show = true;
			}
			this.isCollapsed = false;
		},
		saveTemplate({ id, tempName, configList }){
			this.templateConfigData.push({
				id,
				name: tempName,
				configList,
			});
			this.setLocalStorageTemplate();
		},
		changeTemplate(value){
			const findConfig = this.templateConfigData.find(item=>item.id==value);
			console.log(value, findConfig);
			if(!findConfig) return;
			const { id, name, configList } = findConfig;

			for(const item of configList){
				if(this.colConfigsMap[item.key] !== undefined) this.colConfigsMap[item.key].show = item.show;
			}

			// this.$emit('save', {
			// 	isCollapsed: this.isCollapsed,
			// 	colConfigList: this.colConfigList,
			// });
		},
		removeTemplate(value){
			this.templateConfigData = this.templateConfigData.filter(item=>item.id != value);
			this.setLocalStorageTemplate();
		},
		setLocalStorageTemplate(){
			try {
				window.localStorage.setItem('tp-temp-settings', JSON.stringify(this.templateConfigData) );
			} catch (error) {
				console.error(error);
			}
		},
		getLocalStorageTemplate(){
			try {
				const data = window.localStorage.getItem('tp-temp-settings');
				return JSON.parse(data);
			} catch (error) {
				console.error(error);
			}
			return;
		}
	},
	watch:{
		['settings.isCollapsed'](is){
			this.isCollapsed = is;
		}
	},
	created(){
		const templateConfigData = this.getLocalStorageTemplate();
		// console.log(templateConfigData);
		if(templateConfigData?.length) this.templateConfigData = templateConfigData;
	},
}