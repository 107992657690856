import dayjs from 'dayjs';

import { ElPopover } from "element-plus";
import 'element-plus/es/components/popover/style/css';

import cellMixin from "@/app/mixins/table/cell-mixin";

import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';
import ShortSelect from '@/app/components/controls/selects/short-select/ShortSelect.vue';
import SideQueueItem2 from '@/app/components/map/map-design-info/side-queue-v2/side-queue-item-2/SideQueueItem2.vue';
import TprogEditDatePopover from '@/app/components/target-programs/tprog-detail-table/tprog-edit-date-popover/TprogEditDatePopover.vue';

export default {
	name: 'tprog-cell-status',
	mixins: [ cellMixin ],
	components: {
		ElPopover,
		SvgIcon, ShortSelect, SideQueueItem2,
		TprogEditDatePopover,
	},
	props: {
		options: {
			type: Array,
			default: [],
		}
	},
	emits: ['change', 'extendReserve'],
	data() {
		return {
			fieldValue: '',
			isOpenEditDatePopover: false,
		}
	},
	computed: {
		/*
			{
				"my": false,
				"status": "free",
				"queue": {
					"title": {
						"position": 1,
						"expired": "2024-05-17"
					},
					"queueList": []
				}
			}
		*/
		isMy(){
			return this.fieldData?.my;
		},
		status(){
			return this.fieldData?.status ?? '';
		},
		monthStatus(){
			return this.fieldData?.monthStatus ?? '';
		},
		queue(){
			// console.log(this.fieldData);
			return this.fieldData?.queue ?? {};
		},
		label(){
			if( !this.isMy && this.status=='approved' ) return 'Занято';
			if( this.isMy && this.status=='reserved' ) return 'Мой резерв';

			const findItem = this.options.find(item=>item.value==this.status);
			return findItem?.label ?? '';
		},
		formatDate(){
			if(!this?.queue?.title?.expired) return '';
			const { expired } = this.queue.title;

			return dayjs(expired).format('DD.MM.YYYY');
		},

		statusData(){
			if(this.status.includes('free')) return {
				className: 'type-free',
			};
			else if(this.status.includes('temporary_approved')) return {
				className: 'type-agr-reserved',
				icon:'14-upload',
			};
			else if(this.status.includes('reserved')) return {
				className: 'type-reserved',
				icon:'14-upload',
			};
			else if(!this.isMy && this.status=='approved') return {
				className: 'type-busy',
				icon:'14-view',
			};
			else if(this.status.includes('approved')) return {
				className: 'type-confirm',
			};
			return {
				className: '',
			};
		},
	},
	methods: {
		changeSelect(value){
			this.$emit('change', {
				rowData: this.rowData,
				groupKey: this.groupKey,
				fieldKey: this.fieldKey,
				fieldData: value,
			});
		},
		clickIcon(){
			console.log(this.status);
		},
		extendReserve(date){
			// console.log(date);
			this.$emit('extendReserve', {
				date,
				rowData: this.rowData,
			});
		}
	},
	watch: {
		fieldData(val){
			this.fieldValue = this.monthStatus;
			// console.log(this.fieldValue);
		}
	},
	created() {
		if(this.fieldData?.monthStatus) this.fieldValue = this.monthStatus;
		// console.log(this.fieldValue);
	},
}