import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "pricing-list-table" }
const _hoisted_2 = {
  key: 0,
  class: "table-grid__cell"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  key: 0,
  class: "table-grid__cell"
}
const _hoisted_5 = {
  key: 1,
  class: "table-grid__cell"
}
const _hoisted_6 = {
  key: 2,
  class: "table-grid__cell"
}
const _hoisted_7 = {
  key: 3,
  class: "table-grid__cell"
}
const _hoisted_8 = {
  key: 4,
  class: "table-grid__cell pricing-list-table__updated-at"
}
const _hoisted_9 = {
  key: 5,
  class: "table-grid__cell"
}
const _hoisted_10 = {
  key: 6,
  class: "table-grid__cell"
}
const _hoisted_11 = {
  key: 7,
  class: "table-grid__cell"
}
const _hoisted_12 = {
  key: 8,
  class: "table-grid__cell"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_CellMassActions = _resolveComponent("CellMassActions")
  const _component_PrTitleLink = _resolveComponent("PrTitleLink")
  const _component_CellNumber = _resolveComponent("CellNumber")
  const _component_CellDate = _resolveComponent("CellDate")
  const _component_PrCellResponsible = _resolveComponent("PrCellResponsible")
  const _component_CellTags = _resolveComponent("CellTags")
  const _component_TableGrid = _resolveComponent("TableGrid")
  const _component_MassPricesStatusDialog = _resolveComponent("MassPricesStatusDialog")
  const _component_MassPricesDepartDialog = _resolveComponent("MassPricesDepartDialog")
  const _component_MassPricesManagerDialog = _resolveComponent("MassPricesManagerDialog")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TableGrid, {
      columns: _ctx.columns,
      rows: _ctx.tableData,
      isSelected: true,
      onChangeSelectedRows: _ctx.changeSelectedRows,
      onDblClickRow: _ctx.dblClickRow
    }, {
      th: _withCtx(({ colData }) => [
        (colData.key=='massAction')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createVNode(_component_CellMassActions, {
                title: "Массовые действия",
                count: _ctx.selectedRows.length,
                actionList: _ctx.massActionsList,
                onChange: _ctx.changeMass
              }, null, 8 /* PROPS */, ["count", "actionList", "onChange"])
            ]))
          : (_openBlock(), _createElementBlock("span", {
              key: 1,
              innerHTML: colData.label
            }, null, 8 /* PROPS */, _hoisted_3))
      ]),
      td: _withCtx(({ colData, rowData }) => [
        (colData.key=='key1')
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, "key1"))
          : (colData.key=='title')
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_PrTitleLink, {
                  data: { row: rowData },
                  groupKey: colData.keyGroup,
                  fieldKey: colData.keyProp
                }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"])
              ]))
            : (colData.key=='count')
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_CellNumber, {
                    data: { row: rowData },
                    groupKey: colData.keyGroup,
                    fieldKey: colData.keyProp
                  }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"])
                ]))
              : (colData.key=='createdAt')
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _createVNode(_component_CellDate, {
                      data: { row: rowData },
                      groupKey: colData.keyGroup,
                      fieldKey: colData.keyProp
                    }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"])
                  ]))
                : (colData.key=='updatedAt')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createVNode(_component_CellDate, {
                        data: { row: rowData },
                        groupKey: colData.keyGroup,
                        fieldKey: colData.keyProp,
                        showTime: true
                      }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"])
                    ]))
                  : (colData.key=='responsible')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createVNode(_component_PrCellResponsible, {
                          data: { row: rowData },
                          groupKey: colData.keyGroup,
                          fieldKey: colData.keyProp
                        }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"])
                      ]))
                    : (colData.key=='department')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                          _createVNode(_component_CellTags, {
                            data: { row: rowData },
                            groupKey: colData.keyGroup,
                            fieldKey: colData.keyProp
                          }, null, 8 /* PROPS */, ["data", "groupKey", "fieldKey"])
                        ]))
                      : (colData.key=='massAction')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                            _createVNode(_component_CellMassActions, {
                              actionList: _ctx.actionList,
                              onChange: $event => (_ctx.changeRow($event, rowData))
                            }, null, 8 /* PROPS */, ["actionList", "onChange"])
                          ]))
                        : (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(rowData?.[colData.keyProp] || '–'), 1 /* TEXT */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["columns", "rows", "onChangeSelectedRows", "onDblClickRow"]),
    _createVNode(_component_MassPricesStatusDialog, {
      visible: _ctx.isShowMassStatusDialog,
      "onUpdate:visible": _cache[0] || (_cache[0] = $event => ((_ctx.isShowMassStatusDialog) = $event)),
      options: _ctx.getOptions('pricePolicyStatuses'),
      onChange: _ctx.massStatus
    }, null, 8 /* PROPS */, ["visible", "options", "onChange"]),
    _createVNode(_component_MassPricesDepartDialog, {
      visible: _ctx.isShowMassDepartDialog,
      "onUpdate:visible": _cache[1] || (_cache[1] = $event => ((_ctx.isShowMassDepartDialog) = $event)),
      options: _ctx.getOptions('department'),
      onChange: _ctx.massDepart
    }, null, 8 /* PROPS */, ["visible", "options", "onChange"]),
    _createVNode(_component_MassPricesManagerDialog, {
      visible: _ctx.isShowMassManagerDialog,
      "onUpdate:visible": _cache[2] || (_cache[2] = $event => ((_ctx.isShowMassManagerDialog) = $event)),
      options: _ctx.getOptions('managers'),
      onChange: _ctx.massManager
    }, null, 8 /* PROPS */, ["visible", "options", "onChange"])
  ]))
}