import { ElDialog, ElMessageBox } from "element-plus";


import BsInput from '@/app/components/controls/bs-input/BsInput.vue';
import BsBtn from '@/app/components/controls/buttons/bs-btn/BsBtn.vue';

export default {
	name: 'di-edit-input',
	components: {
		ElDialog,
		BsInput, BsBtn,
	},
	props:{
		titleDialog: String,
		labelActiveButton: {
			type: String,
			default: 'Изменить',
		},
		visible: {
			type: Boolean,
			default: false,
		},
		fieldValue: {
			type: String,
			default: '',
		},
	},
	emits:['update:visible','change'],
	data() {
		return {
			isVisible: this.visible,
			title: this.label,
			value: this.fieldValue,
		}
	},
	computed: {
		isBtnEditDisabled(){
			return this.value == this.fieldValue;
		}
	},
	methods: {
		clickEdit(){
			if(this.isBtnEditDisabled) return;

			ElMessageBox.confirm(null, null, {
				title: 'Внимание',
				message: 'Вы точно хотите применить изменения?',
				showClose: false,
				// dangerouslyUseHTMLString: true,
				customClass: 'bs-message-box',
				type: 'warning',
			}).then(() => {
				this.$emit('change', this.value);
				this.clickCancel();
			}).catch(() => {

			})
		},
		clickCancel(){
			this.isVisible = false;
			this.value = '';
		}
	},
	watch: {
		isVisible(is){
			this.$emit('update:visible', is);
			if(is) this.value = this.fieldValue;
		},
		visible(is){
			this.isVisible = is;
		}
	},
}