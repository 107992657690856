import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "new-targprog-form__content" }
const _hoisted_2 = { class: "new-targprog-form__wrap-controls" }
const _hoisted_3 = { class: "control control--required" }
const _hoisted_4 = { class: "control__group" }
const _hoisted_5 = { class: "control" }
const _hoisted_6 = { class: "control__group" }
const _hoisted_7 = { class: "control" }
const _hoisted_8 = { class: "control__group" }
const _hoisted_9 = { class: "control control--required" }
const _hoisted_10 = { class: "control__group" }
const _hoisted_11 = { class: "control" }
const _hoisted_12 = { class: "control__group" }
const _hoisted_13 = { class: "control control--required" }
const _hoisted_14 = { class: "control__group" }
const _hoisted_15 = { class: "control control--required" }
const _hoisted_16 = { class: "control__group" }
const _hoisted_17 = { class: "control" }
const _hoisted_18 = { class: "control__group" }
const _hoisted_19 = { class: "control" }
const _hoisted_20 = { class: "control__group" }
const _hoisted_21 = { class: "control" }
const _hoisted_22 = { class: "control__group" }
const _hoisted_23 = { class: "new-targprog-form__content" }
const _hoisted_24 = { class: "new-targprog-form__wrap-controls" }
const _hoisted_25 = { class: "control" }
const _hoisted_26 = { class: "control__group" }
const _hoisted_27 = { class: "control" }
const _hoisted_28 = { class: "control__group" }
const _hoisted_29 = { class: "control" }
const _hoisted_30 = { class: "control__group" }
const _hoisted_31 = { class: "control" }
const _hoisted_32 = { class: "control__group" }
const _hoisted_33 = { class: "control" }
const _hoisted_34 = { class: "control__group" }
const _hoisted_35 = { class: "control" }
const _hoisted_36 = { class: "control__group" }
const _hoisted_37 = { class: "new-targprog-form__action-buttons" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsInput = _resolveComponent("BsInput")
  const _component_ElFormItem = _resolveComponent("ElFormItem")
  const _component_BsSelectV2 = _resolveComponent("BsSelectV2")
  const _component_ElTabPane = _resolveComponent("ElTabPane")
  const _component_ElTabs = _resolveComponent("ElTabs")
  const _component_BsBtn = _resolveComponent("BsBtn")
  const _component_ElForm = _resolveComponent("ElForm")
  const _component_ElDialog = _resolveComponent("ElDialog")

  return (_openBlock(), _createBlock(_component_ElDialog, {
    class: "new-targprog-form",
    modelValue: _ctx.isVisible,
    "onUpdate:modelValue": _cache[17] || (_cache[17] = $event => ((_ctx.isVisible) = $event)),
    title: "Создание адресной программы",
    width: "480",
    "align-center": ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ElForm, {
        ref: "ruleFormRef",
        model: _ctx.targetProgDialogData.ruleForm,
        rules: _ctx.rules
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ElTabs, {
            class: "new-targprog-form__tabs",
            modelValue: _ctx.activeTab,
            "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => ((_ctx.activeTab) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ElTabPane, {
                label: "Основная информация",
                name: "main-info"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_ElFormItem, { prop: "nameTargetProgram" }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_3, [
                            _cache[18] || (_cache[18] = _createElementVNode("div", { class: "control__label" }, "Название адресной программы", -1 /* HOISTED */)),
                            _createElementVNode("div", _hoisted_4, [
                              _createVNode(_component_BsInput, {
                                placeholder: "Введите название",
                                modelValue: _ctx.targetProgDialogData.ruleForm.nameTargetProgram,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.targetProgDialogData.ruleForm.nameTargetProgram) = $event))
                              }, null, 8 /* PROPS */, ["modelValue"])
                            ])
                          ])
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createElementVNode("div", _hoisted_5, [
                        _cache[19] || (_cache[19] = _createElementVNode("div", { class: "control__label" }, "Агентство", -1 /* HOISTED */)),
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_component_BsSelectV2, {
                            placeholder: "Выберите из списка",
                            modelValue: _ctx.targetProgDialogData.agency,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.targetProgDialogData.agency) = $event)),
                            options: _ctx.getOptions('agency'),
                            filterable: true,
                            clearable: true,
                            size: "large"
                          }, null, 8 /* PROPS */, ["modelValue", "options"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_7, [
                        _cache[20] || (_cache[20] = _createElementVNode("div", { class: "control__label" }, "Клиент", -1 /* HOISTED */)),
                        _createElementVNode("div", _hoisted_8, [
                          _createVNode(_component_BsSelectV2, {
                            placeholder: "Выберите из списка",
                            modelValue: _ctx.targetProgDialogData.client,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.targetProgDialogData.client) = $event)),
                            options: _ctx.getOptions('customer'),
                            filterable: true,
                            clearable: true,
                            size: "large"
                          }, null, 8 /* PROPS */, ["modelValue", "options"])
                        ])
                      ]),
                      _createVNode(_component_ElFormItem, { prop: "clientCategory" }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_9, [
                            _cache[21] || (_cache[21] = _createElementVNode("div", { class: "control__label" }, "Категория клиента", -1 /* HOISTED */)),
                            _createElementVNode("div", _hoisted_10, [
                              _createVNode(_component_BsSelectV2, {
                                placeholder: "Выберите из списка",
                                modelValue: _ctx.targetProgDialogData.ruleForm.clientCategory,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.targetProgDialogData.ruleForm.clientCategory) = $event)),
                                options: _ctx.getOptions('customer_category'),
                                filterable: true,
                                clearable: true,
                                size: "large"
                              }, null, 8 /* PROPS */, ["modelValue", "options"])
                            ])
                          ])
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createElementVNode("div", _hoisted_11, [
                        _cache[22] || (_cache[22] = _createElementVNode("div", { class: "control__label" }, "Бренд", -1 /* HOISTED */)),
                        _createElementVNode("div", _hoisted_12, [
                          _createVNode(_component_BsSelectV2, {
                            placeholder: "Введите название или выберите из списка",
                            modelValue: _ctx.targetProgDialogData.brand,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.targetProgDialogData.brand) = $event)),
                            options: _ctx.getOptions('brand'),
                            clearable: true,
                            filterable: true,
                            size: "large"
                          }, null, 8 /* PROPS */, ["modelValue", "options"])
                        ])
                      ]),
                      _createVNode(_component_ElFormItem, { prop: "typePlacement" }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_13, [
                            _cache[23] || (_cache[23] = _createElementVNode("div", { class: "control__label" }, "Тип размещения", -1 /* HOISTED */)),
                            _createElementVNode("div", _hoisted_14, [
                              _createVNode(_component_BsSelectV2, {
                                placeholder: "Выберите тип",
                                modelValue: _ctx.targetProgDialogData.ruleForm.typePlacement,
                                "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.targetProgDialogData.ruleForm.typePlacement) = $event)),
                                options: _ctx.getOptions('placement_type'),
                                filterable: true,
                                clearable: true,
                                size: "large"
                              }, null, 8 /* PROPS */, ["modelValue", "options"])
                            ])
                          ])
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_ElFormItem, { prop: "department" }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_15, [
                            _cache[24] || (_cache[24] = _createElementVNode("div", { class: "control__label" }, "Подразделение", -1 /* HOISTED */)),
                            _createElementVNode("div", _hoisted_16, [
                              _createVNode(_component_BsSelectV2, {
                                placeholder: "Выберите из списка",
                                modelValue: _ctx.targetProgDialogData.ruleForm.department,
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.targetProgDialogData.ruleForm.department) = $event)),
                                options: _ctx.getOptions('department'),
                                filterable: true,
                                clearable: true,
                                size: "large"
                              }, null, 8 /* PROPS */, ["modelValue", "options"])
                            ])
                          ])
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createElementVNode("div", _hoisted_17, [
                        _cache[25] || (_cache[25] = _createElementVNode("div", { class: "control__label" }, "Акции", -1 /* HOISTED */)),
                        _createElementVNode("div", _hoisted_18, [
                          _createVNode(_component_BsSelectV2, {
                            placeholder: "Выберите из списка",
                            modelValue: _ctx.targetProgDialogData.promo,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_ctx.targetProgDialogData.promo) = $event)),
                            options: _ctx.getOptions('promo'),
                            filterable: true,
                            clearable: true,
                            size: "large"
                          }, null, 8 /* PROPS */, ["modelValue", "options"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_19, [
                        _cache[26] || (_cache[26] = _createElementVNode("div", { class: "control__label" }, "Ссылка на сделку в Битрикс24", -1 /* HOISTED */)),
                        _createElementVNode("div", _hoisted_20, [
                          _createVNode(_component_BsInput, {
                            placeholder: "Вставьте ссылку",
                            modelValue: _ctx.targetProgDialogData.dealId,
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => ((_ctx.targetProgDialogData.dealId) = $event))
                          }, null, 8 /* PROPS */, ["modelValue"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_21, [
                        _cache[27] || (_cache[27] = _createElementVNode("div", { class: "control__label" }, "Ссылка на задачу в Битрикс24", -1 /* HOISTED */)),
                        _createElementVNode("div", _hoisted_22, [
                          _createVNode(_component_BsInput, {
                            placeholder: "Вставьте ссылку",
                            modelValue: _ctx.targetProgDialogData.linkDealB24,
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => ((_ctx.targetProgDialogData.linkDealB24) = $event))
                          }, null, 8 /* PROPS */, ["modelValue"])
                        ])
                      ])
                    ])
                  ])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_ElTabPane, {
                label: "Юрлица",
                name: "person-info"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("div", _hoisted_24, [
                      _createElementVNode("div", _hoisted_25, [
                        _cache[28] || (_cache[28] = _createElementVNode("div", { class: "control__label" }, "Юр. лицо клиента", -1 /* HOISTED */)),
                        _createElementVNode("div", _hoisted_26, [
                          _createVNode(_component_BsSelectV2, {
                            placeholder: "Выберите из списка",
                            modelValue: _ctx.targetProgDialogData.legalPerson,
                            "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => ((_ctx.targetProgDialogData.legalPerson) = $event)),
                            options: _ctx.getOptions('customer_legal_entity'),
                            filterable: true,
                            clearable: true,
                            size: "large"
                          }, null, 8 /* PROPS */, ["modelValue", "options"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_27, [
                        _cache[29] || (_cache[29] = _createElementVNode("div", { class: "control__label" }, "Юр. лицо МГ РИМ", -1 /* HOISTED */)),
                        _createElementVNode("div", _hoisted_28, [
                          _createVNode(_component_BsSelectV2, {
                            placeholder: "Выберите из списка",
                            modelValue: _ctx.targetProgDialogData.rimPerson,
                            "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => ((_ctx.targetProgDialogData.rimPerson) = $event)),
                            options: _ctx.getOptions('rim_legal_entity'),
                            filterable: true,
                            clearable: true,
                            size: "large"
                          }, null, 8 /* PROPS */, ["modelValue", "options"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_29, [
                        _cache[30] || (_cache[30] = _createElementVNode("div", { class: "control__label" }, "Монтаж", -1 /* HOISTED */)),
                        _createElementVNode("div", _hoisted_30, [
                          _createVNode(_component_BsSelectV2, {
                            placeholder: "Выберите из списка",
                            modelValue: _ctx.targetProgDialogData.install,
                            "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => ((_ctx.targetProgDialogData.install) = $event)),
                            options: _ctx.getOptions('installer'),
                            filterable: true,
                            clearable: true,
                            size: "large"
                          }, null, 8 /* PROPS */, ["modelValue", "options"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_31, [
                        _cache[31] || (_cache[31] = _createElementVNode("div", { class: "control__label" }, "Печать", -1 /* HOISTED */)),
                        _createElementVNode("div", _hoisted_32, [
                          _createVNode(_component_BsSelectV2, {
                            placeholder: "Выберите из списка",
                            modelValue: _ctx.targetProgDialogData.print,
                            "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => ((_ctx.targetProgDialogData.print) = $event)),
                            options: _ctx.getOptions('printer'),
                            filterable: true,
                            clearable: true,
                            size: "large"
                          }, null, 8 /* PROPS */, ["modelValue", "options"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_33, [
                        _cache[32] || (_cache[32] = _createElementVNode("div", { class: "control__label" }, "Дизайн", -1 /* HOISTED */)),
                        _createElementVNode("div", _hoisted_34, [
                          _createVNode(_component_BsSelectV2, {
                            placeholder: "Выберите из списка",
                            modelValue: _ctx.targetProgDialogData.design,
                            "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => ((_ctx.targetProgDialogData.design) = $event)),
                            options: _ctx.getOptions('designer'),
                            filterable: true,
                            clearable: true,
                            size: "large"
                          }, null, 8 /* PROPS */, ["modelValue", "options"])
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_35, [
                        _cache[33] || (_cache[33] = _createElementVNode("div", { class: "control__label" }, "Фотоотчет", -1 /* HOISTED */)),
                        _createElementVNode("div", _hoisted_36, [
                          _createVNode(_component_BsSelectV2, {
                            placeholder: "Выберите из списка",
                            modelValue: _ctx.targetProgDialogData.photoReport,
                            "onUpdate:modelValue": _cache[15] || (_cache[15] = $event => ((_ctx.targetProgDialogData.photoReport) = $event)),
                            options: _ctx.getOptions('photo_reporter'),
                            filterable: true,
                            clearable: true,
                            size: "large"
                          }, null, 8 /* PROPS */, ["modelValue", "options"])
                        ])
                      ])
                    ])
                  ])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue"]),
          _createElementVNode("div", _hoisted_37, [
            _createVNode(_component_BsBtn, {
              tag: "div",
              size: "small",
              onClick: _ctx.cancel
            }, {
              default: _withCtx(() => _cache[34] || (_cache[34] = [
                _createTextVNode("Отмена")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick"]),
            _createVNode(_component_BsBtn, {
              type: "primary",
              tag: "div",
              size: "small",
              onClick: _ctx.createProgram
            }, {
              default: _withCtx(() => _cache[35] || (_cache[35] = [
                _createTextVNode("Создать")
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick"])
          ])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["model", "rules"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}