import { defineStore } from 'pinia';
import dayjs from 'dayjs';

import { useFiltersStore } from '../filters-store';

import api from '@/app/utils/service-api';
import helper from '@/app/utils/helper';
import errorService from '@/app/utils/error-service';

export const usePricingListStore = defineStore('pricingListStore', {
	state() {
		return {
			isViewLoader: false,
			isAsideLoader: false,
			paginationData: null,

			filterData: {
				name: '',
				status: '',
				type: '',
				departments: [],
				manager: '',
				allowEdit: false,
			},

			tableData: [
				// {
				// 	id: 55678,
				// 	status: 'Черновик',
				// 	type: 'По умолчанию',
				// 	title: 'Название АП',
				// 	count: 12,
				// 	createdAt: new Date(),
				// 	updatedAt: new Date(),
				// 	responsible: 'Петров С.К.',
				// 	department: ['Фра', 'ОП Москва', 'ОП Кострома', 'Фра', 'ОП Москва', 'ОП Кострома'],
				// },
			],
		}
	},

	getters: {
		countFilters(){
			let count = 0;

			for(const [key, value] of Object.entries(this.filterData)){
				if(Array.isArray(value) && value?.length) count++;
				else if(!Array.isArray(value) && value) count++;
			}

			return count;
		},

		pricePolicyStatusesMap(){
			const { getOptions } = useFiltersStore();
			const options = getOptions('pricePolicyStatuses');
			const map = {};

			for( const item of options){
				const { value, label } = item;
				map[value] = label;
			}

			return map;
		},
		pricePolicyTypesMap(){
			const { getOptions } = useFiltersStore();
			const options = getOptions('pricePolicyTypes');
			const map = {};

			for( const item of options){
				const { value, label } = item;
				map[value] = label;
			}

			return map;
		}
	},
	actions: {
		clearFilters(){
			for(const [key, value] of Object.entries(this.filterData)){
				this.filterData[key] = '';
			}
		},

		buildPriceData(data){
			const list = []

			if(data?.length) for( const item of data ){
				const { status, type, departments } = item ?? {};

				list.push({
					...item,
					status: this.pricePolicyStatusesMap?.[status] ?? '',
					type: this.pricePolicyTypesMap?.[type] ?? '',
					departments: departments?.map(item=>item.name),
				});
			}

			return list;
		},

		buildFiltersData(){
			const data = {};

			for(const [key, value] of Object.entries(this.filterData)){
				if(Array.isArray(value) && value?.length) data[key] = value;
				else if(!Array.isArray(value) && value) data[key] = value;
			}

			return data;
		},

		async getPriceList({ pagination }={}){
			this.isViewLoader = true;

			let paginationData = {
				page: 1,
				perPage: 50,
			};
			const filters = this.buildFiltersData();
			console.log({filters});
			if(pagination) paginationData = pagination;
			else if(this.paginationData){
				const { current_page, per_page } = this.paginationData;
				paginationData = {
					page: current_page,
					perPage: per_page,
				}
			}

			try {
				const res = await api.postData({
					model: 'price-policy/list',
					data: {
						filters,
						...paginationData,
					},
				});
				if(res?.data) this.tableData = this.buildPriceData(res.data);
				if(res?.meta) this.paginationData = res.meta;

				console.log(res);

				this.isViewLoader = false;
				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({error});
			}

			this.isViewLoader = false;
			return;
		},

		clearFilter(){
			for(const [key, value] of Object.entries(this.filterData)){
				if(key=='allowEdit') this.filterData[key] = false;
				else this.filterData[key] = '';
			}
		},

		async createPricePolicy({ name, type }={}){
			if( !name && !type ) return;

			this.isViewLoader = true;
			try {
				const res = await api.postData({
					model: 'price-policy/create',
					data: { name, type },
				});
				console.log(res);
				if(res?.pricePolicy){
					const ruleData = this.buildPriceData([res.pricePolicy])[0];
					this.tableData.push(ruleData);
				}

				this.isViewLoader = false;
				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({error});
			}

			this.isViewLoader = false;
			return;
		},

		async removePricePolicy(pricePolicyId){
			if(!pricePolicyId) return;

			this.isViewLoader = true;
			try {
				const res = await api.postData({
					model: `price-policy/${pricePolicyId}/delete`,
				});
				console.log(res);

				this.isViewLoader = false;
				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({error});
			}

			this.isViewLoader = false;
			return;
		},

		async massPricesRemoved(ids){
			if(!ids) return;

			this.isViewLoader = true;
			try {
				const res = await api.postData({
					model: `price-policy/bulk/delete`,
					data: { ids },
				});
				console.log(res);

				this.isViewLoader = false;
				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({error});
			}

			this.isViewLoader = false;
			return;
		},

		async massChangeStatus({ ids, status }={}){
			if(!(ids && status)) return;

			this.isViewLoader = true;
			try {
				const res = await api.postData({
					model: `price-policy/bulk/change-status`,
					data: { ids, status },
				});
				console.log(res);

				this.isViewLoader = false;
				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({error});
			}

			this.isViewLoader = false;
			return;
		},

		async massChangeDepart({ ids, departmentIds }={}){
			if(!(ids && departmentIds)) return;

			this.isViewLoader = true;
			try {
				const res = await api.postData({
					model: `price-policy/bulk/change-departments`,
					data: { ids, departmentIds },
				});
				console.log(res);

				this.isViewLoader = false;
				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({error});
			}

			this.isViewLoader = false;
			return;
		},

		async massChangeManager({ ids, managerId }={}){
			if(!(ids && managerId)) return;

			this.isViewLoader = true;
			try {
				const res = await api.postData({
					model: `price-policy/bulk/change-manager`,
					data: { ids, managerId },
				});
				console.log(res);

				this.isViewLoader = false;
				return res;
			} catch (error) {
				console.error(error);
				errorService.showMessageBox({error});
			}

			this.isViewLoader = false;
			return;
		},


	},
});