import { mapState, mapActions, mapWritableState } from 'pinia';
import { usePricingDetailStore } from '@/app/stores/pricing-policies/pricing-detail-store';

import dayjs from 'dayjs';
import { ElMessage } from 'element-plus';

import EditField from '@/app/components/controls/edit-field/EditField.vue';
import BsSelectV2 from '@/app/components/controls/selects/bs-select-v2/BsSelectV2.vue';
import BsCheckbox from '@/app/components/controls/bs-checkbox/BsCheckbox.vue';
import TargetManager from '@/app/components/target-programs/target-summary/target-manager/TargetManager.vue';
import EditComment from '@/app/components/controls/edit-comment/EditComment.vue';

/*
	{
		id: 122733,
		title: 'Название прайсовой политики',
		createDate: new Date(),
		updateDate: new Date(),
		status: { value: 1, options: [
			{ value: 1, label: 'Черновик' },
			...fakeOptions,
		]},
		departments: {
			value: [1, 20, 30],
			options: [
				{ value: 1, label: 'ФРА' },
				{ value: 20, label: 'ОП Москва' },
				{ value: 30, label: 'ОП Санкт-Петербург' },
				...fakeOptions,
			],
		},
		customers: { value: [], options: fakeOptions },
		showForAll: true,
		responsibleManager: {
			value: 16,
			options: [
				{
					id: 16,
					value: 16,
					label: 'Алексеев Артур',
					firstName: "Артур",
					lastName: "Алексеев",
					department: null,
					phone: '899999999',
					tgId: 'artur',
					email: 'test@test.ru',
				},
				...fakeOptions,
			],
		},
		comment: 'Не следует, однако забывать, что консультация с широким активом обеспечивает широкому кругу (специалистов) участие в формировании форм развития.',
		allowEdit: true,
	}
*/

export default {
	name: 'pricing-summary',
	components: {
		EditField, BsSelectV2, BsCheckbox, TargetManager,
		EditComment,
	},
	computed: {
		...mapWritableState( usePricingDetailStore, ['summaryData'] ),

		date(){
			const { createDate, updateDate } = this.summaryData;

			return {
				create: dayjs(createDate).format('DD.MM.YYYY HH:mm'),
				update: dayjs(updateDate).format('DD.MM.YYYY HH:mm'),
			}
		}
	},
	methods: {
		...mapActions(usePricingDetailStore, ['updateFieldItem', 'buildSummaryData']),

		updateFieldState({ action, data, sucMessage }={}){
			if(!data) return;
			if(action) action();

			ElMessage({
				message: sucMessage ?? 'Данные успешно изменены',
				type: 'success',
			});
		},

		async changeEditField({ value, fieldData }, key){
			console.log(key, fieldData);
			if(value==undefined) return;

			const { id } = this.summaryData;
			const res = await this.updateFieldItem({
				pricePolicyId: id,
				key, value, fieldData
			});
			const { pricePolicy } = res ?? {};

			this.updateFieldState({ data: pricePolicy, action: ()=>{
				// fieldData.value = value;
				this.summaryData = this.buildSummaryData(pricePolicy);
			}});
		},

		changeShowAll(is){
			this.changeEditField({
				value: is,
			}, 'showForAll');
			// this.summaryData.showForAll = is;
		},
		changeBanOnChange(is){
			this.changeEditField({
				value: is,
			}, 'allowEdit');
			// this.summaryData.allowEdit = is;
		},
		changeComment(comment){
			this.changeEditField({
				value: comment,
			}, 'comment');
		}
	},
}