import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "tp-temp-save-dialog__content" }
const _hoisted_2 = { class: "tp-temp-save-dialog__action-buttons" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsInput = _resolveComponent("BsInput")
  const _component_BsBtn = _resolveComponent("BsBtn")
  const _component_ElDialog = _resolveComponent("ElDialog")

  return (_openBlock(), _createBlock(_component_ElDialog, {
    class: "tp-temp-save-dialog",
    modelValue: _ctx.isVisible,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.isVisible) = $event)),
    "destroy-on-close": true,
    title: "Сохранить шаблон",
    "align-center": ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_BsInput, {
          placeholder: "Введите название шаблона",
          modelValue: _ctx.tempName,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.tempName) = $event))
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_BsBtn, {
          type: "primary",
          size: "small",
          disabled: _ctx.isBtnSaveDIsabled,
          onClick: _ctx.clickSave
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("Сохранить")
          ])),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["disabled", "onClick"]),
        _createVNode(_component_BsBtn, {
          size: "small",
          onClick: _ctx.clickCancel
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode("Отмена")
          ])),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}