import { mapState, mapActions, mapWritableState } from 'pinia';
import { useRootStore } from '@/app/stores/root-store';
import { usePricingListStore } from '@/app/stores/pricing-policies/pricing-list-store';
import { useFiltersStore } from '@/app/stores/filters-store';

import { ElBadge } from 'element-plus';

import LoaderSpinner from '@/app/components/loaders/loader-spinner/LoaderSpinner.vue';
import GridPagination from '@/app/components/grid/grid-pagination/GridPagination.vue';
import BtnAction from '@/app/components/controls/buttons/btn-action/BtnAction.vue';
import PricingListTable from '@/app/components/pricing-policies/pricing-list-table/PricingListTable.vue';

import AddPricingDialog from '@/app/components/pricing-policies/dialogs/add-pricing-dialog/AddPricingDialog.vue';

export default {
	name: 'pricing-list-view',
	components: {
		ElBadge,
		LoaderSpinner, GridPagination, BtnAction,
		PricingListTable,

		AddPricingDialog,
	},
	data() {
		return {
			countMessage: 3,
			isShowAddPricingDialog: false,
		}
	},
	computed: {
		...mapState(useRootStore, ['isDevServer', 'devMode', 'isShowSecondAside']),
		...mapState(usePricingListStore, ['countFilters', 'paginationData']),
		...mapWritableState(usePricingListStore, ['isViewLoader', 'tableData']),
	},
	methods: {
		...mapActions(useRootStore, ['setShowSecondAside' ]),
		...mapActions(usePricingListStore, ['getPriceList', 'createPricePolicy']),
		...mapActions(useFiltersStore, ['getBatchFiltersData']),

		async initViewData(){
			const res = await this.getPriceList();
		},
		openAsideFilter(){
			this.setShowSecondAside(!this.isShowSecondAside);
		},
		async createPricingPolice(data){
			const res = await this.createPricePolicy(data);
			if(!res?.pricePolicy) return;

			const { id } = res.pricePolicy;
			window.open(`/pricing-policies/${id}`, '_blank');
			// this.$router.push({
			// 	name: 'pricing-policies-item',
			// 	params: { id },
			// });
		},
		async changePagination(data){
			const { page, sizeChunk } = data;

			const res = await this.getPriceList({
				pagination: {
					page,
					perPage: sizeChunk
				}
			});

			// Очищаем сортировку столбцов
			// for(let item of this.colConfigList){
			// 	item.order = '';
			// }
		},
	},
	async created() {
		await this.getBatchFiltersData();
		this.initViewData();
	},
}