import { mapState, mapActions, mapWritableState } from 'pinia';
import { useFiltersStore } from '@/app/stores/filters-store';
import { usePricingDetailStore } from '@/app/stores/pricing-policies/pricing-detail-store';

import helper from '@/app/utils/helper';

import BsBtn from "@/app/components/controls/buttons/bs-btn/BsBtn.vue";
import BsSelectV2 from '@/app/components/controls/selects/bs-select-v2/BsSelectV2.vue';
import BsSingleSelect from '@/app/components/controls/selects/bs-single-select/BsSingleSelect.vue';
import BsInput from '@/app/components/controls/bs-input/BsInput.vue';

export default {
	name: 'pricing-filter',
	components: {
		BsBtn, BsSelectV2, BsSingleSelect,
		BsInput,
	},
	data() {
		return {
			fragmentingPeriodOption: [
				{
					value: true,
					label: 'Да',
				},
				{
					value: false,
					label: 'Нет',
				}
			],
			currentMonthOptions: [
				{
					value: true,
					label: 'Текущий',
				},
				{
					value: false,
					label: 'Обычный',
				}
			],
			compareOptions: [
				// {
				// 	label: '=',
				// 	value: '='
				// },
				{
					label: '<',
					value: '<'
				},
				{
					label: '>',
					value: '>'
				},
			],
			gidOptions: [],
		}
	},
	computed: {
		...mapWritableState(usePricingDetailStore, ['filterData']),
	},
	methods: {
		...mapActions(useFiltersStore, ['getOptions']),
		...mapActions(usePricingDetailStore, ['clearFilters', 'getRuleList', 'findSideRimGids']),

		searchGids(query){
			if(query.length < 3) return;
			console.log(query);

			helper.debounce({
				keyTimer:'search-query-sides',
				duration: 300,
				action: async ()=>{
					const res = await this.findSideRimGids(query);
					if(!res?.rimGids?.length) return;

					this.gidOptions = res.rimGids.map(item=>{
						return {
							value: item,
							label: item
						};
					});
				}
			});
		},

		submit(){
			const { params } = this.$route ?? {};
			this.getRuleList({ pricePolicyId: params?.id });
		},

		clear(){
			const { params } = this.$route ?? {};

			this.clearFilters();
			this.getRuleList({ pricePolicyId: params?.id });
		}
	},
}