import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, Transition as _Transition, Fragment as _Fragment, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "map-design-info" }
const _hoisted_2 = {
  key: 0,
  class: "map-design-info__container"
}
const _hoisted_3 = { class: "map-design-info__block type-head" }
const _hoisted_4 = {
  key: 0,
  class: "map-design-info__photo-slider"
}
const _hoisted_5 = { class: "map-design-info__group" }
const _hoisted_6 = { class: "map-design-info__head" }
const _hoisted_7 = { class: "map-design-info__head-title" }
const _hoisted_8 = { class: "map-design-info__head-actions" }
const _hoisted_9 = {
  class: "map-design-info__links-item",
  title: "Обновить данные РК"
}
const _hoisted_10 = { class: "map-design-info__inner-id" }
const _hoisted_11 = { class: "map-design-info__group group-address" }
const _hoisted_12 = { class: "map-design-info__group-value" }
const _hoisted_13 = {
  key: 1,
  class: "map-design-info__group-value"
}
const _hoisted_14 = { class: "map-design-info__block" }
const _hoisted_15 = { class: "map-design-info__group group-row" }
const _hoisted_16 = { class: "map-design-info__group" }
const _hoisted_17 = { class: "map-design-info__group-value" }
const _hoisted_18 = { class: "map-design-info__group" }
const _hoisted_19 = { class: "map-design-info__group-value" }
const _hoisted_20 = { class: "map-design-info__block" }
const _hoisted_21 = { class: "map-design-info__group group-row" }
const _hoisted_22 = { class: "map-design-info__group" }
const _hoisted_23 = { class: "map-design-info__group-value" }
const _hoisted_24 = { class: "map-design-info__group" }
const _hoisted_25 = { class: "map-design-info__group-value" }
const _hoisted_26 = { class: "map-design-info__block" }
const _hoisted_27 = { class: "map-design-info__group" }
const _hoisted_28 = { class: "map-design-info__side-select" }
const _hoisted_29 = {
  key: 0,
  class: "map-design-info__block"
}
const _hoisted_30 = { class: "map-design-info__group group-row row-gap-32" }
const _hoisted_31 = { class: "map-design-info__group" }
const _hoisted_32 = { class: "map-design-info__group-value" }
const _hoisted_33 = { class: "map-design-info__group" }
const _hoisted_34 = { class: "map-design-info__group-value" }
const _hoisted_35 = { class: "map-design-info__group" }
const _hoisted_36 = { class: "map-design-info__group-value" }
const _hoisted_37 = {
  key: 1,
  class: "map-design-info__block"
}
const _hoisted_38 = { class: "map-design-info__group group-row" }
const _hoisted_39 = { class: "map-design-info__group" }
const _hoisted_40 = { class: "map-design-info__group-value" }
const _hoisted_41 = { class: "map-design-info__group" }
const _hoisted_42 = { class: "map-design-info__group-value" }
const _hoisted_43 = { class: "map-design-info__block" }
const _hoisted_44 = { class: "map-design-info__group group-row" }
const _hoisted_45 = { class: "map-design-info__group" }
const _hoisted_46 = { class: "map-design-info__group-value" }
const _hoisted_47 = { key: 0 }
const _hoisted_48 = { key: 1 }
const _hoisted_49 = { class: "map-design-info__group" }
const _hoisted_50 = { class: "map-design-info__group-value" }
const _hoisted_51 = { key: 0 }
const _hoisted_52 = { key: 1 }
const _hoisted_53 = {
  key: 0,
  class: "map-design-info__group"
}
const _hoisted_54 = { class: "map-design-info__group-value" }
const _hoisted_55 = {
  key: 2,
  class: "map-design-info__block"
}
const _hoisted_56 = { class: "map-design-info__group group-row" }
const _hoisted_57 = { class: "map-design-info__group" }
const _hoisted_58 = { class: "map-design-info__group-value" }
const _hoisted_59 = { class: "map-design-info__group" }
const _hoisted_60 = { class: "map-design-info__group-value" }
const _hoisted_61 = {
  key: 3,
  class: "map-design-info__block"
}
const _hoisted_62 = { class: "control" }
const _hoisted_63 = { class: "control__group group-row" }
const _hoisted_64 = { class: "control full-free-width" }
const _hoisted_65 = { class: "control__group" }
const _hoisted_66 = { class: "control full-free-width" }
const _hoisted_67 = { class: "control__group" }
const _hoisted_68 = {
  key: 0,
  class: "map-design-info__block block-side-queue"
}
const _hoisted_69 = { class: "map-design-info__group" }
const _hoisted_70 = { class: "map-design-info__side-queue" }
const _hoisted_71 = { class: "map-design-info__block" }
const _hoisted_72 = { class: "map-design-info__employ-info" }
const _hoisted_73 = {
  key: 0,
  class: "map-design-info__block"
}
const _hoisted_74 = { class: "map-design-info__group group-row" }
const _hoisted_75 = {
  key: 0,
  class: "map-design-info__employ-calendar"
}
const _hoisted_76 = {
  key: 1,
  class: "map-design-info__employ-calendar"
}
const _hoisted_77 = { class: "map-design-info__block" }
const _hoisted_78 = { class: "map-design-info__group group-row" }
const _hoisted_79 = { class: "map-design-info__group" }
const _hoisted_80 = { class: "map-design-info__group-value" }
const _hoisted_81 = { class: "map-design-info__group" }
const _hoisted_82 = { class: "map-design-info__group-value" }
const _hoisted_83 = { class: "map-design-info__block" }
const _hoisted_84 = { class: "map-design-info__group" }
const _hoisted_85 = { class: "map-design-info__group-value" }
const _hoisted_86 = { class: "map-design-info__block" }
const _hoisted_87 = { class: "map-design-info__group group-row" }
const _hoisted_88 = { class: "map-design-info__group" }
const _hoisted_89 = { class: "map-design-info__group-value" }
const _hoisted_90 = { class: "map-design-info__group" }
const _hoisted_91 = { class: "map-design-info__group-value" }
const _hoisted_92 = { class: "map-design-info__block" }
const _hoisted_93 = { class: "map-design-info__group group-row" }
const _hoisted_94 = { class: "map-design-info__group" }
const _hoisted_95 = { class: "map-design-info__group-value" }
const _hoisted_96 = { class: "map-design-info__group" }
const _hoisted_97 = { class: "map-design-info__group-value" }
const _hoisted_98 = { class: "map-design-info__block" }
const _hoisted_99 = { class: "map-design-info__group group-row row-gap-32" }
const _hoisted_100 = { class: "map-design-info__group" }
const _hoisted_101 = { class: "map-design-info__group-value" }
const _hoisted_102 = { class: "map-design-info__group" }
const _hoisted_103 = { class: "map-design-info__group-value" }
const _hoisted_104 = { class: "map-design-info__block" }
const _hoisted_105 = { class: "map-design-info__group" }
const _hoisted_106 = { class: "map-design-info__group-value" }
const _hoisted_107 = { class: "map-design-info__block" }
const _hoisted_108 = { class: "map-design-info__group group-row row-gap-32" }
const _hoisted_109 = { class: "map-design-info__group" }
const _hoisted_110 = { class: "map-design-info__group-value" }
const _hoisted_111 = { class: "map-design-info__group" }
const _hoisted_112 = { class: "map-design-info__group-value" }
const _hoisted_113 = { class: "map-design-info__block" }
const _hoisted_114 = { class: "map-design-info__group" }
const _hoisted_115 = { class: "map-design-info__group-value" }
const _hoisted_116 = {
  key: 6,
  class: "map-design-info__block"
}
const _hoisted_117 = { class: "map-design-info__group" }
const _hoisted_118 = { class: "map-design-info__tag-list" }
const _hoisted_119 = { class: "map-design-info__block block-action-btns" }
const _hoisted_120 = { class: "control" }
const _hoisted_121 = { class: "control__group group-row" }
const _hoisted_122 = { class: "control full-free-width" }
const _hoisted_123 = { class: "control full-free-width" }
const _hoisted_124 = {
  key: 1,
  class: "map-design-info__empty"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsPhotoSlider = _resolveComponent("BsPhotoSlider")
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_ElTooltip = _resolveComponent("ElTooltip")
  const _component_SideSelect = _resolveComponent("SideSelect")
  const _component_BsSingleSelect = _resolveComponent("BsSingleSelect")
  const _component_SideQueueV2 = _resolveComponent("SideQueueV2")
  const _component_EmployInfo = _resolveComponent("EmployInfo")
  const _component_EmployDatepicker = _resolveComponent("EmployDatepicker")
  const _component_TagList = _resolveComponent("TagList")
  const _component_BsBtn = _resolveComponent("BsBtn")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.detailDesignData)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_ctx.side.photoList?.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_BsPhotoSlider, {
                    list: _ctx.side.photoList
                  }, null, 8 /* PROPS */, ["list"])
                ]))
              : _createCommentVNode("v-if", true),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.side.innerId), 1 /* TEXT */),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", {
                    class: "map-design-info__links-item link-open",
                    title: "Показать на карте",
                    onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.clickCenterMap && _ctx.clickCenterMap(...args)))
                  }, [
                    _createVNode(_component_SvgIcon, { name: "12-open" })
                  ]),
                  _createElementVNode("div", {
                    class: "map-design-info__links-item",
                    title: "Открыть тех.требования",
                    onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.openTechReq && _ctx.openTechReq(...args)))
                  }, [
                    _createVNode(_component_SvgIcon, { name: "16-collection" })
                  ]),
                  _createElementVNode("div", {
                    class: "map-design-info__links-item",
                    title: "Копировать ссылку",
                    onClick: _cache[2] || (_cache[2] = (...args) => (_ctx.copyLink && _ctx.copyLink(...args)))
                  }, [
                    _createVNode(_component_SvgIcon, { name: "16-share" })
                  ]),
                  _createElementVNode("div", _hoisted_9, [
                    _createVNode(_component_SvgIcon, {
                      name: "16-refresh",
                      onClick: _ctx.clickRefresh
                    }, null, 8 /* PROPS */, ["onClick"])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.side.gid), 1 /* TEXT */),
              _createElementVNode("div", _hoisted_11, [
                (_ctx.detailDesignData?.address?.length > 36)
                  ? (_openBlock(), _createBlock(_component_ElTooltip, {
                      key: 0,
                      content: _ctx.detailDesignData.address,
                      placement: "top-start"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.detailDesignData.address), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["content"]))
                  : (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.detailDesignData.address), 1 /* TEXT */))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _cache[11] || (_cache[11] = _createElementVNode("div", { class: "map-design-info__group-label" }, "Категория РК", -1 /* HOISTED */)),
                _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.detailDesignData?.structureCategory || '–'), 1 /* TEXT */)
              ]),
              _createElementVNode("div", _hoisted_18, [
                _cache[12] || (_cache[12] = _createElementVNode("div", { class: "map-design-info__group-label" }, "ESPAR ID", -1 /* HOISTED */)),
                _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.side?.esparId || '–'), 1 /* TEXT */)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("div", _hoisted_22, [
                _cache[13] || (_cache[13] = _createElementVNode("div", { class: "map-design-info__group-label" }, "Тип РК", -1 /* HOISTED */)),
                _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.detailDesignData?.constructType || '–'), 1 /* TEXT */)
              ]),
              _createElementVNode("div", _hoisted_24, [
                _cache[14] || (_cache[14] = _createElementVNode("div", { class: "map-design-info__group-label" }, "Сервисный статус", -1 /* HOISTED */)),
                _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.side?.serviceStatus || '–'), 1 /* TEXT */)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("div", _hoisted_28, [
                _createVNode(_component_SideSelect, {
                  modelValue: _ctx.sideSelectData.value,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.sideSelectData.value) = $event)),
                  options: _ctx.sideSelectData.options,
                  onChange: _cache[4] || (_cache[4] = () => {})
                }, null, 8 /* PROPS */, ["modelValue", "options"])
              ])
            ])
          ]),
          (!_ctx.side.digitalData)
            ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                _createElementVNode("div", _hoisted_30, [
                  _createElementVNode("div", _hoisted_31, [
                    _cache[15] || (_cache[15] = _createElementVNode("div", { class: "map-design-info__group-label" }, "Тип механизма РС", -1 /* HOISTED */)),
                    _createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.side.sideMech), 1 /* TEXT */)
                  ]),
                  _createElementVNode("div", _hoisted_33, [
                    _cache[16] || (_cache[16] = _createElementVNode("div", { class: "map-design-info__group-label" }, "Размер РС", -1 /* HOISTED */)),
                    _createElementVNode("div", _hoisted_34, _toDisplayString(_ctx.side.sideSize), 1 /* TEXT */)
                  ]),
                  _createElementVNode("div", _hoisted_35, [
                    _cache[17] || (_cache[17] = _createElementVNode("div", { class: "map-design-info__group-label" }, "Время работы", -1 /* HOISTED */)),
                    _createElementVNode("div", _hoisted_36, _toDisplayString(_ctx.side?.workingHours || '–'), 1 /* TEXT */)
                  ])
                ])
              ]))
            : _createCommentVNode("v-if", true),
          (!_ctx.side.digitalData)
            ? (_openBlock(), _createElementBlock("div", _hoisted_37, [
                _createElementVNode("div", _hoisted_38, [
                  _createElementVNode("div", _hoisted_39, [
                    _cache[18] || (_cache[18] = _createElementVNode("div", { class: "map-design-info__group-label" }, "Материал", -1 /* HOISTED */)),
                    _createElementVNode("div", _hoisted_40, _toDisplayString(_ctx.side.staticMaterial), 1 /* TEXT */)
                  ]),
                  _createElementVNode("div", _hoisted_41, [
                    _cache[19] || (_cache[19] = _createElementVNode("div", { class: "map-design-info__group-label" }, "НДС", -1 /* HOISTED */)),
                    _createElementVNode("div", _hoisted_42, _toDisplayString(_ctx.side.vat), 1 /* TEXT */)
                  ])
                ])
              ]))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", _hoisted_43, [
            _createElementVNode("div", _hoisted_44, [
              _createElementVNode("div", _hoisted_45, [
                _cache[20] || (_cache[20] = _createElementVNode("div", { class: "map-design-info__group-label" }, "GRP", -1 /* HOISTED */)),
                _createElementVNode("div", _hoisted_46, [
                  (_ctx.side?.grp  && Number(_ctx.side.grp))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_47, _toDisplayString(_ctx.side.grp), 1 /* TEXT */))
                    : (_openBlock(), _createElementBlock("span", _hoisted_48, "–"))
                ])
              ]),
              _createElementVNode("div", _hoisted_49, [
                _cache[21] || (_cache[21] = _createElementVNode("div", { class: "map-design-info__group-label" }, "OTS", -1 /* HOISTED */)),
                _createElementVNode("div", _hoisted_50, [
                  (_ctx.side?.ots && Number(_ctx.side.ots))
                    ? (_openBlock(), _createElementBlock("span", _hoisted_51, _toDisplayString(_ctx.side.ots), 1 /* TEXT */))
                    : (_openBlock(), _createElementBlock("span", _hoisted_52, "–"))
                ])
              ]),
              (_ctx.side.digitalData)
                ? (_openBlock(), _createElementBlock("div", _hoisted_53, [
                    _cache[22] || (_cache[22] = _createElementVNode("div", { class: "map-design-info__group-label" }, "НДС", -1 /* HOISTED */)),
                    _createElementVNode("div", _hoisted_54, _toDisplayString(_ctx.side.vat), 1 /* TEXT */)
                  ]))
                : _createCommentVNode("v-if", true)
            ])
          ]),
          (_ctx.side.digitalData)
            ? (_openBlock(), _createElementBlock("div", _hoisted_55, [
                _createElementVNode("div", _hoisted_56, [
                  _createElementVNode("div", _hoisted_57, [
                    _cache[23] || (_cache[23] = _createElementVNode("div", { class: "map-design-info__group-label" }, "WiFi ловушка", -1 /* HOISTED */)),
                    _createElementVNode("div", _hoisted_58, _toDisplayString(_ctx.side?.wifiTrap || '–'), 1 /* TEXT */)
                  ]),
                  _createElementVNode("div", _hoisted_59, [
                    _cache[24] || (_cache[24] = _createElementVNode("div", { class: "map-design-info__group-label" }, "Блок/слот", -1 /* HOISTED */)),
                    _createElementVNode("div", _hoisted_60, _toDisplayString(_ctx.side.digitalData.blockDuration) + " сек/" + _toDisplayString(_ctx.side.digitalData.slotDuration) + " сек", 1 /* TEXT */)
                  ])
                ])
              ]))
            : _createCommentVNode("v-if", true),
          (_ctx.side.digitalData)
            ? (_openBlock(), _createElementBlock("div", _hoisted_61, [
                _createElementVNode("div", _hoisted_62, [
                  _createElementVNode("div", _hoisted_63, [
                    _createElementVNode("div", _hoisted_64, [
                      _cache[25] || (_cache[25] = _createElementVNode("div", { class: "control__label" }, "Длина ролика", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_65, [
                        _createVNode(_component_BsSingleSelect, {
                          placeholder: "Выберите количество секунд",
                          modelValue: _ctx.side.digitalDuration.value,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => ((_ctx.side.digitalDuration.value) = $event)),
                          options: _ctx.side.digitalDuration.options,
                          clearable: true,
                          filterable: true
                        }, null, 8 /* PROPS */, ["modelValue", "options"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_66, [
                      _cache[26] || (_cache[26] = _createElementVNode("div", { class: "control__label" }, "Частота выходов", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_67, [
                        _createVNode(_component_BsSingleSelect, {
                          placeholder: "Любая",
                          modelValue: _ctx.side.outFrequency.value,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.side.outFrequency.value) = $event)),
                          options: _ctx.side.outFrequency.options,
                          clearable: true,
                          filterable: true
                        }, null, 8 /* PROPS */, ["modelValue", "options"])
                      ])
                    ])
                  ])
                ])
              ]))
            : _createCommentVNode("v-if", true),
          _createVNode(_Transition, {
            name: "el-fade-in",
            mode: "out-in"
          }, {
            default: _withCtx(() => [
              (_ctx.queueData)
                ? (_openBlock(), _createElementBlock("div", _hoisted_68, [
                    _createElementVNode("div", _hoisted_69, [
                      _cache[27] || (_cache[27] = _createElementVNode("div", { class: "map-design-info__group-label" }, "Очередь", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_70, [
                        _createVNode(_component_SideQueueV2, {
                          data: _ctx.queueData,
                          sideInfo: _ctx.sideInfo,
                          showActionMonth: false
                        }, null, 8 /* PROPS */, ["data", "sideInfo"])
                      ])
                    ])
                  ]))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }),
          _createElementVNode("div", _hoisted_71, [
            _createElementVNode("div", _hoisted_72, [
              (_ctx.side.digitalData)
                ? (_openBlock(), _createBlock(_component_EmployInfo, {
                    key: 0,
                    type: "digital",
                    checkMonthDate: _ctx.detailDesignData.checkMonthDate,
                    "onUpdate:checkMonthDate": _cache[7] || (_cache[7] = $event => ((_ctx.detailDesignData.checkMonthDate) = $event)),
                    isDblMode: true,
                    period: _ctx.detailDesignData.period,
                    data: { digitalOccupancy: _ctx.digitalOccupancy },
                    sideData: _ctx.side,
                    isShowCalendar: true
                  }, null, 8 /* PROPS */, ["checkMonthDate", "period", "data", "sideData"]))
                : (_openBlock(), _createBlock(_component_EmployInfo, {
                    key: 1,
                    type: "static",
                    checkMonthDate: _ctx.detailDesignData.checkMonthDate,
                    "onUpdate:checkMonthDate": _cache[8] || (_cache[8] = $event => ((_ctx.detailDesignData.checkMonthDate) = $event)),
                    isDblMode: true,
                    period: _ctx.detailDesignData.period,
                    data: { staticOccupancy: _ctx.staticOccupancy },
                    sideData: _ctx.side,
                    isShowCalendar: true
                  }, null, 8 /* PROPS */, ["checkMonthDate", "period", "data", "sideData"]))
            ])
          ]),
          _createVNode(_Transition, {
            name: "el-fade-in",
            mode: "out-in"
          }, {
            default: _withCtx(() => [
              (_ctx.detailDesignData.checkMonthDate)
                ? (_openBlock(), _createElementBlock("div", _hoisted_73, [
                    _createElementVNode("div", _hoisted_74, [
                      (_ctx.side.digitalData)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_75, [
                            _createVNode(_component_EmployDatepicker, {
                              selectedPeriods: _ctx.side.selectedPeriods,
                              "onUpdate:selectedPeriods": _cache[9] || (_cache[9] = $event => ((_ctx.side.selectedPeriods) = $event)),
                              date: _ctx.detailDesignData.checkMonthDate,
                              calendar: _ctx.calendar
                            }, null, 8 /* PROPS */, ["selectedPeriods", "date", "calendar"])
                          ]))
                        : (_openBlock(), _createElementBlock("div", _hoisted_76, [
                            _createVNode(_component_EmployDatepicker, {
                              selectedPeriods: _ctx.side.selectedPeriods,
                              "onUpdate:selectedPeriods": _cache[10] || (_cache[10] = $event => ((_ctx.side.selectedPeriods) = $event)),
                              date: _ctx.detailDesignData.checkMonthDate,
                              calendar: _ctx.calendar
                            }, null, 8 /* PROPS */, ["selectedPeriods", "date", "calendar"])
                          ]))
                    ])
                  ]))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          }),
          (!_ctx.side.digitalData)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 4 }, [
                _createElementVNode("div", _hoisted_77, [
                  _createElementVNode("div", _hoisted_78, [
                    _createElementVNode("div", _hoisted_79, [
                      _cache[28] || (_cache[28] = _createElementVNode("div", { class: "map-design-info__group-label" }, "Прайс в месяц", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_80, _toDisplayString(_ctx.getMoney(_ctx.side.priceMonth)) + "₽", 1 /* TEXT */)
                    ]),
                    _createElementVNode("div", _hoisted_81, [
                      _cache[29] || (_cache[29] = _createElementVNode("div", { class: "map-design-info__group-label" }, "Прайс за период", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_82, _toDisplayString(_ctx.getMoney(_ctx.pricePeriod)) + "₽", 1 /* TEXT */)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_83, [
                  _createElementVNode("div", _hoisted_84, [
                    _cache[30] || (_cache[30] = _createElementVNode("div", { class: "map-design-info__group-label" }, "Дата окончания разрешения", -1 /* HOISTED */)),
                    _createElementVNode("div", _hoisted_85, _toDisplayString(_ctx.contractEndDate), 1 /* TEXT */)
                  ])
                ]),
                _createElementVNode("div", _hoisted_86, [
                  _createElementVNode("div", _hoisted_87, [
                    _createElementVNode("div", _hoisted_88, [
                      _cache[31] || (_cache[31] = _createElementVNode("div", { class: "map-design-info__group-label" }, "Прайс печать Локалы", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_89, _toDisplayString(_ctx.getMoney(_ctx.side.pricePrint)) + "₽", 1 /* TEXT */)
                    ]),
                    _createElementVNode("div", _hoisted_90, [
                      _cache[32] || (_cache[32] = _createElementVNode("div", { class: "map-design-info__group-label" }, "Прайс печать ФРА", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_91, _toDisplayString(_ctx.getMoney(_ctx.side.printPriceFra)) + "₽", 1 /* TEXT */)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_92, [
                  _createElementVNode("div", _hoisted_93, [
                    _createElementVNode("div", _hoisted_94, [
                      _cache[33] || (_cache[33] = _createElementVNode("div", { class: "map-design-info__group-label" }, "Прайс монтаж Локалы", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_95, _toDisplayString(_ctx.getMoney(_ctx.side.priceInstallationLocal)) + "₽", 1 /* TEXT */)
                    ]),
                    _createElementVNode("div", _hoisted_96, [
                      _cache[34] || (_cache[34] = _createElementVNode("div", { class: "map-design-info__group-label" }, "Прайс монтаж ФРА", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_97, _toDisplayString(_ctx.getMoney(_ctx.side.priceInstallationFra)) + "₽", 1 /* TEXT */)
                    ])
                  ])
                ])
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          (_ctx.side.digitalData)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                _createElementVNode("div", _hoisted_98, [
                  _createElementVNode("div", _hoisted_99, [
                    _createElementVNode("div", _hoisted_100, [
                      _cache[35] || (_cache[35] = _createElementVNode("div", { class: "map-design-info__group-label" }, "Прайс в месяц", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_101, _toDisplayString(_ctx.getMoney(_ctx.side.priceMonth)) + "₽", 1 /* TEXT */)
                    ]),
                    _createElementVNode("div", _hoisted_102, [
                      _cache[36] || (_cache[36] = _createElementVNode("div", { class: "map-design-info__group-label" }, "Прайс за период", -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_103, _toDisplayString(_ctx.getMoney(_ctx.pricePeriod)) + "₽", 1 /* TEXT */)
                    ])
                  ])
                ]),
                _cache[37] || (_cache[37] = _createElementVNode("div", { class: "map-design-info__block" }, [
                  _createElementVNode("div", { class: "map-design-info__group" }, [
                    _createElementVNode("div", { class: "map-design-info__group-label" }, "Дата окончания разрешения"),
                    _createElementVNode("div", { class: "map-design-info__group-value" }, "27.02.2024")
                  ])
                ], -1 /* HOISTED */))
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", _hoisted_104, [
            _createElementVNode("div", _hoisted_105, [
              _cache[38] || (_cache[38] = _createElementVNode("div", { class: "map-design-info__group-label" }, "Тип сети", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_106, _toDisplayString(_ctx.side.network), 1 /* TEXT */)
            ])
          ]),
          _createElementVNode("div", _hoisted_107, [
            _createElementVNode("div", _hoisted_108, [
              _createElementVNode("div", _hoisted_109, [
                _cache[39] || (_cache[39] = _createElementVNode("div", { class: "map-design-info__group-label" }, "Оператор", -1 /* HOISTED */)),
                _createElementVNode("div", _hoisted_110, _toDisplayString(_ctx.side?.operator || '–'), 1 /* TEXT */)
              ]),
              _createElementVNode("div", _hoisted_111, [
                _cache[40] || (_cache[40] = _createElementVNode("div", { class: "map-design-info__group-label" }, "Собственник", -1 /* HOISTED */)),
                _createElementVNode("div", _hoisted_112, _toDisplayString(_ctx.side?.owner || '–'), 1 /* TEXT */)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_113, [
            _createElementVNode("div", _hoisted_114, [
              _cache[41] || (_cache[41] = _createElementVNode("div", { class: "map-design-info__group-label" }, "Технический комментарий:", -1 /* HOISTED */)),
              _createElementVNode("div", _hoisted_115, _toDisplayString(_ctx.side?.techComment || '–'), 1 /* TEXT */)
            ])
          ]),
          (_ctx.side.tagList?.length)
            ? (_openBlock(), _createElementBlock("div", _hoisted_116, [
                _createElementVNode("div", _hoisted_117, [
                  _cache[42] || (_cache[42] = _createElementVNode("div", { class: "map-design-info__group-label" }, "Теги:", -1 /* HOISTED */)),
                  _createElementVNode("div", _hoisted_118, [
                    _createVNode(_component_TagList, {
                      list: _ctx.side.tagList
                    }, null, 8 /* PROPS */, ["list"])
                  ])
                ])
              ]))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", _hoisted_119, [
            _createElementVNode("div", _hoisted_120, [
              _createElementVNode("div", _hoisted_121, [
                _createElementVNode("div", _hoisted_122, [
                  _createVNode(_component_BsBtn, {
                    type: "primary",
                    tag: "div",
                    onClick: _ctx.addTargetProg
                  }, {
                    default: _withCtx(() => _cache[43] || (_cache[43] = [
                      _createTextVNode("Добавить в АП")
                    ])),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["onClick"])
                ]),
                _createElementVNode("div", _hoisted_123, [
                  _createVNode(_component_BsBtn, { tag: "div" }, {
                    default: _withCtx(() => _cache[44] || (_cache[44] = [
                      _createTextVNode("Добавить в избранное")
                    ])),
                    _: 1 /* STABLE */
                  })
                ])
              ])
            ])
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_124, "Выберите на карте или в таблице нужную РК"))
  ]))
}