import { mapState, mapActions, mapWritableState } from 'pinia';
import { useDialogStore } from '@/app/stores/dialog-store';
import { useFiltersStore } from '@/app/stores/filters-store';
import { useDetailTargetProgStore } from '@/app/stores/detail-target-prog-store';

import { ElDialog, ElMessageBox, ElMessage,
	ElTabs, ElTabPane, ElForm, ElFormItem } from "element-plus";



import helper from '@/app/utils/helper';

import BsBtn from '@/app/components/controls/buttons/bs-btn/BsBtn.vue';
import BsInput from '@/app/components/controls/bs-input/BsInput.vue';
import BsSingleSelect from '@/app/components/controls/selects/bs-single-select/BsSingleSelect.vue';
import BsSelectV2 from '@/app/components/controls/selects/bs-select-v2/BsSelectV2.vue';

export default {
	name: 'new-targprog-form',
	components: {
		ElDialog,
		ElTabs, ElTabPane,
		ElForm, ElFormItem,
		BsBtn, BsInput, BsSingleSelect, BsSelectV2,
	},
	props:{
		modelValue: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['update:modelValue'],
	data() {
		return {
			isVisible: this.modelValue,
			activeTab: 'main-info',

			rules: {
				nameTargetProgram: [
					{ required: true, message: 'Название должно быть не меньше 3 символов', trigger: 'change' },
					{ min: 3, message: 'Length should be 3', trigger: 'change' },
				],
				// clientType:  [
				// 	{ required: true, message: 'Выберите тип клиента из списка', trigger: 'change' },
				// ],
				clientCategory:  [
					{ required: true, message: 'Выберите категорию клиента из списка', trigger: 'change' },
				],
				typePlacement:  [
					{ required: true, message: 'Выберите тип размещения из списка', trigger: 'change' },
				],
				department: [
					{ required: true, message: 'Выберите подразделение из списка', trigger: 'change' },
				],
			},
		}
	},
	computed: {
		...mapState(useDialogStore, ['adapterTargetProgBack']),
		...mapWritableState(useDialogStore, ['targetProgDialogData', 'targetProgDialog']),
	},
	methods: {
		...mapActions(useDialogStore, [ 'createTargetProgram', 'addTargetProgram' ]),
		...mapActions(useFiltersStore, [ 'getOptions' ]),
		...mapActions(useDetailTargetProgStore, ['getTargetTabData']),

		async cancel(){
			await this.resetForm();

			this.targetProgDialog.data = null;
			this.isVisible = false;
		},
		async createProgram(){
			const { ruleFormRef } = this.$refs;
			console.log('createProgram');

			await ruleFormRef.validate(async (valid, fields) => {
				if (valid) {
					console.log('submit!');
					this.createSubmit();
				} else {
					console.log('error submit!', fields)
					this.errorSubmit(fields);
				}
			});
		},
		async createSubmit(){
			const resCreate = await this.createTargetProgram();
			// console.log(this.targetProgDialogData);
			// return;

			const targetProgId = resCreate.id;
			const { data } = this.targetProgDialog;

			let orders = [ data ];
			if(Array.isArray(data)) orders = [...data];

			const res = await this.addTargetProgram({
				orders,
				target_program_id: targetProgId,
			});
			console.log(res);
			if(!res) return;

			this.getTargetTabData(targetProgId);
			ElMessage({
				type: 'success',
				message: 'РС успешно добавлена',
			});

			this.cancel();
		},
		errorSubmit(fields){
			let errorFields = [];

			for(let [key, value] of Object.entries(fields)){
				const itemFind = this.adapterTargetProgBack.find(item=>item.key==key);
				if(itemFind) errorFields.push(itemFind.name);
			}
			const strFields = errorFields.map( name=>(`<p class="p">- ${name}</p>`) ).join('');

			ElMessageBox.alert(null, null, {
				title: 'Проверьте правильность полей:',
				message: strFields,
				showClose: false,
				dangerouslyUseHTMLString: true,
				customClass: 'new-targprog-form__message-box bs-message-box',
				type: 'error',
			});
		},

		async resetForm(){
			const { ruleFormRef } = this.$refs;

			for(let [key, fieldData] of Object.entries(this.targetProgDialogData)){
				if(['dealId', 'linkDealB24'].includes(key)) this.targetProgDialogData[key] = '';
				else if(key=='ruleForm') for(let [key, value] of Object.entries(fieldData)) fieldData[key] = '';
				else this.targetProgDialogData[key] = '';
			}

			await this.$nextTick();
			await helper.pause(100);
			ruleFormRef.clearValidate();
			console.log('resetForm');
		},
	},
	watch: {
		isVisible(is){
			this.$emit('update:modelValue', is);
			this.activeTab = 'main-info';
		},
		modelValue(is){
			this.isVisible = is;
		}
	},
	created() {

	},
}