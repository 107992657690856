import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pricing-detail-aside" }
const _hoisted_2 = { class: "pricing-detail-aside__loader" }
const _hoisted_3 = { class: "pricing-detail-aside__head" }
const _hoisted_4 = { class: "pricing-detail-aside__head-title" }
const _hoisted_5 = { class: "pricing-detail-aside__head-id" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_LoaderSpinner = _resolveComponent("LoaderSpinner")
  const _component_EditField = _resolveComponent("EditField")
  const _component_PricingSummary = _resolveComponent("PricingSummary")
  const _component_ElTabPane = _resolveComponent("ElTabPane")
  const _component_ElBadge = _resolveComponent("ElBadge")
  const _component_PricingFilter = _resolveComponent("PricingFilter")
  const _component_ElTabs = _resolveComponent("ElTabs")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_Transition, {
      name: "el-fade-in",
      mode: "out-in",
      persisted: ""
    }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_2, [
          _createVNode(_component_LoaderSpinner)
        ], 512 /* NEED_PATCH */), [
          [_vShow, _ctx.isAsideLoader]
        ])
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_EditField, {
          label: _ctx.summaryData?.title ?? '',
          titleDialog: "Название прайсовой политики",
          onChange: _ctx.changeEditField
        }, null, 8 /* PROPS */, ["label", "onChange"])
      ]),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.summaryData?.id || ''), 1 /* TEXT */)
    ]),
    _createVNode(_component_ElTabs, {
      class: "pricing-detail-aside__tabs",
      modelValue: _ctx.activeTab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.activeTab) = $event)),
      onTabClick: _cache[1] || (_cache[1] = () => {})
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ElTabPane, {
          class: "pricing-detail-aside__tab-pane",
          label: "Сводка",
          name: "pricing-summary"
        }, {
          label: _withCtx(() => _cache[2] || (_cache[2] = [
            _createElementVNode("div", { class: "pricing-detail-aside__tab-pane-label" }, "Сводка", -1 /* HOISTED */)
          ])),
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_KeepAlive, null, [
              (_ctx.activeTab=='pricing-summary')
                ? (_openBlock(), _createBlock(_component_PricingSummary, { key: 0 }))
                : _createCommentVNode("v-if", true)
            ], 1024 /* DYNAMIC_SLOTS */))
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_ElTabPane, {
          class: "pricing-detail-aside__tab-pane",
          label: "Фильтр",
          name: "pricing-filter"
        }, {
          label: _withCtx(() => [
            _createVNode(_component_ElBadge, {
              class: "pricing-detail-aside__tab-badge",
              value: _ctx.countFilters,
              hidden: !_ctx.countFilters
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createElementVNode("div", { class: "pricing-detail-aside__tab-pane-label" }, "Фильтр", -1 /* HOISTED */)
              ])),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["value", "hidden"])
          ]),
          default: _withCtx(() => [
            _createVNode(_component_PricingFilter)
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"])
  ]))
}