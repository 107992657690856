import { mapActions, mapState, mapWritableState } from 'pinia';
import { useDetailTargetProgStore } from '@/app/stores/detail-target-prog-store';

import { ElDialog } from "element-plus";


import BsBtn from "@/app/components/controls/buttons/bs-btn/BsBtn.vue";
import BsInput from "@/app/components/controls/bs-input/BsInput.vue";
import BsMultiSelect from "@/app/components/controls/selects/bs-multi-select/BsMultiSelect.vue";

export default {
	name: 'tg-mass-price',
	components: {
		ElDialog,
		BsBtn, BsInput, BsMultiSelect,
	},
	props: {
		visible:{
			type: Boolean,
			default: false,
		},
	},
	emits: ['update:visible', 'change'],
	data() {
		return {
			price: '',
			months: '',
		}
	},
	computed: {
		...mapState( useDetailTargetProgStore, ['detailMonthSelectData'] ),

		isVisible: {
			get(){
				return this.visible;
			},
			set(is){
				this.$emit('update:visible', is);
			},
		},
	},
	methods: {
		clickChange(){
			this.$emit('change', {
				price: Number(this.price),
				months: this.months
			});
			this.isVisible = false;
		},

		inputChange(val){
			const price = Number(val);
			if(price<0) this.price = 0;
		},
	},
	watch: {
		isVisible(is){
			if(is){
				this.months = [ this.detailMonthSelectData.value ];
			} else {
				this.price = '';
				this.months = '';
			}
		}
	},
}