import { columns } from "./table-config";

import TableGrid from "@/app/components/table-grid/TableGrid.vue";
import HeadCellDefault from "@/app/components/table/header/head-cell-default/HeadCellDefault.vue";

import CellDate from '@/app/components/table/cell-types/cell-date/CellDate.vue';
import NtCellUser from "@/app/components/notification/type-cell/nt-cell-user/NtCellUser.vue";
import NtCellMessage from "@/app/components/notification/type-cell/nt-cell-message/NtCellMessage.vue";

export default {
	name: 'NotifCenterTable',
	components: {
		TableGrid, HeadCellDefault,
		CellDate, NtCellUser, NtCellMessage,
	},
	props: {
		tableData: {
			type: Array,
			default: [],
		}
	},
	data() {
		return {
			columns,
			selectedRows: [],
		}
	},
	methods: {
		changeSelectedRows(list){
			console.log('changeSelectedRows', list);
			this.selectedRows = list;
		},
		dblClickRow(rowData){
			console.log('dblClickRow', rowData);
		},
		sortTable(order, key){
			// if(!order) return;
			console.log({order, key});
			// this.sortData = { order, key };
			// this.setQueryUrlSort(order, key);
		},
	},
}