import { mapState, mapActions, mapWritableState } from 'pinia';
import { useMapDesignStore } from '@/app/stores/map-design-store';

import helper from "@/app/utils/helper";

import SvgIcon from "@/app/components/svg-icon/SvgIcon.vue";

/*
{
	"sideId": 3,
	"orderId": 5,
	"rimGid": "234",
	"partnerGid": "234",
	"sideCode": "A",
	"sideType": "статика",
	"address": "улица Попова, 25",
	"grp": "0.00",
	"ots": "0.00",
	"sideDirection": "A",
	"structureType": "Щит 6х3",
	"city": "Барнаул",
	"price": 59900,
	"canDelete": false,
	"discount": 65,
	"isLighted": false
}
*/

export default {
	name: 'dstr-side-item',
	components: {
		SvgIcon,
	},
	props: {
		data: {
			type: Object,
			default: {},
		},
	},
	emits: ['remove','openMap'],
	computed: {
		fullAddress(){
			const { city, address } = this.data ?? {};
			let cityStr = '';
			let addressStr = '';

			if(city) cityStr = `г.${city},`;
			if(address) addressStr = address;

			return `${cityStr} ${addressStr}`.trim();
		}
	},
	methods: {
		...mapActions( useMapDesignStore, ['getTypeDesignIcon']),

		getMoney: helper.getMoney,
		remove(){
			this.$emit('remove', this.data);
		},
		openMap(){
			const { sideId, cityId, structureId } = this.data ?? {};

			this.$emit('openMap', { sideId, cityId, structureId });
		}
	},
}