import { mapState, mapActions, mapWritableState } from 'pinia';
import { useMapDesignStore } from "@/app/stores/map-design-store";
import { useDesignFilterStore } from '@/app/stores/design-filter-store';
import { useDetailTargetProgStore } from '@/app/stores/detail-target-prog-store';

import { ElTabs, ElTabPane } from 'element-plus';


import LoaderSpinner from '@/app/components/loaders/loader-spinner/LoaderSpinner.vue';
import DesignFilter from '@/app/components/design-filter/DesignFilter.vue';
import MapDesignInfo from '@/app/components/map/map-design-info/MapDesignInfo.vue';
import DesignTargetInfo from '@/app/components/designs-comp/aside/design-target-info/DesignTargetInfo.vue';

export default {
	name: 'design-aside',
	components: {
		ElTabs, ElTabPane,
		LoaderSpinner,
		DesignFilter, MapDesignInfo, DesignTargetInfo,
	},
	data() {
		return {
			activeTab: 'design-filter',
			// activeTab: 'map-design-info',
			// activeTab: 'target-info',

		}
	},
	computed: {
		...mapState(useMapDesignStore, ['detailDesignData'] ),
		...mapState(useDesignFilterStore, ['isFilterDataLoad'] ),
		...mapState(useDetailTargetProgStore, ['targetTabData']),

		designInfoLabel(){
			if(this.detailDesignData?.gid) return `РК(${this.detailDesignData.gid})`;
			return 'РК';
		},
		targetInfoLabel(){
			if(this.targetTabData?.id) return `АП (${this.targetTabData.id})`;
			return 'АП';
		}
	},
	methods: {
		...mapActions( useDetailTargetProgStore, ['getTargetTabData'] ),
	},
	watch: {
		designInfoLabel(){
			this.activeTab = 'map-design-info';
		},
		targetInfoLabel(){
			this.activeTab = 'target-info';
		}
	},
	created() {
		// this.getTargetTabData(1773);
	},
}