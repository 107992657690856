import dayjs from 'dayjs';

export default {
	computed: {
		date(){
			const { createAt } = this.data;
			let date = createAt;

			if(!date) date = new Date();
			let curFormatDate = dayjs(date).format('YYYY-MM-DD');
			let todayFormat = dayjs().format('YYYY-MM-DD');

			console.log({curFormatDate, todayFormat, date});
			if(todayFormat==curFormatDate) return dayjs(date).format('HH:mm');
			else return dayjs(date).format('DD.MM.YYYY');
		}
	},
	methods: {
		goToTargetProgram(){
			const { targetProgramId } = this.data;
			if(targetProgramId) window.open(`/target-programs/${targetProgramId}`, '_blank');
		}
	},
}