import { mapState, mapActions, mapWritableState } from 'pinia';
import { useFiltersStore } from '@/app/stores/filters-store';
import { usePricingDetailStore } from '@/app/stores/pricing-policies/pricing-detail-store';

import helper from '@/app/utils/helper';

import SvgIcon from "@/app/components/svg-icon/SvgIcon.vue";
import BsSelectV2 from "@/app/components/controls/selects/bs-select-v2/BsSelectV2.vue";
import BsInput from "@/app/components/controls/bs-input/BsInput.vue";

export default {
	name: 'pr-rules-edit-item',
	components: {
		BsSelectV2, SvgIcon, BsInput,
	},
	props: {
		data: {
			type: Object,
			default: {
				// key: 'customer',
				// keyBackOptions: '',
				// label: 'Отдел пользователя',
				// type: 'single-select',
				// placeholder: 'Все',
				// value: [] | '',
			},
		}
	},
	emits: ['remove'],
	data() {
		return {
			gidOptions: [],
		}
	},
	computed: {
		selectOptions(){
			const { key, keyBackOptions } = this.data;
			if(keyBackOptions) return this.getOptions(keyBackOptions);

			return [];
		},
	},
	methods: {
		...mapActions(useFiltersStore, ['getOptions']),
		...mapActions(usePricingDetailStore, ['findSideRimGids']),

		removeRule(){
			this.$emit('remove', this.data.key);
		},

		searchGids(query){
			if(query.length < 3) return;
			// console.log(query);

			helper.debounce({
				keyTimer:'search-query-sides',
				duration: 300,
				action: async ()=>{
					const res = await this.findSideRimGids(query);
					if(!res?.rimGids?.length) return;

					this.gidOptions = res.rimGids.map(item=>{
						return {
							value: item,
							label: item
						};
					});
				}
			});
		},
	},
}