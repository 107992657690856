const typeList = [
	{
		key: 'department',
		keyBackOptions: 'department',
		label: 'Отдел пользователя',
		type: 'multi-select',
		placeholder: 'Все',
	},
	{
		key: 'userRole',
		keyBackOptions: 'userRoles',
		label: 'Роль пользователя',
		type: 'multi-select',
		placeholder: 'Все',
	},
	{
		key: 'user',
		keyBackOptions: 'managers',
		label: 'Пользователь',
		type: 'multi-select',
		placeholder: 'Все',
	},
	{
		key: 'customer',
		keyBackOptions: 'customer',
		label: 'Клиент',
		type: 'multi-select',
		placeholder: 'Все',
	},
	{
		key: 'customerLegalAddService',
		keyBackOptions: 'customer_legal_entity',
		label: 'Юр. лицо доп. услуг',
		type: 'multi-select',
		placeholder: 'Все',
	},
	{
		key: 'agency',
		keyBackOptions: 'agency',
		label: 'Агентство',
		type: 'multi-select',
		placeholder: 'Все',
	},
	// {
	// 	key: 'customerCategory',
	// 	keyBackOptions: 'customer_category',
	// 	label: 'Категория клиента',
	// 	type: 'multi-select',
	// 	placeholder: 'Все',
	// },
	{
		key: 'city',
		keyBackOptions: 'city',
		label: 'Города РС',
		type: 'multi-select',
		placeholder: 'Все',
	},
	{
		key: 'structureCategory',
		keyBackOptions: 'structureCategories',
		label: 'Категория РК',
		type: 'multi-select',
		placeholder: 'Все',
	},
	{
		key: 'serviceStatus',
		keyBackOptions: 'serviceStatus',
		label: 'Сервисный статус',
		type: 'multi-select',
		placeholder: 'Все',
	},
	{
		key: 'structureType',
		keyBackOptions: 'structureType',
		label: 'Тип РК',
		type: 'multi-select',
		placeholder: 'Все',
	},
	{
		key: 'sideDirection',
		keyBackOptions: 'direction',
		label: 'Сторона',
		type: 'multi-select',
		placeholder: 'Все',
	},
	{
		key: 'sideSize',
		keyBackOptions: 'size',
		label: 'Размер РС',
		type: 'multi-select',
		placeholder: 'Все',
	},
	{
		key: 'sideType',
		keyBackOptions: 'mechanism',
		label: 'Механизм РС',
		type: 'multi-select',
		placeholder: 'Все',
	},
	{
		key: 'operator',
		keyBackOptions: 'operator',
		label: 'Оператор',
		type: 'multi-select',
		placeholder: 'Все',
	},
	{
		key: 'net',
		keyBackOptions: 'net',
		label: 'Сеть',
		type: 'single-select',
		placeholder: 'Все',
	},
	{
		key: 'rimGid',
		keyBackOptions: '',
		label: 'ГИДы',
		type: 'remote-multi-select',
		placeholder: 'Все',
	},
	{
		key: 'etherFraction',
		label: 'Доля эфира',
		type: 'compare',
		placeholder: 'Введите данные',
	},
	{
		key: 'daysCount',
		label: 'Период в месяце',
		type: 'compare',
		placeholder: 'Введите данные',
	},
	{
		key: 'promo',
		keyBackOptions: 'promo',
		label: 'Акция',
		type: 'multi-select',
		placeholder: 'Все',
	},
	{
		key: 'isCurrentMonth',
		keyBackOptions: 'currentMonth',
		label: 'Тип месяца',
		type: 'single-select',
		placeholder: 'Все',
	},
	{
		key: 'isFragmentingPeriod',
		keyBackOptions: 'fragmentingPeriod',
		label: 'Неполный период, через середину месяца',
		type: 'single-select',
		placeholder: 'Все',
	},
];

export { typeList };