import HeadBtnSort from "@/app/components/table/header/head-btn-sort/HeadBtnSort.vue";
import SvgIcon from "@/app/components/svg-icon/SvgIcon.vue";

export default {
	name: 'tprog-head-default',
	components: {
		HeadBtnSort, SvgIcon,
	},
	props: {
		modelValue: {
			type: String,
			default :'',
		},
		label: {
			type: String,
			default: '',
		},
		sort: {
			type: Boolean,
			default: false,
		}
	},
	emits: ['update:modelValue', 'change'],
	data() {
		return {
			order: this.modelValue ?? '',
		}
	},
	methods: {
		clickBtn(){
			if(this.order == 'asc') this.order = 'desc';
			else if(this.order == 'desc') this.order = '';
			else this.order = 'asc';
		}
	},
	watch: {
		modelValue(val){
			this.order = val;
		},
		order(val){
			this.$emit('update:modelValue', val);
			this.$emit('change', val);
		}
	},
}