import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "tprog-settings-table" }
const _hoisted_2 = { class: "tprog-settings-table__container" }
const _hoisted_3 = { class: "tprog-settings-table__template" }
const _hoisted_4 = { class: "tprog-settings-table__check-collapse" }
const _hoisted_5 = { class: "tprog-settings-table__item-checkbox" }
const _hoisted_6 = { class: "tprog-settings-table__search" }
const _hoisted_7 = { class: "tprog-settings-table__search-icon" }
const _hoisted_8 = { class: "tprog-settings-table__fields" }
const _hoisted_9 = { class: "tprog-settings-table__wrap" }
const _hoisted_10 = {
  key: 0,
  class: "tprog-settings-table__hr"
}
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "tprog-settings-table__item-label" }
const _hoisted_13 = { class: "tprog-settings-table__item-checkbox" }
const _hoisted_14 = { class: "tprog-settings-table__btn-all-show" }
const _hoisted_15 = { class: "tprog-settings-table__btn-actions" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TpTempSettings = _resolveComponent("TpTempSettings")
  const _component_BsCheckbox = _resolveComponent("BsCheckbox")
  const _component_SvgIcon = _resolveComponent("SvgIcon")
  const _component_BsInput = _resolveComponent("BsInput")
  const _component_BsBtn = _resolveComponent("BsBtn")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[7] || (_cache[7] = _createElementVNode("div", { class: "tprog-settings-table__template-label" }, "Шаблоны колонок", -1 /* HOISTED */)),
        _createVNode(_component_TpTempSettings, {
          isCommonFieldsCollapsed: _ctx.isCollapsed,
          colConfigList: _ctx.colConfigList,
          options: _ctx.templateOptions,
          onSave: _ctx.saveTemplate,
          onChange: _ctx.changeTemplate,
          onRemove: _ctx.removeTemplate
        }, null, 8 /* PROPS */, ["isCommonFieldsCollapsed", "colConfigList", "options", "onSave", "onChange", "onRemove"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: "tprog-settings-table__item item-collapsed",
          onClick: _cache[2] || (_cache[2] = _withModifiers((...args) => (_ctx.clickCollapse && _ctx.clickCollapse(...args)), ["stop"]))
        }, [
          _cache[8] || (_cache[8] = _createElementVNode("span", { class: "tprog-settings-table__item-label" }, "Скрыть общую часть", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_BsCheckbox, {
              modelValue: _ctx.isCollapsed,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.isCollapsed) = $event)),
              onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
            }, null, 8 /* PROPS */, ["modelValue"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_Transition, {
          name: "el-fade-in",
          mode: "out-in",
          persisted: ""
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", _hoisted_7, [
              _createVNode(_component_SvgIcon, { name: "16-search" })
            ], 512 /* NEED_PATCH */), [
              [_vShow, !(_ctx.isInputFocus || _ctx.strSearch)]
            ])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_BsInput, {
          modelValue: _ctx.strSearch,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => ((_ctx.strSearch) = $event)),
          clearable: true,
          onFocus: _cache[4] || (_cache[4] = $event => (_ctx.isInputFocus=true)),
          onBlur: _cache[5] || (_cache[5] = $event => (_ctx.isInputFocus=false))
        }, null, 8 /* PROPS */, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.searchList, (item) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: item.key
            }, [
              (item.key=='hr')
                ? (_openBlock(), _createElementBlock("div", _hoisted_10))
                : (!item.notSettings)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: "tprog-settings-table__item",
                      onClick: _withModifiers($event => (_ctx.clickItem(item)), ["stop"])
                    }, [
                      _createElementVNode("span", _hoisted_12, _toDisplayString(item.label), 1 /* TEXT */),
                      _createElementVNode("div", _hoisted_13, [
                        _createVNode(_component_BsCheckbox, {
                          modelValue: item.show,
                          "onUpdate:modelValue": $event => ((item.show) = $event),
                          disabled: item.disabledCheck,
                          onClick: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["stop"]))
                        }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "disabled"])
                      ])
                    ], 8 /* PROPS */, _hoisted_11))
                  : _createCommentVNode("v-if", true)
            ], 64 /* STABLE_FRAGMENT */))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createVNode(_component_BsBtn, {
          tag: "div",
          onClick: _ctx.clickAllShow
        }, {
          default: _withCtx(() => _cache[9] || (_cache[9] = [
            _createTextVNode("Показать все поля")
          ])),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createVNode(_component_BsBtn, {
          tag: "div",
          onClick: _ctx.clickCancel
        }, {
          default: _withCtx(() => _cache[10] || (_cache[10] = [
            _createTextVNode("Отмена")
          ])),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["onClick"]),
        _createVNode(_component_BsBtn, {
          tag: "div",
          type: "primary",
          disabled: _ctx.btnOkDisabled,
          onClick: _ctx.clickSave
        }, {
          default: _withCtx(() => _cache[11] || (_cache[11] = [
            _createTextVNode("Ок")
          ])),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["disabled", "onClick"])
      ])
    ])
  ]))
}