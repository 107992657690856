import { mapState, mapActions, mapWritableState } from 'pinia';
import { useRootStore } from '@/app/stores/root-store';
import { useMapDesignStore } from "@/app/stores/map-design-store";
import { useDesignFilterStore } from '@/app/stores/design-filter-store';
import { useDialogStore } from '@/app/stores/dialog-store';
import { useDetailTargetProgStore } from '@/app/stores/detail-target-prog-store';

import { ElImage, ElMessageBox, ElMessage } from "element-plus";

import helper from '@/app/utils/helper';

import SvgIcon from "@/app/components/svg-icon/SvgIcon.vue";
import BsBtn from '@/app/components/controls/buttons/bs-btn/BsBtn.vue';
import BtnAction from '@/app/components/controls/buttons/btn-action/BtnAction.vue';
import SideSelect from '@/app/components/map/map-design-info/side-select/SideSelect.vue';

export default {
	name: 'design-marker-popup',
	components: {
		ElImage, SvgIcon,
		BsBtn, BtnAction, SideSelect,
	},
	props: {
		data: {
			type: Object,
			default: {},
		},
	},
	emits: ['closePopup'],
	data() {
		return {
			selectSide: '',
			isLoadingOpenStructure: false,
		}
	},
	computed:{
		...mapState(useRootStore, ['isShowSecondAside'] ),
		...mapState(useDesignFilterStore, [ 'targetProgram', 'placePeriodDate', 'frequency', 'digitalVideoLength' ]),

		defaultData(){
			const { address, gid, type } = this.data ?? {};
			return {
				address: address || '',
				gid: gid || '',
				structureType: type || '',
			}
		},
		sides(){
			return this.data?.sides ?? [];
		},
		sideSelectOptions(){
			const options = [];
			if(this.sides?.length) for( const item of this.sides ){
				const { id, direction, lighted, isDigital } = item;

				options.push({
					value: id,
					label: direction,
					light: lighted,
					isDigital,
				});
			}

			return options.sort((a,b)=>a.label.localeCompare(b.label));
		},
		sideData(){
			const side = this.sides.find(item=>item.id==this.selectSide) ?? {};
			const { type, price } = side;

			return {
				...side,
				priceMonth: price?.RUB ?? 0,
				mechanism: type ?? '',
			}
		},
		firstPhoto(){
			const { photoList } = this.sideData ?? {};
			if(!photoList) return '';

			const photo = photoList[0];

			return photo ?? ''
		}
	},
	methods: {
		...mapActions(useMapDesignStore, ['getTypeDesignIcon', 'getStructure'] ),
		...mapActions(useRootStore, ['setShowSecondAside']),
		...mapActions(useDialogStore, ['openTargetProgDialog', 'addTargetProgram']),
		...mapActions(useDetailTargetProgStore, ['removeSideInTp', 'getTargetTabData']),

		getMoney: helper.getMoney,

		async openStructure(){
			console.log(this.data.id, this.data);

			this.isLoadingOpenStructure = true;
			await this.getStructure({ id: this.data.id, noDataMap: true });
			this.isLoadingOpenStructure = false;

			if(!this.isShowSecondAside) this.setShowSecondAside(true);
		},

		async addTargetProg(){
			const targetProgId = this.targetProgram.value;
			let data = {
				side_id: this.sideData.id,
				periods: [],
				status: '',
			};

			const { from, to } = this.placePeriodDate;
			data.periods = [[from, to]];

			if(this.sideData?.isDigital){
				data = {
					...data,
					duration: this.digitalVideoLength,
					frequency: this.frequency.value,
				}
			}
			console.log('addTargetProg', this.sideData.id, data);

			if(targetProgId){
				const res = await this.addTargetProgram({
					orders: [data],
					target_program_id: targetProgId,
				});
				console.log(res);
				if(!res) return;

				this.getTargetTabData(targetProgId);
				ElMessage({
					type: 'success',
					message: 'РС успешно добавлена',
				});
			} else {
				this.openTargetProgDialog(data);
			}

			this.$emit('closePopup');
		},
		async removeSide(){
			const { gid } = this.data ?? {};
			const { orderId, direction } = this.sideData ?? {};
			const res = await this.removeSideInTp(orderId);

			if(res?.success){
				ElMessage({
					type: 'success',
					message: `Сторона ${gid}-${direction} успешно удалена из АП`,
				});
			}
			else if(res?.message){
				ElMessageBox.alert(null, null, {
					type: 'warning',
					title: 'Внимание!',
					message: res.message,
					customClass: 'bs-message-box',
				}).then(() => { }).catch(() => { });
			}
			this.$emit('closePopup');
		}
	},
	created(){
		this.selectSide = this.sideSelectOptions[0]?.value ?? '';
	}
}
