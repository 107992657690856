import cellMixin from "@/app/mixins/table/cell-mixin";

export default {
	name: 'pr-title-link',
	mixins: [cellMixin],
	computed: {
		label(){
			return this.fieldData;
		},
		link(){
			const { id } = this.rowData ?? {};
			return `/pricing-policies/${id}`;
		}
	},
}