import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "notif-user-info" }
const _hoisted_2 = { class: "notif-user-info__ava" }
const _hoisted_3 = { class: "notif-user-info__name" }
const _hoisted_4 = { class: "notif-user-info__contacts" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsAvatar = _resolveComponent("BsAvatar")
  const _component_SvgIcon = _resolveComponent("SvgIcon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BsAvatar, {
        size: 32,
        src: _ctx.avatar,
        name: _ctx.fullName
      }, null, 8 /* PROPS */, ["src", "name"])
    ]),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.fullName), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.contacts?.phone)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "notif-user-info__contact-item item-phone",
            title: "Копировать номер телефона",
            onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.clickPhone && _ctx.clickPhone(...args)))
          }, [
            _createVNode(_component_SvgIcon, { name: "16-gg-phone" })
          ]))
        : _createCommentVNode("v-if", true),
      (_ctx.contacts?.email)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "notif-user-info__contact-item item-email",
            title: "Копировать email",
            onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.clickEmail && _ctx.clickEmail(...args)))
          }, [
            _createVNode(_component_SvgIcon, { name: "16-email-solid" })
          ]))
        : _createCommentVNode("v-if", true),
      (_ctx.contacts?.telegram)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: "notif-user-info__contact-item item-telegram",
            title: "Копировать telegram",
            onClick: _cache[2] || (_cache[2] = (...args) => (_ctx.clickTelegram && _ctx.clickTelegram(...args)))
          }, [
            _createVNode(_component_SvgIcon, { name: "12-telegram" })
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}