import cellMixin from "@/app/mixins/table/cell-mixin";
import helper from "@/app/utils/helper";

import BsInput from "@/app/components/controls/bs-input/BsInput.vue";

export default {
	name: 'pr-cell-edit-num',
	mixins: [cellMixin],
	components: {
		BsInput,
	},
	emits: ['change'],
	data() {
		return {
			value: '',
		}
	},
	methods: {
		setValue(){
			let num = 0;
			if(this.fieldData) num = helper.getNum(this.fieldData);

			this.value = num;
		},
		change(val){
			const num = helper.getNum(val);

			this.$emit('change', {
				rowData: this.rowData,
				groupKey: this.groupKey,
				fieldKey: this.fieldKey,
				fieldData: num,
			});
		}
	},
	watch:{
		fieldData(val){
			this.setValue();
		},
	},
	created() {
		this.setValue();
	},
}