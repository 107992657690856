import { mapActions, mapState, mapWritableState } from 'pinia';
import { useDetailTargetProgStore } from '@/app/stores/detail-target-prog-store';

import { ElDialog } from "element-plus";


import BsBtn from "@/app/components/controls/buttons/bs-btn/BsBtn.vue";
import BsSingleSelect from "@/app/components/controls/selects/bs-single-select/BsSingleSelect.vue";
import BsMultiSelect from "@/app/components/controls/selects/bs-multi-select/BsMultiSelect.vue";

export default {
	name: 'tg-mass-status',
	components: {
		ElDialog,
		BsBtn, BsSingleSelect, BsMultiSelect,
	},
	props: {
		visible:{
			type: Boolean,
			default: false,
		},
		statusOptions: {
			type: Array,
			default: [],
		},
	},
	emits: ['update:visible', 'change'],
	data() {
		return {
			status: '',
			months: '',
		}
	},
	computed: {
		...mapState( useDetailTargetProgStore, ['detailMonthSelectData'] ),

		isVisible: {
			get(){
				return this.visible;
			},
			set(is){
				this.$emit('update:visible', is);
			},
		},
	},
	methods: {
		clickChange(){
			this.$emit('change', {
				status: this.status,
				months: this.months
			});
			this.isVisible = false;
		}
	},
	watch: {
		isVisible(is){
			if(is){
				this.months = [ this.detailMonthSelectData.value ];
			} else {
				this.status = '';
				this.months = '';
			}
		}
	},
}