import cellMixin from "@/app/mixins/table/cell-mixin";

export default {
	name: 'pr-cell-responsible',
	mixins: [cellMixin],
	computed: {
		label(){
			const { name } = this.fieldData ?? {};

			return (name ?? '').trim();
		}
	},
}