import { mapState, mapActions, mapWritableState } from 'pinia';
import { useFiltersStore } from '@/app/stores/filters-store';

import BsSelectV2 from "@/app/components/controls/selects/bs-select-v2/BsSelectV2.vue";
import BsCheckboxGroup from "@/app/components/controls/bs-checkbox-group/BsCheckboxGroup.vue";

export default {
	name: 'dstr-info-filters',
	components: {
		BsSelectV2, BsCheckboxGroup,
	},
	emits: ['change'],
	data() {
		return {
			filterData:{
				city: [],
				direction: [],
				structureType: [],
				mechanism: [],
			}
		}
	},
	methods: {
		...mapActions(useFiltersStore, ['getOptions']),

		changeFilter(){
			const data = {};

			for( const [key, value] of Object.entries(this.filterData)){
				if(!value?.length) continue;
				const options = this.getOptions(key);
				const list = options.filter(item=>value.includes(item.value));

				if(list.length) data[key] = list;
			}

			this.$emit('change', data);
		},
		clear(){
			this.filterData = {
				city: [],
				direction: [],
				structureType: [],
				mechanism: [],
			}
		}
	},
}