import { ElDialog } from "element-plus";

import BsBtn from "@/app/components/controls/buttons/bs-btn/BsBtn.vue";
import BsSingleSelect from "@/app/components/controls/selects/bs-single-select/BsSingleSelect.vue";

export default {
	name: 'MassRuleStatusDialog',
	components: {
		ElDialog,
		BsBtn, BsSingleSelect,
	},
	props: {
		visible:{
			type: Boolean,
			default: false,
		},
	},
	emits: ['update:visible', 'change'],
	data() {
		return {
			isEnabled: '',
			statusOptions: [
				{
					value: 1,
					label: 'Включен',
				},
				{
					value: 0,
					label: 'Выключен',
				},
			],
		}
	},
	computed: {
		isVisible: {
			get(){
				return this.visible;
			},
			set(is){
				this.$emit('update:visible', is);
			},
		},

		disabledChangeBtn(){
			return this.isEnabled==='';
		}
	},
	methods: {
		clickChange(){
			this.$emit('change', {
				isEnabled: !!this.isEnabled,
			});

			this.isVisible = false;
		},
	},
	watch: {
		isVisible(is){
			if(is){ } else {
				this.isEnabled = '';
			}
		}
	},
}