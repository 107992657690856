import { mapState, mapActions, mapWritableState } from 'pinia';
import { useDesignFilterStore } from '@/app/stores/design-filter-store';

import { ElDialog, ElMessage } from "element-plus";



import BsInput from '@/app/components/controls/bs-input/BsInput.vue';
import BsBtn from '@/app/components/controls/buttons/bs-btn/BsBtn.vue';

export default {
	name: 'save-filtertemp-form',
	components: {
		ElDialog, BsInput, BsBtn,
	},
	props:{
		modelValue: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['update:modelValue'],
	data() {
		return {
			isVisible: this.modelValue,
			isSubmitData: false,
			nameTemp: '',
		}
	},
	computed: {
		...mapState(useDesignFilterStore, ['template']),

		currentTemp(){
			const { value, options } = this.template;
			if(value) return;

			const findOpt = options?.find(item=>item.value==value);
			return findOpt;
		},
		headTitle(){
			if(this.currentTemp) return 'Изменение шаблона фильтров';
			return 'Создание шаблона фильтров';
		},
		isSaveBtnDisabled(){
			return (this.nameTemp.length < 5) || this.isSubmitData;
		},
	},
	methods: {
		...mapActions(useDesignFilterStore, ['saveFilterTemplate', 'updateFilterTemplate']),

		cancel(){
			this.isVisible = !this.isVisible;
		},
		async saveTemp(){
			// console.log('saveTemp');
			if(this.isCreateVtnDisabled) return;

			this.isSubmitData = true;
			const res = await this.saveFilterTemplate(this.nameTemp);
			console.log(res);

			if(res){
				ElMessage({
					type: 'success',
					message: 'Шаблон успешно сохранен',
				});
			}

			this.isSubmitData = false;
			this.isVisible = false;
		},
		updateTemp(){

		},
	},
	watch: {
		isVisible(is){
			this.$emit('update:modelValue', is);
			this.nameTemp = '';
		},
		modelValue(is){
			this.isVisible = is;
		}
	},
}