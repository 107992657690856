/*
{
	key: '',
	keyProp: '',
	keyGroup: '',
	label: '',
	order: '',
	sort: true,
	show: true,
	minWidth: 160,
	maxWidth: '',
	classNameTh: '',
	classNameTd: '',
	fixed: false, // true, false, 'right'
},
*/

const columnsPrices = [
	{
		key: 'status',
		keyProp: 'status',
		keyGroup: '',
		label: 'Статус',
		order: '',
		sort: false,
		show: true,
		minWidth: 70,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
	},
	{
		key: 'id',
		keyProp: 'id',
		keyGroup: '',
		label: 'ID',
		order: '',
		sort: false,
		show: true,
		minWidth: 70,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
	},
	{
		key: 'pos',
		keyProp: 'pos',
		keyGroup: '',
		label: 'POS',
		order: '',
		sort: true,
		show: true,
		minWidth: 80,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
	},
	{
		key: 'createdAt',
		keyProp: 'createdAt',
		keyGroup: '',
		label: 'Создано',
		order: '',
		sort: true,
		show: true,
		minWidth: 120,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
		// fixed: true,
	},
	{
		key: 'updatedAt',
		keyProp: 'updatedAt',
		keyGroup: '',
		label: 'Обновлено',
		order: '',
		sort: true,
		show: true,
		minWidth: 120,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
	},
	{
		key: 'rules',
		keyProp: 'rules',
		keyGroup: '',
		label: 'Условия',
		order: '',
		sort: false,
		show: true,
		minWidth: 410,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
	},
	{
		key: 'avgPriceMonth',
		keyProp: 'avgPriceMonth',
		keyGroup: '',
		label: 'Средний <br> прайс/месяц',
		order: '',
		sort: false,
		show: true,
		minWidth: 110,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
	},
	{
		key: 'countStructure',
		keyProp: 'countStructure',
		keyGroup: '',
		label: 'Кол-во <br> РК/РС/РП',
		order: '',
		sort: false,
		show: true,
		minWidth: 110,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
		// fixed: true,
	},
	// {
	// 	key: 'priceMonth',
	// 	keyProp: 'priceMonth',
	// 	keyGroup: '',
	// 	label: 'Прайс/месяц',
	// 	order: '',
	// 	sort: true,
	// 	show: true,
	// 	minWidth: 110,
	// 	maxWidth: '',
	// 	classNameTh: 'th-col-green',
	// 	classNameTd: '',
	// },

	// Скидки
	{
		key: 'targetDiscount',
		keyProp: 'targetDiscount',
		keyGroup: '',
		label: 'Таргет скидка',
		order: '',
		sort: true,
		show: true,
		minWidth: 140,
		maxWidth: '',
		classNameTh: 'th-col-green',
		classNameTd: '',
		type: 'prices',
	},
	{
		key: 'maxDiscount',
		keyProp: 'maxDiscount',
		keyGroup: '',
		label: 'Макс. скидка',
		order: '',
		sort: true,
		show: true,
		minWidth: 140,
		maxWidth: '',
		classNameTh: 'th-col-green',
		classNameTd: '',
		type: 'prices',
	},
	{
		key: 'curMonthDiscount',
		keyProp: 'curMonthDiscount',
		keyGroup: '',
		label: 'Скидка <br> на тек. месяц',
		order: '',
		sort: true,
		show: true,
		minWidth: 140,
		maxWidth: '',
		classNameTh: 'th-col-green',
		classNameTd: '',
		type: 'prices',
	},
	{
		key: 'coefficient',
		keyProp: 'coefficient',
		keyGroup: '',
		label: 'Коэффициент',
		order: '',
		sort: true,
		show: true,
		minWidth: 140,
		maxWidth: '',
		classNameTh: 'th-col-green',
		classNameTd: '',
		type: 'prices',
	},

	{
		key: 'comment',
		keyProp: 'comment',
		keyGroup: '',
		label: 'Комментарий',
		order: '',
		sort: false,
		show: true,
		minWidth: 280,
		maxWidth: 280,
		classNameTh: '',
		classNameTd: '',
	},
	{
		key: 'massAction',
		keyProp: '',
		keyGroup: '',
		label: '',
		order: '',
		sort: false,
		show: true,
		minWidth: 30,
		maxWidth: '',
		classNameTh: 'th-mass-actions',
		classNameTd: 'td-mass-actions',
		noSettings: true,
		fixed: 'right',
	},
];

const columnsAddServ = [
	{
		key: 'status',
		keyProp: 'status',
		keyGroup: '',
		label: 'Статус',
		order: '',
		sort: false,
		show: true,
		minWidth: 70,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
	},
	{
		key: 'id',
		keyProp: 'id',
		keyGroup: '',
		label: 'ID',
		order: '',
		sort: false,
		show: true,
		minWidth: 70,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
	},
	{
		key: 'pos',
		keyProp: 'pos',
		keyGroup: '',
		label: 'POS',
		order: '',
		sort: true,
		show: true,
		minWidth: 80,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
	},
	{
		key: 'createdAt',
		keyProp: 'createdAt',
		keyGroup: '',
		label: 'Создано',
		order: '',
		sort: true,
		show: true,
		minWidth: 120,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
		// fixed: true,
	},
	{
		key: 'updatedAt',
		keyProp: 'updatedAt',
		keyGroup: '',
		label: 'Обновлено',
		order: '',
		sort: true,
		show: true,
		minWidth: 120,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
	},
	{
		key: 'rules',
		keyProp: 'rules',
		keyGroup: '',
		label: 'Условия',
		order: '',
		sort: false,
		show: true,
		minWidth: 410,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
	},
	{
		key: 'avgPriceMonth',
		keyProp: 'avgPriceMonth',
		keyGroup: '',
		label: 'Средний <br> прайс/месяц',
		order: '',
		sort: false,
		show: true,
		minWidth: 110,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
	},
	{
		key: 'countStructure',
		keyProp: 'countStructure',
		keyGroup: '',
		label: 'Кол-во <br> РК/РС/РП',
		order: '',
		sort: false,
		show: true,
		minWidth: 110,
		maxWidth: '',
		classNameTh: '',
		classNameTd: '',
		// fixed: true,
	},
	// {
	// 	key: 'priceMonth',
	// 	keyProp: 'priceMonth',
	// 	keyGroup: '',
	// 	label: 'Прайс/месяц',
	// 	order: '',
	// 	sort: true,
	// 	show: true,
	// 	minWidth: 110,
	// 	maxWidth: '',
	// 	classNameTh: 'th-col-green',
	// 	classNameTd: '',
	// },

	// Доп. услуги
	{
		key: 'design',
		keyProp: 'design',
		keyGroup: '',
		label: 'Дизайн',
		order: '',
		sort: true,
		show: true,
		minWidth: 140,
		maxWidth: '',
		classNameTh: 'th-col-green',
		classNameTd: '',
		type: 'additional-services',
	},
	{
		key: 'print',
		keyProp: 'print',
		keyGroup: '',
		label: 'Печать',
		order: '',
		sort: true,
		show: true,
		minWidth: 140,
		maxWidth: '',
		classNameTh: 'th-col-green',
		classNameTd: '',
		type: 'additional-services',
	},
	{
		key: 'installation',
		keyProp: 'installation',
		keyGroup: '',
		label: 'Монтаж',
		order: '',
		sort: true,
		show: true,
		minWidth: 140,
		maxWidth: '',
		classNameTh: 'th-col-green',
		classNameTd: '',
		type: 'additional-services',
	},
	{
		key: 'photoReport',
		keyProp: 'photoReport',
		keyGroup: '',
		label: 'Фотоотчет',
		order: '',
		sort: true,
		show: true,
		minWidth: 140,
		maxWidth: '',
		classNameTh: 'th-col-green',
		classNameTd: '',
		type: 'additional-services',
	},


	{
		key: 'comment',
		keyProp: 'comment',
		keyGroup: '',
		label: 'Комментарий',
		order: '',
		sort: false,
		show: true,
		minWidth: 280,
		maxWidth: 280,
		classNameTh: '',
		classNameTd: '',
	},
	{
		key: 'massAction',
		keyProp: '',
		keyGroup: '',
		label: '',
		order: '',
		sort: false,
		show: true,
		minWidth: 30,
		maxWidth: '',
		classNameTh: 'th-mass-actions',
		classNameTd: 'td-mass-actions',
		noSettings: true,
		fixed: 'right',
	},
];

export { columnsPrices, columnsAddServ };