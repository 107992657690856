import { mapState, mapActions, mapWritableState } from 'pinia';
import { useDetailTargetProgStore } from '@/app/stores/detail-target-prog-store';
import { useMapTargetStore } from '@/app/stores/map-target-store';

import { ElTabs, ElTabPane, ElBadge, ElMessageBox, ElMessage } from 'element-plus';

import LoaderSpinner from '@/app/components/loaders/loader-spinner/LoaderSpinner.vue';
import EditField from '@/app/components/controls/edit-field/EditField.vue';
import SvgIcon from '@/app/components/svg-icon/SvgIcon.vue';
import TargetSummary from '@/app/components/target-programs/target-summary/TargetSummary.vue';
import TargetPersons from '@/app/components/target-programs/target-persons/TargetPersons.vue';
import TargetStatistics from '@/app/components/target-programs/target-statistics/TargetStatistics.vue';
import TargetFilter from '@/app/components/target-programs/target-filter/TargetFilter.vue';

export default {
	name: 'TargetAside',
	components: {
		ElTabs, ElTabPane, ElBadge,
		LoaderSpinner, EditField, SvgIcon,
		TargetSummary, TargetPersons, TargetStatistics, TargetFilter,
	},
	data() {
		return {
			activeTab: 'target-summary',
			// activeTab: 'target-filter',
		}
	},
	computed: {
		...mapState(useDetailTargetProgStore, ['isAsideLoader', 'countDetailTargetFilter', 'detailMonthSelectData']),
		...mapWritableState(useDetailTargetProgStore, ['detailTargetStatsData', ]),

		defaultData(){
			return this.detailTargetStatsData?.defaultData ?? {};
		}
	},
	methods: {
		...mapActions(useDetailTargetProgStore, ['updateDetailTargetAsideItem', 'clearFilters', 'getDetailTargetGridData']),
		...mapActions(useMapTargetStore, ['getMapData']),

		async editTargetName(value){
			const res = await this.updateDetailTargetAsideItem({ fieldKey: 'name', value });
			if(!res) return;

			this.defaultData.name = value;
			ElMessage({
				message: 'Данные успешно изменены.',
				type: 'success',
			});
		},

		clickResetFilters(){
			const { params, query } = this.$route;

			this.clearFilters();
			this.$router.push({
				name: 'target-program-item',
				params,
				query: {},
			});

			if(query?.view=='map'){
				this.getMapData({
					id: params?.id,
				});
			} else {
				this.getDetailTargetGridData({
					id: params?.id,
					monthDate: this.detailMonthSelectData.value,
				});
			}
		}
	},
}