import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "add-pricing-dialog__content" }
const _hoisted_2 = { class: "control" }
const _hoisted_3 = { class: "control__group" }
const _hoisted_4 = { class: "control" }
const _hoisted_5 = { class: "control__group" }
const _hoisted_6 = { class: "add-pricing-dialog__action-buttons" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsInput = _resolveComponent("BsInput")
  const _component_BsSingleSelect = _resolveComponent("BsSingleSelect")
  const _component_BsBtn = _resolveComponent("BsBtn")
  const _component_ElDialog = _resolveComponent("ElDialog")

  return (_openBlock(), _createBlock(_component_ElDialog, {
    class: "add-pricing-dialog",
    modelValue: _ctx.isVisible,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.isVisible) = $event)),
    "append-to-body": true,
    "destroy-on-close": true,
    title: "Создание прайсовой политики",
    "align-center": ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "control__label" }, "Название политики", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_BsInput, {
              modelValue: _ctx.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.name) = $event)),
              placeholder: "Введите данные",
              clearable: true,
              onChange: _cache[1] || (_cache[1] = () => {})
            }, null, 8 /* PROPS */, ["modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "control__label" }, "Тип", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_BsSingleSelect, {
              modelValue: _ctx.type,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.type) = $event)),
              placeholder: "Выберите из списка",
              options: _ctx.getOptions('pricePolicyTypes')
            }, null, 8 /* PROPS */, ["modelValue", "options"])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_BsBtn, {
          size: "small",
          onClick: _cache[3] || (_cache[3] = $event => (_ctx.isVisible=false))
        }, {
          default: _withCtx(() => _cache[7] || (_cache[7] = [
            _createTextVNode("Отмена")
          ])),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_BsBtn, {
          disabled: _ctx.disabledCreateBtn,
          type: "primary",
          size: "small",
          onClick: _ctx.create
        }, {
          default: _withCtx(() => _cache[8] || (_cache[8] = [
            _createTextVNode("Создать")
          ])),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["disabled", "onClick"])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}