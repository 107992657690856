import { mapState, mapActions, mapWritableState } from 'pinia';
import { usePricingDetailStore } from '@/app/stores/pricing-policies/pricing-detail-store';

import helper from '@/app/utils/helper';

import { ElMessage, ElMessageBox } from 'element-plus';

import TableGrid from "@/app/components/table-grid/TableGrid.vue";
import HeadCellDefault from '@/app/components/table/header/head-cell-default/HeadCellDefault.vue';

import CellSwitch from "@/app/components/table/cell-types/cell-switch/CellSwitch.vue";
import PrCellEditNum from "@/app/components/pricing-policies/type-cell/pr-cell-edit-num/PrCellEditNum.vue";
import CellDate from '@/app/components/table/cell-types/cell-date/CellDate.vue';
import CellPrice from "@/app/components/table/cell-types/cell-price/CellPrice.vue";
import PrCellCountStruct from "@/app/components/pricing-policies/type-cell/pr-cell-count-struct/PrCellCountStruct.vue";
import PrCellEditPrice from "@/app/components/pricing-policies/type-cell/pr-cell-edit-price/PrCellEditPrice.vue";
import PrCellEditPercent from "@/app/components/pricing-policies/type-cell/pr-cell-edit-percent/PrCellEditPercent.vue";
import PrCellCoef from "@/app/components/pricing-policies/type-cell/pr-cell-coef/PrCellCoef.vue";
import PrCellComment from "@/app/components/pricing-policies/type-cell/pr-cell-comment/PrCellComment.vue";
import PrCellRules from "@/app/components/pricing-policies/type-cell/pr-cell-rules/PrCellRules.vue";
import CellMassActions from "@/app/components/table/cell-types/cell-mass-actions/CellMassActions.vue";

import MassRuleStatusDialog from '@/app/components/pricing-policies/dialogs/mass-rule-status-dialog/MassRuleStatusDialog.vue';

export default {
	name: 'pricing-detail-table',
	components: {
		TableGrid, HeadCellDefault,
		CellSwitch, PrCellEditNum, CellDate, CellPrice,
		PrCellCountStruct, PrCellEditPrice, PrCellEditPercent,
		PrCellCoef, PrCellComment, PrCellRules, CellMassActions,

		MassRuleStatusDialog,
	},
	props: {
		tableData: {
			type: Array,
			default: [],
		},
		columns: {
			type: Array,
			default: [],
		}
	},
	emits: ['update:tableData'],
	data() {
		return {
			massActionList: [
				{
					key: 'status',
					label: 'Сменить статус',
				},
				{
					key: 'remove',
					label: 'Удалить',
				},
			],
			actionList: [
				{
					key: 'remove',
					label: 'Удалить',
				},
			],
			selectedRows: [],
			sortData: null,

			isShowMassRuleStatusDialog: false,
		}
	},
	computed: {
		sortRowList(){
			const { order, key } = this.sortData ?? {};
			if(!this.sortData || !order) return this.tableData;

			const tableData = [...this.tableData];

			for(const item of this.columns){
				if(item.key == key) continue;
				item.order = '';
			}

			const getData = (item)=>{
				return item?.[key] ?? 0;
			}

			if(order=='asc') tableData.sort((a,b)=>getData(a) - getData(b));
			else if(order=='desc') tableData.sort((a,b)=>getData(b) - getData(a));
			else tableData.sort((a,b)=>a['pos'] - b['pos']);

			return tableData;
		}
	},
	methods: {
		...mapActions(usePricingDetailStore, [
			'getRuleList', 'updateFieldRuleItem', 'updateTableRow',
			'massRulesRemoved', 'removeRuleItem', 'massRulesChangeStatus',
		]),

		getPriceCell(key){
			return [
				'design', 'print', 'installation', 'photoReport',
			].includes(key);
		},

		updateFieldState({ action, data, sucMessage }={}){
			if(!data) return;
			if(action) action();

			ElMessage({
				message: sucMessage ?? 'Данные успешно изменены',
				type: 'success',
			});
		},

		async changeDefaultCell({ rowData, groupKey, fieldKey, fieldData }){
			const { params } = this.$route ?? {};
			console.log({ rowData, groupKey, fieldKey, fieldData });

			helper.debounce({
				keyTimer: 'changeDefaultCell',
				action: async ()=>{
					const res = await this.updateFieldRuleItem({
						pricePolicyId: params?.id,
						pricePolicyRuleId: rowData?.id,
						fieldKey, fieldData,
					});

					// console.log(res);
					this.updateFieldState({
						data: res?.rule,
						action: ()=>{
							this.updateTableRow(res.rule);
						}
					});
				},
			});
			// rowData[fieldKey] = fieldData;
		},

		// Массовые действия
		changeMass(key){
			console.log('changeMass', {key});
			if(!this.selectedRows?.length) return;

			if(key=='remove') this.massRemoved();
			else if(key=='status') this.isShowMassRuleStatusDialog = true;

		},
		massRemoved(){
			const { params } = this.$route ?? {};
			const ids = this.selectedRows.map(item=>item.id);

			ElMessageBox.confirm(null, null, {
				title: 'Внимание',
				message: 'Вы точно хотите удалить выбранные правила?',
				showClose: false,
				customClass: 'bs-message-box',
				type: 'warning',
			}).then(async () => {
				const res = await this.massRulesRemoved({
					pricePolicyId: params?.id,
					ids,
				});
				if(!res) return;

				this.getRuleList({
					pricePolicyId: params?.id,
				});
				ElMessage({
					type: 'success',
					message: 'Правила успешно удалёны',
				});
			}).catch(() => {
				ElMessage({
					type: 'info',
					message: 'Удаление отменено',
				})
			});
		},

		async changeMassStatus({ isEnabled }){
			const { params } = this.$route ?? {};
			const ids = this.selectedRows.map(item=>item.id);

			const res = await this.massRulesChangeStatus({
				pricePolicyId: params?.id,
				ids, isEnabled,
			});
			if(!res) return;

			this.getRuleList({
				pricePolicyId: params?.id,
			});
			ElMessage({
				type: 'success',
				message: 'Статусы успешно изменены',
			});
		},

		// Изменение строки
		changeRow(key, rowData){
			const { params } = this.$route ?? {};
			console.log('changeRow', {key, rowData});

			if(key=='remove'){
				ElMessageBox.confirm(null, null, {
					title: 'Внимание',
					message: 'Вы точно хотите удалить выбранное правило?',
					showClose: false,
					customClass: 'bs-message-box',
					type: 'warning',
				}).then(async () => {
					const res = await this.removeRuleItem({
						pricePolicyId: params?.id,
						ruleId: rowData?.id,
					});
					if(!res) return;

					const tableData = this.tableData.filter(item=>item.id!=rowData.id);
					this.$emit('update:tableData', tableData);

					ElMessage({
						type: 'success',
						message: 'Правило успешно удалёно',
					});
				}).catch(() => {
					ElMessage({
						type: 'info',
						message: 'Удаление отменено',
					})
				});
			}
		},

		changeSelectedRows(list){
			// console.log('changeSelectedRows', list);
			this.selectedRows = list;
		},

		setQueryUrlSort(order, key){
			const { query } = this.$route;
			let sort = { [key]: order };
			let updatedParams = {
				...query,
				sort: JSON.stringify(sort),
			};

			if(!order?.length) {
				sort = null
				delete updatedParams.sort
			}

			this.$router.push({ query: updatedParams });
		},
		sortTable(order, key){
			// if(!order) return;
			// console.log({order, key});
			this.sortData = { order, key };
			this.setQueryUrlSort(order, key);
		},
	},
	created() {
		const { query } = this.$route;

		// Данные сортировки таблицы и URLа
		if(query?.sort){
			const sort = JSON.parse(query.sort);
			const [ key, order ] = Object.entries(sort)[0];
			const findCol = this.columns.find(item=>item.key==key);
			// console.log(key, order , findCol);
			if(findCol && order){
				findCol.order = order;
				this.sortData = { key, order };
			}
		}
	},
}