import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dstr-total-price" }
const _hoisted_2 = { class: "dstr-total-price__top control__group group-row gap-16" }
const _hoisted_3 = { class: "control" }
const _hoisted_4 = { class: "control__value" }
const _hoisted_5 = { class: "control" }
const _hoisted_6 = { class: "dstr-total-price__discount control__group group-row gap-8 align-center" }
const _hoisted_7 = { class: "dstr-total-price__bottom control__group" }
const _hoisted_8 = { class: "control__group group-row gap-16" }
const _hoisted_9 = { class: "control" }
const _hoisted_10 = { class: "control__value" }
const _hoisted_11 = { class: "control" }
const _hoisted_12 = { class: "control__value" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BsInput = _resolveComponent("BsInput")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "control__label" }, "Общая стоимость:", -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.getMoney(_ctx.data.pureCost)) + " ₽", 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "control__label" }, "Скидка", -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_BsInput, {
            placeholder: "",
            modelValue: _ctx.discount,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.discount) = $event)),
            type: "number",
            min: "0",
            max: "100",
            step: "0.01",
            onChange: _ctx.changeDiscount
          }, null, 8 /* PROPS */, ["modelValue", "onChange"]),
          _cache[2] || (_cache[2] = _createElementVNode("span", null, "%", -1 /* HOISTED */))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "dstr-total-price__bottom-title control__label" }, "Стоимость со скидкой", -1 /* HOISTED */)),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "control__label" }, "Без НДС:", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.getMoney(_ctx.discountTotalPrice)) + " ₽", 1 /* TEXT */)
        ]),
        _createElementVNode("div", _hoisted_11, [
          _cache[5] || (_cache[5] = _createElementVNode("div", { class: "control__label" }, "С НДС:", -1 /* HOISTED */)),
          _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.getMoney(_ctx.discountTotalPriceWithVat)) + " ₽", 1 /* TEXT */)
        ])
      ])
    ])
  ]))
}